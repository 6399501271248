/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'
import { ComentarioSubProyectoService } from '../services/ComentarioSubProyectoService'
import AuthUse from '../../../auth/AuthUse'
// import { CargaBodegaService } from '../services/CargaBodegaService'
export const ComentarioSubProyectoContext = createContext()

const ComentarioSubProyectoContextProvider = (props) => {
  const comentarioSubProyectoService = new ComentarioSubProyectoService()
  // const cargaBodegaService = new CargaBodegaService()

  const [comentarioSubProyectos, setComentarioSubProyectos] = useState([])
  const [
    createBodegaComentarioSubProyecto1,
    setCreateBodegaComentarioSubProyecto1
  ] = useState([])
  const [comentarioSubProyectocargando, setComentarioSubProyectocargando] =
    useState(null)
  const [editComentarioSubProyecto, setEditComentarioSubProyecto] =
    useState(null)
  const [loading, setLoading] = useState(true)
  const [actuallizar, setActuallizar] = useState(null)

  const auth = AuthUse()
  const token = auth.user.token

  useEffect(() => {
    comentarioSubProyectoService.readAll(token).then((data) => {
      setComentarioSubProyectos(data)
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      comentarioSubProyectoService.readAll(token).then((data) => {
        setComentarioSubProyectos(data)
        setLoading(false)
      })
      setActuallizar(actuallizar + 1)
    }, 600000)
    return () => {
      clearInterval(interval)
    }
  }, [actuallizar])

  useEffect(() => {
    const comentarioSubProyecto = comentarioSubProyectos.find(
      (p) => p.estatusComentarioSubProyecto === 'OPERATIVO'
    )
    setComentarioSubProyectocargando(comentarioSubProyecto)
  }, [comentarioSubProyectos])

  const findComentarioSubProyecto = (id) => {
    const comentarioSubProyecto = comentarioSubProyectos.find(
      (p) => p.id === id
    )
    setEditComentarioSubProyecto(comentarioSubProyecto)
  }

  const createComentarioSubProyecto = (comentarioSubProyecto) => {
    comentarioSubProyectoService
      .create(comentarioSubProyecto, token)
      .then((data) => {
        setComentarioSubProyectos([
          ...comentarioSubProyectos,
          data.saveComentarioSubProyecto
        ])
        createBodegaComentarioSubProyecto(data.saveComentarioSubProyecto)
        setCreateBodegaComentarioSubProyecto1(data.saveComentarioSubProyecto)
      })
  }
  const createBodegaComentarioSubProyecto = (saveComentarioSubProyecto) => {
    const cargaBodega = {
      comentarioSubProyectoID: saveComentarioSubProyecto.id,
      nombreBodega: '',
      estatusCargaBodega: 'CARGANDO',
      toneladasCargadasBodega: 0,
      toneladasCapacidadBodega: 0
    }

    // let i = 1

    // do {
    //   cargaBodega.nombreBodega = `BODEGA ${i}`
    //   cargaBodegaService.create(cargaBodega, token).then(i++)
    // } while (i <= saveComentarioSubProyecto.cantidadBodegas)
    for (let i = 1; i <= saveComentarioSubProyecto.cantidadBodegas; i++) {
      cargaBodega.nombreBodega = `BODEGA ${i}`
    }
    // comentarioSubProyectoService.create(CargaBodega, token).then((data) => {
    //   setComentarioSubProyectos([...comentarioSubProyectos, data.saveComentarioSubProyecto])
    //   console.log('comentarioSubProyecto creado', data.saveComentarioSubProyecto)
    // })
  }

  const updateComentarioSubProyecto = (comentarioSubProyecto) => {
    comentarioSubProyectoService
      .update(comentarioSubProyecto, token)
      .then((data) =>
        setComentarioSubProyectos(
          comentarioSubProyectos.map((p) =>
            p.id === comentarioSubProyecto.id
              ? data.updateComentarioSubProyecto
              : p
          )
        )
      )
    setEditComentarioSubProyecto(null)
  }
  const deleteComentarioSubProyecto = (id) => {
    comentarioSubProyectoService
      .delete(id, token)
      .then(() =>
        setComentarioSubProyectos(
          comentarioSubProyectos.filter((p) => p.id !== id)
        )
      )
  }

  return (
    <ComentarioSubProyectoContext.Provider
      value={{
        findComentarioSubProyecto,
        createComentarioSubProyecto,
        updateComentarioSubProyecto,
        deleteComentarioSubProyecto,
        editComentarioSubProyecto,
        comentarioSubProyectocargando,
        comentarioSubProyectos,
        loading,
        createBodegaComentarioSubProyecto1
      }}
    >
      {props.children}
    </ComentarioSubProyectoContext.Provider>
  )
}

export default ComentarioSubProyectoContextProvider
