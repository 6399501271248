/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { ResponsableContext } from '../contexts/ResponsableContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import { addLocale } from 'primereact/api'
import { InputTextarea } from 'primereact/inputtextarea'
import moment from 'moment'
import { classNames } from 'primereact/utils'

const ActivoForm = (props) => {
  const initialResponsableForm = {
    id: null,
    nombreResponsable: '',
    direccionResponsable: '',
    totalResponsable: 0,
    avanceResponsable: 0,
    fechaInicioResponsable: '',
    fechaFinalResponsable: '',
    estatusResponsable: '',
    creadoResponsable: moment(),
    modificadoResponsable: moment()
  }

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  const { createResponsable, editResponsable, updateResponsable } =
    useContext(ResponsableContext)

  const { isVisible, setIsVisible } = props
  const [selectedResponsable, setSelectedResponsable] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [ResponsableData, setResponsableData] = useState(initialResponsableForm)
  const estadoResponsable = [
    { estatusResponsable: 'OPERATIVO' },
    { estatusResponsable: 'ANULADO' },

    { estatusResponsable: 'CULMINADO' }
  ]
  const onEstatusResponsable = (e) => {
    setSelectedResponsable(e.value)
    updateField(e.value.estatusResponsable, 'estatusResponsable')
  }

  const toast = useRef(null)

  useEffect(() => {
    if (editResponsable) {
      setResponsableData(editResponsable)
      setSelectedResponsable({
        estatusResponsable: editResponsable.estatusResponsable
      })
    }
  }, [editResponsable])

  const updateField = (data, field) => {
    setResponsableData({
      ...ResponsableData,
      [field]: data
    })
  }

  const saveResponsable = () => {
    setSubmitted(true)
    if (
      ResponsableData.nombreResponsable.trim() &&
      ResponsableData.estatusResponsable.trim()
    ) {
      if (!editResponsable) {
        createResponsable(ResponsableData)
      } else {
        updateResponsable({
          ...ResponsableData,
          ResponsableModificado: moment()
        })
      }
      clearSelected()
    }
  }

  const dialogFooter = (
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => clearSelected()}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveResponsable} />
    </div>
  )

  const clearSelected = () => {
    setIsVisible(false)
    setResponsableData(initialResponsableForm)
    setSelectedResponsable('')
    setSubmitted(false)
  }
  const selectedestatusResponsableTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.estatusResponsable}</div>
        </div>
      )
    }

    return <span>{props.placeholder}</span>
  }

  const estatusResponsableOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.estatusResponsable}</div>
      </div>
    )
  }

  return (
    <div className="dialog-demo">
      <Toast ref={toast} />
      <Dialog
        visible={isVisible}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '30vw' }}
        header="Detalles de la Responsable"
        footer={dialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="p-grid p-fluid">
          <br />
          <div className="p-float-label">
            <InputText
              value={ResponsableData.nombreResponsable}
              onChange={(e) => updateField(e.target.value, 'nombreResponsable')}
              className={classNames({
                'p-invalid': submitted && !ResponsableData.nombreResponsable
              })}
            />
            {submitted && !ResponsableData.nombreResponsable && (
              <small className="p-invalid">Nombre es requerido.</small>
            )}
            <label>Nombre del Responsable:*</label>
          </div>
          <br />
          <div className="p-float-label">
            <InputTextarea
              id="description"
              value={ResponsableData.direccionResponsable}
              onChange={(e) =>
                updateField(e.target.value, 'direccionResponsable')
              }
              rows={3}
              cols={20}
            />

            <label>Direccion:</label>
          </div>
          <br />
          <div className="p-float-label ">
            <Dropdown
              value={selectedResponsable}
              options={estadoResponsable}
              onChange={onEstatusResponsable}
              optionLabel="estatusResponsable"
              placeholder="Seleccione Estado"
              valueTemplate={selectedestatusResponsableTemplate}
              itemTemplate={estatusResponsableOptionTemplate}
              className={classNames({
                'p-invalid': submitted && !ResponsableData.estatusResponsable
              })}
            />
            {submitted && !ResponsableData.estatusResponsable && (
              <small className="p-invalid">Estatus es requerido.</small>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ActivoForm
