/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { SolicitudFondoContext } from '../contexts/SolicitudFondoContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import { addLocale } from 'primereact/api'
import { InputTextarea } from 'primereact/inputtextarea'
import moment from 'moment'
import { classNames } from 'primereact/utils'
import { Calendar } from 'primereact/calendar'
import { InputNumber } from 'primereact/inputnumber'

const ActivoForm = (props) => {
  const initialSolicitudFondoForm = {
    id: null,
    nombreSolicitudFondo: '',
    descripcionSolicitudFondo: '',
    montoSolicitudFondo: 0,
    fechaRequeridaSolicitudFondo: '',
    estatusSolicitudFondo: '',
    creadoSolicitudFondo: moment(),
    modificadoSolicitudFondo: moment()
  }

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  const { createSolicitudFondo, editSolicitudFondo, updateSolicitudFondo } =
    useContext(SolicitudFondoContext)

  const { isVisible, setIsVisible } = props
  const [selectedSolicitudFondo, setSelectedSolicitudFondo] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [SolicitudFondoData, setSolicitudFondoData] = useState(
    initialSolicitudFondoForm
  )
  const [dateRequerida, setDateRequerida] = useState(null)

  const estadoSolicitudFondo = [{ estatusSolicitudFondo: 'ELABORADO' }]
  const onEstatusSolicitudFondo = (e) => {
    setSelectedSolicitudFondo(e.value)
    updateField(e.value.estatusSolicitudFondo, 'estatusSolicitudFondo')
  }

  const toast = useRef(null)

  useEffect(() => {
    if (editSolicitudFondo) {
      setSolicitudFondoData(editSolicitudFondo)
      setSelectedSolicitudFondo({
        estatusSolicitudFondo: editSolicitudFondo.estatusSolicitudFondo
      })
    }
  }, [editSolicitudFondo])

  const updateField = (data, field) => {
    setSolicitudFondoData({
      ...SolicitudFondoData,
      [field]: data
    })
  }

  const saveSolicitudFondo = () => {
    setSubmitted(true)
    if (
      SolicitudFondoData.nombreSolicitudFondo.trim() &&
      SolicitudFondoData.estatusSolicitudFondo.trim()
    ) {
      if (!editSolicitudFondo) {
        createSolicitudFondo(SolicitudFondoData)
      } else {
        updateSolicitudFondo({
          ...SolicitudFondoData,
          solicitudFondoModificado: moment()
        })
      }
      clearSelected()
    }
  }

  const dialogFooter = (
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => clearSelected()}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveSolicitudFondo} />
    </div>
  )

  const clearSelected = () => {
    setIsVisible(false)
    setSolicitudFondoData(initialSolicitudFondoForm)
    setSelectedSolicitudFondo('')
    setSubmitted(false)
  }

  return (
    <div className="dialog-demo">
      <Toast ref={toast} />
      <Dialog
        visible={isVisible}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '30vw' }}
        header="Detalles de la SolicitudFondo"
        footer={dialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="p-grid p-fluid">
          <br />
          <div className="p-float-label">
            <InputText
              value={SolicitudFondoData.nombreSolicitudFondo}
              onChange={(e) =>
                updateField(e.target.value, 'nombreSolicitudFondo')
              }
              className={classNames({
                'p-invalid':
                  submitted && !SolicitudFondoData.nombreSolicitudFondo
              })}
            />
            {submitted && !SolicitudFondoData.nombreSolicitudFondo && (
              <small className="p-invalid">Nombre es requerido.</small>
            )}
            <label>Nombre del SolicitudFondo:*</label>
          </div>
          <br />
          <div className="p-float-label">
            <InputTextarea
              id="description"
              value={SolicitudFondoData.descripcionSolicitudFondo}
              onChange={(e) =>
                updateField(e.target.value, 'descripcionSolicitudFondo')
              }
              rows={3}
              cols={20}
            />

            <label>Descripcion:</label>
          </div>

          <div className="p-float-label mt-4 ">
            <InputNumber
              inputId="montoSolicitudFondo"
              value={SolicitudFondoData.montoSolicitudFondo}
              onValueChange={(e) =>
                updateField(e.target.value, 'montoSolicitudFondo')
              }
              mode="currency"
              currency="USD"
              locale="en-US"
              className={classNames({
                'p-invalid':
                  submitted && !SolicitudFondoData.montoSolicitudFondo
              })}
            />
            {submitted && !SolicitudFondoData.montoSolicitudFondo && (
              <small className="p-invalid">Monto es requerido.</small>
            )}
            <label>Monto de solicitud:</label>
          </div>
          <div className="p-float-label mt-4">
            <Calendar
              // className="p-datepicker-today"
              id="time24"
              value={dateRequerida !== null && dateRequerida}
              onChange={(e) => {
                setDateRequerida(e.value)
                updateField(e.target.value, 'fechaRequeridaSolicitudFondo')
              }}
              showTime
              locale="es"
              // hourFormat="12"
              showButtonBar
              className={classNames(
                {
                  'p-invalid':
                    submitted &&
                    !SolicitudFondoData.fechaRequeridaSolicitudFondo
                },
                'p-datepicker-today'
              )}
            />{' '}
            {submitted && !SolicitudFondoData.estatusSolicitudFondo && (
              <small className="p-invalid">Fecha es requerido.</small>
            )}
            <label>Fecha Requerida:</label>
          </div>
          <div className="p-float-label mt-4">
            <Dropdown
              value={selectedSolicitudFondo}
              options={estadoSolicitudFondo}
              onChange={onEstatusSolicitudFondo}
              optionLabel="estatusSolicitudFondo"
              className={classNames({
                'p-invalid':
                  submitted && !SolicitudFondoData.estatusSolicitudFondo
              })}
            />
            {submitted && !SolicitudFondoData.estatusSolicitudFondo && (
              <small className="p-invalid">Estatus es requerido.</small>
            )}
            <label>Estatus de la Solicitud:</label>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ActivoForm
