/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { AvancePresupuestoContext } from '../contexts/AvancePresupuestoContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { addLocale } from 'primereact/api'
import { PresupuestoContext } from '../contexts/PresupuestoContext'

import moment from 'moment'
import { ProyectoContext } from '../contexts/ProyectoContext'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { classNames } from 'primereact/utils'
import { InputTextarea } from 'primereact/inputtextarea'

const AvancePresupuestoForm = (props) => {
  const initialAvancePresupuestoForm = {
    id: null,

    descripcionAvancePresupuesto: '',

    totalAvancePresupuesto: 0,
    totalAvanceRealPresupuesto: 0,

    fechaEfectivaAvancePresupuesto: '',

    estatusAvancePresupuesto: '',
    creadoAvancePresupuesto: moment(),
    modificadoAvancePresupuesto: moment()
  }

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  const {
    createAvancePresupuesto,
    editAvancePresupuesto,
    updateAvancePresupuesto
  } = useContext(AvancePresupuestoContext)
  const { presupuestos } = useContext(PresupuestoContext)
  const { subProyectos } = useContext(SubProyectoContext)
  const { proyectos } = useContext(ProyectoContext)
  const { isVisible, setIsVisible, editPresupuesto } = props
  const [selectedAvancePresupuesto, setSelectedAvancePresupuesto] =
    useState(null)

  const [selectedPresupuesto, setSelectedPresupuesto] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [selectedProyecto, setSelectedProyecto] = useState(null)
  const [selectedSubProyecto, setSelectedSubProyecto] = useState(null)

  const [subProyecto, setSubProyecto] = useState(subProyectos)
  const [presupuesto, setPresupuesto] = useState(presupuestos)
  const [AvancePresupuestoData, setAvancePresupuestoData] = useState(
    initialAvancePresupuestoForm
  )
  const estadoAvancePresupuesto = [
    { estatusAvancePresupuesto: 'OPERATIVO' },
    { estatusAvancePresupuesto: 'ANULADO' },
    { estatusAvancePresupuesto: 'CULMINADO' }
  ]

  const onEstatusAvancePresupuesto = (e) => {
    setSelectedAvancePresupuesto(e.value)
    updateField(e.value.estatusAvancePresupuesto, 'estatusAvancePresupuesto')
  }

  const onProyecto = (e) => {
    if (e.value) {
      const subProyectoFilter = subProyectos.filter(
        (p) => p.proyectoId?.id === e.value.id
      )
      setSelectedProyecto(e.value)
      setSubProyecto(subProyectoFilter)
    } else {
      setSelectedProyecto(null)
      setSubProyecto(null)

      setSelectedPresupuesto(null)
    }
  }
  const onSubProyecto = (e) => {
    if (e.value) {
      const presupuestoFilter = presupuestos.filter(
        (p) => p.subProyectoId?.id === e.value.id
      )
      setPresupuesto(presupuestoFilter)
      setSelectedSubProyecto(e.value)
      updateField(e.value.id, 'subProyectoId')
    } else {
      setSelectedSubProyecto(null)
      updateField(null, 'subProyectoId')
    }
  }

  const onPresupuesto = (e) => {
    e.value
      ? (setSelectedPresupuesto(e.value),
        updateField(e.value.id, 'presupuestoId'))
      : (setSelectedPresupuesto(null), updateField(null, 'presupuestoId'))
  }
  const [dateInicio, setDateInicio] = useState(null)

  const toast = useRef(null)

  useEffect(() => {
    if (editAvancePresupuesto) {
      setAvancePresupuestoData({
        ...editAvancePresupuesto,
        presupuestoId: editAvancePresupuesto.presupuestoId?.id
      })
      setSelectedAvancePresupuesto({
        estatusAvancePresupuesto: editAvancePresupuesto.estatusAvancePresupuesto
      })
      const conceptoAuxSelecEdit =
        editAvancePresupuesto.conceptoAuxId &&
        presupuestos.find(
          (p) => p.id === editAvancePresupuesto.conceptoAuxId.id
        )

      setSelectedPresupuesto(conceptoAuxSelecEdit)
      setDateInicio(
        editAvancePresupuesto.fechaEfectivaAvancePresupuesto &&
          moment(editAvancePresupuesto.fechaEfectivaAvancePresupuesto)._d
      )
    }
  }, [editAvancePresupuesto])
  useEffect(() => {
    if (editPresupuesto) {
      //   const proveedorSelecEdit =
      //   editPresupuesto.proveedorId &&
      //   proveedors.find((p) => p.id === editPresupuesto.proveedorId.id)
      // setSelectedProveedor(proveedorSelecEdit)

      const presupuestoSelecEdit =
        editPresupuesto.id &&
        presupuestos.find((p) => p.id === editPresupuesto.id)
      setSelectedPresupuesto(presupuestoSelecEdit)
      updateField(presupuestoSelecEdit.id, 'presupuestoId')
    }
  }, [editPresupuesto, isVisible])

  const updateField = (data, field) => {
    setAvancePresupuestoData({
      ...AvancePresupuestoData,
      [field]: data
    })
  }

  const saveAvancePresupuesto = () => {
    setSubmitted(true)
    if (
      AvancePresupuestoData.totalAvancePresupuesto !== null &&
      AvancePresupuestoData.totalAvancePresupuesto !== 0 &&
      AvancePresupuestoData.estatusAvancePresupuesto.trim() &&
      AvancePresupuestoData.fechaEfectivaAvancePresupuesto !== null
    ) {
      if (!editAvancePresupuesto) {
        createAvancePresupuesto(AvancePresupuestoData)
      } else {
        updateAvancePresupuesto({
          ...AvancePresupuestoData,
          AvancePresupuestoModificado: moment()
        })
      }
      clearSelected()
    }
  }

  const dialogFooter = (
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => clearSelected()}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        onClick={saveAvancePresupuesto}
      />
    </div>
  )

  const clearSelected = () => {
    setIsVisible(false)
    setAvancePresupuestoData(initialAvancePresupuestoForm)
    setSelectedAvancePresupuesto('')
    setSelectedPresupuesto(null)
    setSelectedProyecto(null)
    setSelectedSubProyecto(null)
    setDateInicio(null)

    setSubmitted(false)
  }

  return (
    <div className="dialog-demo">
      <Toast ref={toast} />
      <Dialog
        visible={isVisible}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '50vw' }}
        header="Detalles de la AvancePresupuesto"
        footer={dialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="p-grid p-fluid">
          <div className="formgrid grid">
            {!editPresupuesto && (
              <>
                <div className="field col-12 md:col-6  mt-3">
                  <span className="p-float-label">
                    <Dropdown
                      inputId="dropdown"
                      value={selectedProyecto}
                      options={proyectos}
                      onChange={onProyecto}
                      optionLabel="nombreProyecto"
                      showClear
                      filter
                      filterBy="nombreProyecto"
                      className={classNames({
                        'p-invalid': submitted && !selectedProyecto
                      })}
                    />
                    {submitted && !selectedProyecto && (
                      <small className="p-invalid">
                        Proyecto es requerido.
                      </small>
                    )}
                    <label htmlFor="dropdown">Seleccione Proyecto*</label>
                  </span>
                </div>
                <div className="field col-12 md:col-6  mt-3 ">
                  <span className="p-float-label ">
                    <Dropdown
                      value={selectedSubProyecto}
                      options={subProyecto}
                      onChange={onSubProyecto}
                      optionLabel="nombreSubProyecto"
                      showClear
                      filter
                      filterBy="nombrePresupuesto"
                      disabled={!selectedProyecto}
                      className={classNames({
                        'p-invalid':
                          submitted && !AvancePresupuestoData.subProyectoId
                      })}
                    />
                    {submitted && !AvancePresupuestoData.subProyectoId && (
                      <small className="p-invalid">
                        Sub Proyecto es requerido.
                      </small>
                    )}
                    <label htmlFor="dropdown">Seleccione Sub Proyecto*</label>
                  </span>
                </div>
                <div className="field col-12 md:col-6 mt-3 ">
                  <span className="p-float-label ">
                    <Dropdown
                      value={selectedPresupuesto}
                      options={presupuesto}
                      onChange={onPresupuesto}
                      optionLabel="nombrePresupuesto"
                      showClear
                      filter
                      filterBy="nombrePresupuesto"
                      disabled={!selectedSubProyecto && !editPresupuesto}
                      className={classNames({
                        'p-invalid':
                          submitted && !AvancePresupuestoData.subProyectoId
                      })}
                    />
                    <label htmlFor="dropdown">Seleccione Presupuesto*</label>
                  </span>
                </div>
              </>
            )}

            <div className="p-float-label col-12 md:col-12 mt-3 ">
              <InputTextarea
                id="description"
                value={AvancePresupuestoData.descripcionAvancePresupuesto}
                onChange={(e) =>
                  updateField(e.target.value, 'descripcionAvancePresupuesto')
                }
                rows={3}
                cols={20}
              />

              <label>Descripción:</label>
            </div>
            <br />

            <div className="field col-6 mt-4">
              <span className="p-float-label ">
                <InputNumber
                  inputId="totalAvancePresupuesto"
                  value={AvancePresupuestoData.totalAvancePresupuesto}
                  onValueChange={(e) =>
                    updateField(e.target.value, 'totalAvancePresupuesto')
                  }
                  suffix="%"
                  className={classNames({
                    'p-invalid':
                      submitted && !AvancePresupuestoData.totalAvancePresupuesto
                  })}
                />
                {submitted && !AvancePresupuestoData.totalAvancePresupuesto && (
                  <small className="p-invalid">Precio es requerido.</small>
                )}
                <label htmlFor="totalAvancePresupuesto">
                  Total Avance Plan
                </label>
              </span>
            </div>
            <div className="field col-6 mt-4">
              <span className="p-float-label ">
                <InputNumber
                  inputId="totalAvanceRealPresupuesto"
                  value={AvancePresupuestoData.totalAvanceRealPresupuesto}
                  onValueChange={(e) =>
                    updateField(e.target.value, 'totalAvanceRealPresupuesto')
                  }
                  suffix="%"
                  className={classNames({
                    'p-invalid':
                      submitted &&
                      !AvancePresupuestoData.totalAvanceRealPresupuesto
                  })}
                />
                {submitted &&
                  !AvancePresupuestoData.totalAvanceRealPresupuesto && (
                    <small className="p-invalid">Precio es requerido.</small>
                  )}
                <label htmlFor="totalAvanceRealPresupuesto">
                  Total Avance Real
                </label>
              </span>
            </div>

            <div className="field col-12 md:col-6 mt-3">
              <span className="p-float-label ">
                <Dropdown
                  value={selectedAvancePresupuesto}
                  options={estadoAvancePresupuesto}
                  onChange={onEstatusAvancePresupuesto}
                  optionLabel="estatusAvancePresupuesto"
                  className={classNames({
                    'p-invalid':
                      submitted &&
                      !AvancePresupuestoData.estatusAvancePresupuesto
                  })}
                />
                {submitted &&
                  !AvancePresupuestoData.estatusAvancePresupuesto && (
                    <small className="p-invalid">Unidad es requerido.</small>
                  )}
                <label>Selecione Estado</label>
              </span>
            </div>

            <div className="field col-12 md:col-6 mt-3">
              <span className="p-float-label ">
                <Calendar
                  // className="p-datepicker-today"
                  id="time24"
                  value={dateInicio !== null && dateInicio}
                  onChange={(e) => {
                    setDateInicio(e.value)
                    updateField(
                      e.target.value,
                      'fechaEfectivaAvancePresupuesto'
                    )
                  }}
                  showTime
                  locale="es"
                  // hourFormat="12"
                  showButtonBar
                  className={classNames(
                    {
                      'p-invalid':
                        submitted &&
                        !AvancePresupuestoData.fechaEfectivaAvancePresupuesto
                    },
                    'p-datepicker-today'
                  )}
                />{' '}
                {submitted &&
                  !AvancePresupuestoData.fechaEfectivaAvancePresupuesto && (
                    <small className="p-invalid">
                      Fecha Efectiva es requerido.
                    </small>
                  )}
                <label>Fecha Efectiva </label>
              </span>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default AvancePresupuestoForm
