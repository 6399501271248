/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'
import { ProyectoService } from '../services/ProyectoService'
import AuthUse from '../../../auth/AuthUse'
// import { CargaBodegaService } from '../services/CargaBodegaService'
export const ProyectoContext = createContext()

const ProyectoContextProvider = (props) => {
  const proyectoService = new ProyectoService()
  // const cargaBodegaService = new CargaBodegaService()

  const [proyectos, setProyectos] = useState([])
  const [createBodegaProyecto1, setCreateBodegaProyecto1] = useState([])
  const [proyectocargando, setProyectocargando] = useState(null)
  const [editProyecto, setEditProyecto] = useState(null)
  const [loading, setLoading] = useState(true)
  const [actuallizar, setActuallizar] = useState(null)

  const auth = AuthUse()
  const token = auth.user.token

  useEffect(() => {
    proyectoService.readAll(token).then((data) => {
      setProyectos(data)
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      proyectoService.readAll(token).then((data) => {
        setProyectos(data)
        setLoading(false)
      })
      setActuallizar(actuallizar + 1)
    }, 600000)
    return () => {
      clearInterval(interval)
    }
  }, [actuallizar])

  useEffect(() => {
    const proyecto = proyectos.find((p) => p.estatusProyecto === 'OPERATIVO')
    setProyectocargando(proyecto)
  }, [proyectos])

  const findProyecto = (id) => {
    const proyecto = proyectos.find((p) => p.id === id)
    setEditProyecto(proyecto)
  }

  const createProyecto = (proyecto) => {
    proyectoService.create(proyecto, token).then((data) => {
      setProyectos([...proyectos, data.saveProyecto])
      createBodegaProyecto(data.saveProyecto)
      setCreateBodegaProyecto1(data.saveProyecto)
    })
  }
  const createBodegaProyecto = (saveProyecto) => {
    const cargaBodega = {
      proyectoID: saveProyecto.id,
      nombreBodega: '',
      estatusCargaBodega: 'CARGANDO',
      toneladasCargadasBodega: 0,
      toneladasCapacidadBodega: 0
    }

    // let i = 1

    // do {
    //   cargaBodega.nombreBodega = `BODEGA ${i}`
    //   cargaBodegaService.create(cargaBodega, token).then(i++)
    // } while (i <= saveProyecto.cantidadBodegas)
    for (let i = 1; i <= saveProyecto.cantidadBodegas; i++) {
      cargaBodega.nombreBodega = `BODEGA ${i}`
    }
    // proyectoService.create(CargaBodega, token).then((data) => {
    //   setProyectos([...proyectos, data.saveProyecto])
    //   console.log('proyecto creado', data.saveProyecto)
    // })
  }

  const updateProyecto = (proyecto) => {
    proyectoService
      .update(proyecto, token)
      .then((data) =>
        setProyectos(
          proyectos.map((p) => (p.id === proyecto.id ? data.updateProyecto : p))
        )
      )
    setEditProyecto(null)
  }
  const deleteProyecto = (id) => {
    proyectoService
      .delete(id, token)
      .then(() => setProyectos(proyectos.filter((p) => p.id !== id)))
  }

  return (
    <ProyectoContext.Provider
      value={{
        findProyecto,
        createProyecto,
        updateProyecto,
        deleteProyecto,
        editProyecto,
        proyectocargando,
        proyectos,
        loading,
        createBodegaProyecto1
      }}
    >
      {props.children}
    </ProyectoContext.Provider>
  )
}

export default ProyectoContextProvider
