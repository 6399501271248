/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
import { useContext, useEffect, useState } from 'react'
import { Chart } from 'primereact/chart'
import { IngresoGastoContext } from '../contexts/IngresoGastoContext'
import moment from 'moment'
import { Dropdown } from 'primereact/dropdown'
import { CentroDeCostoAuxContext } from '../contexts/CentroDeCostoAuxContext'
import IngresoVsGastoDetalleCentroDeCosto from './IngresoVsGastoDetalleCentroDeCosto'
import { AbonoPresupuestoContext } from '../../Control/contexts/AbonoPresupuestoContext'

const IngresoVsGastoDetalleWR = () => {
  const { ingresoGastos } = useContext(IngresoGastoContext)
  const { centroDeCostoAuxs } = useContext(CentroDeCostoAuxContext)
  const { abonoPresupuestos } = useContext(AbonoPresupuestoContext)

  const newArr = abonoPresupuestos.map((obj) => {
    return {
      ...obj,
      fechaIngresoGasto: obj.fechaEfectivaAbonoPresupuesto,
      descripcionIngresoGasto: obj.descripcionAbonoPresupuesto,
      egresoIngresoGasto: obj.totalAbonoPresupuesto,
      estatusIngresoGasto: 'AUTOMATICO',
      conceptoAuxId: {
        nombreConceptoAux: 'VALUACION'
      },
      centroDeCostoAuxId: {
        nombreCentroDeCosto:
          obj.presupuestoId?.subProyectoId?.proyectoId?.nombreProyecto
      }
    }
  })
  const combinedArray = [...ingresoGastos, ...newArr]
  const [totalIngresoAnoRender, setTotalIngresoAnoRender] = useState(0)
  const [totalEgresoAnoRender, setTotalEgresoAnoRender] = useState(0)
  const [totalEgresoGlobal, setTotalEgresoGlobal] = useState(0)
  const [totalIngresoGlobal, setTotalIngresoGlobal] = useState(0)
  const [totalIngresosCarameloMes, serTotalIngresosCarameloMes] = useState(0)
  const [totalEgresosCarameloMes, serTotalEgresosCarameloMes] = useState(0)
  const [totalIngresosTrinidadMes, serTotalIngresosTrinidadMes] = useState(0)
  const [totalEgresosTrinidadMes, serTotalEgresosTrinidadMes] = useState(0)
  const [totalIngresosCuidadCaramelodMes, serTotalIngresosCuidadCaramelodMes] =
    useState(0)
  const [totalEgresosCuidadCaramelodMes, serTotalEgresosCuidadCaramelodMes] =
    useState(0)
  const [anoVisual, setAnoVisual] = useState('2024')
  const [selectedEstadoAno, setSelectedEstadoAno] = useState({
    anoActual: '2024'
  })
  const estadoAno = [
    { anoActual: '2021' },
    { anoActual: '2022' },
    { anoActual: '2023' },
    { anoActual: '2024' }
  ]
  const onEstatusPresupuesto = (e) => {
    setSelectedEstadoAno(e.value)
    setAnoVisual(e.value.anoActual)
  }
  const [chartData1, setChartData1] = useState({
    labels: ['Inversión', 'EGRESO'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#094db1', '#a31220'],
        hoverBackgroundColor: ['#64B5F6', '#97313b']
      }
    ]
  })
  const [lightOptions1] = useState({
    plugins: {
      legend: {
        labels: {
          font: {
            size: 18
          }
          // color: '#fffcf3'
        },
        title: {
          display: true,
          text: 'Inversión VS GASTOS',
          // color: '#fffcf3'
          font: {
            size: 22
          }
        }
      }
    }
  })
  const [basicData, setBasicData] = useState({
    labels: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    datasets: [
      {
        label: 'Total Gasto',
        backgroundColor: '#42A5F5',
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56]
      },
      {
        label: 'Total De TM',
        backgroundColor: '#FFA726',
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56]
      }
    ]
  })

  useEffect(() => {
    setBasicData({ ...setBasicData, labels: auxOtro3, datasets: auxOtro4 })
    setChartData1({ ...chartData1, datasets: auxOtro1 })
    ingresosgastosMes()
  }, [ingresoGastos, anoVisual])
  const getLightTheme = () => {
    const basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            // color: '#212529'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            // color: '#212529'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            // color: '#212529'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    }

    return {
      basicOptions
    }
  }

  const { basicOptions } = getLightTheme()

  // cabecera de la tabla
  const mesesDelAno = [
    `${anoVisual}-01-20`,
    `${anoVisual}-02-20`,
    `${anoVisual}-03-20`,
    `${anoVisual}-04-20`,
    `${anoVisual}-05-20`,
    `${anoVisual}-06-20`,
    `${anoVisual}-07-20`,
    `${anoVisual}-08-20`,
    `${anoVisual}-09-20`,
    `${anoVisual}-10-20`,
    `${anoVisual}-11-20`,
    `${anoVisual}-12-20`
  ]
  const mesesDelAnoNombre = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
  ]
  let auxOtro1 = []
  let auxOtro2 = []
  let auxOtro3 = []
  let auxOtro4 = []

  let ingresosTotalesMes = []
  let egresosTotalesMes = []
  let ingresosCarameloMes = 0
  let egresosCarameloMes = 0
  let ingresosCiudadCarameloMes = 0
  let egresosCiudadCarameloMes = 0
  let ingresosTrinidadMes = 0
  let egresosTrinidadMes = 0
  let totalIngresoAno = 0
  let totalEgresoAno = 0
  const ingresosgastosMes = () => {
    mesesDelAno.forEach((dataset, i) => {
      let ingresoGasto = ingresoGastos.filter((p) =>
        moment(dataset).isSame(p.fechaIngresoGasto, 'month')
      )
      let ingresoGastoCarameloMes = ingresoGasto.filter(
        (p) =>
          p.centroDeCostoAuxId !== null &&
          p.centroDeCostoAuxId.id === '63cdca2c1a5853c01ad9d0d8'
      )

      let ingresoGastoCiudadCarameloMes = ingresoGasto.filter(
        (p) =>
          p.centroDeCostoAuxId !== null &&
          p.centroDeCostoAuxId.id === '63cdca4f1a5853c01ad9d0de'
      )
      let ingresoGastoTrinidadMes = ingresoGasto.filter(
        (p) =>
          p.centroDeCostoAuxId !== null &&
          p.centroDeCostoAuxId.id === '63cdca611a5853c01ad9d0e1'
      )
      const totalIngreso = ingresoGasto
        .map((ingreso) => ingreso.ingresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      const totalEgreso = ingresoGasto
        .map((egreso) => egreso.egresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      let mes = mesesDelAnoNombre[i]
      const totalingresoGastoMaroil = ingresoGastoCarameloMes
        .map((ingreso) => ingreso.ingresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      const totalegresoGastoMaroil = ingresoGastoCarameloMes
        .map((egreso) => egreso.egresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      const totalingresoSanFelix = ingresoGastoCiudadCarameloMes
        .map((ingreso) => ingreso.ingresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      const totalegresoSanFelix = ingresoGastoCiudadCarameloMes
        .map((egreso) => egreso.egresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      const totalingresoGastoCedeno = ingresoGastoTrinidadMes
        .map((ingreso) => ingreso.ingresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      const totalingresoGastoTrinidad = ingresoGastoTrinidadMes
        .map((egreso) => egreso.egresoIngresoGasto)
        .reduce((a, b) => a + b, 0)

      const totalIngresoGlobal = ingresoGastos
        .map((ingreso) => ingreso.ingresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      const totalEgresoGlobal = ingresoGastos
        .map((egreso) => egreso.egresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      setTotalEgresoGlobal(totalEgresoGlobal)
      setTotalIngresoGlobal(totalIngresoGlobal)
      // auxOtro2.push({ [mes]: totalIngreso })
      totalEgresoAno = totalEgresoAno + totalEgreso
      totalIngresoAno = totalIngresoAno + totalIngreso
      auxOtro2.push({ mesNombre: mes, totalGastoMes: totalIngreso })
      ingresosTotalesMes = ingresosTotalesMes.concat(totalIngreso)
      egresosTotalesMes = egresosTotalesMes.concat(totalEgreso)
      ingresosCarameloMes = ingresosCarameloMes + totalingresoGastoMaroil
      egresosCarameloMes = egresosCarameloMes + totalegresoGastoMaroil
      ingresosCiudadCarameloMes =
        ingresosCiudadCarameloMes + totalingresoSanFelix
      egresosCiudadCarameloMes = egresosCiudadCarameloMes + totalegresoSanFelix
      ingresosTrinidadMes = ingresosTrinidadMes + totalingresoGastoCedeno
      egresosTrinidadMes = egresosTrinidadMes + totalingresoGastoTrinidad
    })
    auxOtro1.push({
      data: [totalIngresoAno, totalEgresoAno],
      backgroundColor: ['#094db1', '#198754'],
      hoverBackgroundColor: ['#64B5F6', '#198754']
    })
    setTotalIngresoAnoRender(totalIngresoAno)
    setTotalEgresoAnoRender(totalEgresoAno)
    serTotalIngresosCarameloMes(ingresosCarameloMes)
    serTotalEgresosCarameloMes(egresosCarameloMes)
    serTotalIngresosTrinidadMes(ingresosTrinidadMes)
    serTotalEgresosTrinidadMes(egresosTrinidadMes)
    serTotalIngresosCuidadCaramelodMes(ingresosCiudadCarameloMes)
    serTotalEgresosCuidadCaramelodMes(egresosCiudadCarameloMes)
    // setIngresoGastoData({
    //   ...ingresoGastoData,
    //   [mesesDelAnoNombre]: diasTotales
    // })
    // auxOtro2.push(diasTotales)

    auxOtro3.push(...mesesDelAnoNombre)
    auxOtro4.push(
      {
        type: 'bar',
        label: 'Gastos Totales',
        backgroundColor: '#198754',
        data: [...egresosTotalesMes]
      },
      {
        type: 'bar',
        label: 'Inversión Totales',
        backgroundColor: '#0d6efd',
        data: [...ingresosTotalesMes]
      }
    )
  }
  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }
  return (
    <div className="card">
      <div className="field col-12 md:col-12 text-900 text-2xl font-medium">
        <span>
          Ingreso Global{' '}
          <span style={{ color: '#0d6efd' }}>
            {formatCurrency(totalIngresoGlobal)}
          </span>
        </span>

        <span>
          {' '}
          Egreso Global{' '}
          <span style={{ color: '#198754' }}>
            {formatCurrency(totalEgresoGlobal)}
          </span>
        </span>
        <span>
          {' '}
          Acumulado Global{' '}
          <span style={{ color: '#dc3545' }}>
            {formatCurrency(totalIngresoGlobal - totalEgresoGlobal)}
          </span>
        </span>
      </div>
      <div className="field col-12 md:col-6">
        <Dropdown
          value={selectedEstadoAno}
          options={estadoAno}
          onChange={onEstatusPresupuesto}
          optionLabel="anoActual"
        />
      </div>
      <h5>Inversión VS GASTOS </h5>
      {ingresoGastos.length !== 0 && (
        <>
          {/* <Chart type="bar" data={basicData} options={basicOptions} /> */}
          <div className="card">
            <div className="grid ">
              <div className="col-12 lg:col-6 xl:col-6">
                <div className=" mb-0">
                  <Chart
                    type="pie"
                    data={chartData1}
                    options={lightOptions1}
                    style={{ width: '300px', height: '5px' }}
                    // style={{ position: 'relative', width: '100%', left: '35%' }}
                  />
                </div>
              </div>
              <div className="col-12 lg:col-6 xl:col-6 text-center">
                <div className=" mb-0">
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <h4 className="block text-900 font-medium mb-3">
                        Comparativa Inversión Vs Egreso
                      </h4>
                      <hr style={{ width: '100%', height: '5px' }} />
                      <div className="text-900 font-medium text-xl mb-3">
                        Inversión{' '}
                        <span className="text-blue-400 font-medium">
                          {formatCurrency(totalIngresoAnoRender)}
                        </span>{' '}
                      </div>
                      <div className="text-900 font-medium text-xl mb-5">
                        Gastos{' '}
                        <span className="text-green-500 font-medium">
                          {formatCurrency(totalEgresoAnoRender)}
                        </span>{' '}
                      </div>

                      <hr style={{ width: '100%', height: '5px' }} />

                      <div className="text-900 font-medium text-xl mb-3">
                        Inversión{' '}
                        <span className="text-blue-400 font-medium">
                          {(
                            (100 * totalIngresoAnoRender) /
                            (totalIngresoAnoRender + totalEgresoAnoRender)
                          ).toFixed(2)}
                          %
                        </span>
                      </div>
                      <div className="text-900 font-medium text-xl mb-5">
                        Gastos{' '}
                        <span className="text-green-500 font-medium">
                          {(
                            (100 * totalEgresoAnoRender) /
                            (totalIngresoAnoRender + totalEgresoAnoRender)
                          ).toFixed(2)}
                          %
                        </span>
                      </div>
                      <div className="text-900 font-medium text-xl">
                        Saldo{' '}
                        <span className="text-pink-400 font-medium">
                          {formatCurrency(
                            totalIngresoAnoRender - totalEgresoAnoRender
                          )}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="grid ">
              {centroDeCostoAuxs.length !== 0 &&
                centroDeCostoAuxs.map((centroDeCostoAux) => (
                  <IngresoVsGastoDetalleCentroDeCosto
                    key={centroDeCostoAux.id}
                    centroDeCostoAux={centroDeCostoAux}
                    ingresoGastos={combinedArray}
                    anoVisual={anoVisual}
                    formatCurrency={formatCurrency}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default IngresoVsGastoDetalleWR

// data vieja o medelo viejo
// <div className="col-12 lg:col-4 xl:col-4 text-center">
//                 <div className=" mb-0">
//                   <div className="flex justify-content-between mb-3">
//                     <div>
//                       <h4 className="block text-900 font-medium mb-3">
//                         Caramelo
//                       </h4>
//                       <hr style={{ width: '100%', height: '5px' }} />
//                       <div className="text-900 font-medium text-xl mb-3">
//                         Inversión{' '}
//                         <span className="text-blue-400 font-medium">
//                           {formatCurrency(totalIngresosCarameloMes)}
//                         </span>{' '}
//                       </div>
//                       <div className="text-900 font-medium text-xl mb-5">
//                         Gastos{' '}
//                         <span className="text-green-500 font-medium">
//                           {formatCurrency(totalEgresosCarameloMes)}
//                         </span>{' '}
//                       </div>

//                       <hr style={{ width: '100%', height: '5px' }} />

//                       <div className="text-900 font-medium text-xl mb-3">
//                         Inversión{' '}
//                         <span className="text-blue-400 font-medium">
//                           {(
//                             (100 * totalIngresosCarameloMes) /
//                             (totalIngresosCarameloMes + totalEgresosCarameloMes)
//                           ).toFixed(2)}
//                           %
//                         </span>
//                       </div>
//                       <div className="text-900 font-medium text-xl mb-5">
//                         Gastos{' '}
//                         <span className="text-green-500 font-medium">
//                           {(
//                             (100 * totalEgresosCarameloMes) /
//                             (totalEgresosCarameloMes + totalIngresosCarameloMes)
//                           ).toFixed(2)}
//                           %
//                         </span>
//                       </div>
//                       <div className="text-900 font-medium text-xl">
//                         Saldo{' '}
//                         <span className="text-pink-400 font-medium">
//                           {formatCurrency(
//                             totalIngresosCarameloMes - totalEgresosCarameloMes
//                           )}
//                         </span>{' '}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12 lg:col-4 xl:col-4 text-center">
//                 <div className=" mb-0">
//                   <div className="flex justify-content-between mb-3">
//                     <div>
//                       <h4 className="block text-900 font-medium mb-3">
//                         Trinidad
//                       </h4>
//                       <hr style={{ width: '100%', height: '5px' }} />
//                       <div className="text-900 font-medium text-xl mb-3">
//                         Inversión{' '}
//                         <span className="text-blue-400 font-medium">
//                           {formatCurrency(totalIngresosTrinidadMes)}
//                         </span>{' '}
//                       </div>
//                       <div className="text-900 font-medium text-xl mb-5">
//                         Gastos{' '}
//                         <span className="text-green-500 font-medium">
//                           {formatCurrency(totalEgresosTrinidadMes)}
//                         </span>{' '}
//                       </div>

//                       <hr style={{ width: '100%', height: '5px' }} />

//                       <div className="text-900 font-medium text-xl mb-3">
//                         Inversión{' '}
//                         <span className="text-blue-400 font-medium">
//                           {(
//                             (100 * totalIngresosTrinidadMes) /
//                             (totalIngresosTrinidadMes + totalEgresosTrinidadMes)
//                           ).toFixed(2)}
//                           %
//                         </span>
//                       </div>
//                       <div className="text-900 font-medium text-xl mb-5">
//                         Gastos{' '}
//                         <span className="text-green-500 font-medium">
//                           {(
//                             (100 * totalEgresosTrinidadMes) /
//                             (totalIngresosTrinidadMes + totalEgresosTrinidadMes)
//                           ).toFixed(2)}
//                           %
//                         </span>
//                       </div>
//                       <div className="text-900 font-medium text-xl">
//                         Saldo{' '}
//                         <span className="text-pink-400 font-medium">
//                           {formatCurrency(
//                             totalIngresosTrinidadMes - totalEgresosTrinidadMes
//                           )}
//                         </span>{' '}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12 lg:col-4 xl:col-4 text-center">
//                 <div className=" mb-0">
//                   <div className="flex justify-content-between mb-3">
//                     <div>
//                       <h4 className="block text-900 font-medium mb-3">
//                         Cuidad Caramelo
//                       </h4>
//                       <hr style={{ width: '100%', height: '5px' }} />
//                       <div className="text-900 font-medium text-xl mb-3">
//                         Inversión{' '}
//                         <span className="text-blue-400 font-medium">
//                           {formatCurrency(totalIngresosCuidadCaramelodMes)}
//                         </span>{' '}
//                       </div>
//                       <div className="text-900 font-medium text-xl mb-5">
//                         Gastos{' '}
//                         <span className="text-green-500 font-medium">
//                           {formatCurrency(totalEgresosCuidadCaramelodMes)}
//                         </span>{' '}
//                       </div>

//                       <hr style={{ width: '100%', height: '5px' }} />

//                       <div className="text-900 font-medium text-xl mb-3">
//                         Inversión{' '}
//                         <span className="text-blue-400 font-medium">
//                           {(
//                             (100 * totalIngresosCuidadCaramelodMes) /
//                             (totalIngresosCuidadCaramelodMes +
//                               totalEgresosCuidadCaramelodMes)
//                           ).toFixed(2)}
//                           %
//                         </span>
//                       </div>
//                       <div className="text-900 font-medium text-xl mb-5">
//                         Gastos{' '}
//                         <span className="text-green-500 font-medium">
//                           {(
//                             (100 * totalEgresosCuidadCaramelodMes) /
//                             (totalIngresosCuidadCaramelodMes +
//                               totalEgresosCuidadCaramelodMes)
//                           ).toFixed(2)}
//                           %
//                         </span>
//                       </div>
//                       <div className="text-900 font-medium text-xl">
//                         Saldo{' '}
//                         <span className="text-pink-400 font-medium">
//                           {formatCurrency(
//                             totalIngresosCuidadCaramelodMes -
//                               totalEgresosCuidadCaramelodMes
//                           )}
//                         </span>{' '}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
