/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'
import { ResponsableService } from '../services/ResponsableService'
import AuthUse from '../../../auth/AuthUse'
// import { CargaBodegaService } from '../services/CargaBodegaService'
export const ResponsableContext = createContext()

const ResponsableContextProvider = (props) => {
  const responsableService = new ResponsableService()
  // const cargaBodegaService = new CargaBodegaService()

  const [responsables, setResponsables] = useState([])
  const [createBodegaResponsable1, setCreateBodegaResponsable1] = useState([])
  const [responsablecargando, setResponsablecargando] = useState(null)
  const [editResponsable, setEditResponsable] = useState(null)
  const [loading, setLoading] = useState(true)
  const [actuallizar, setActuallizar] = useState(null)

  const auth = AuthUse()
  const token = auth.user.token

  useEffect(() => {
    responsableService.readAll(token).then((data) => {
      setResponsables(data)
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      responsableService.readAll(token).then((data) => {
        setResponsables(data)
        setLoading(false)
      })
      setActuallizar(actuallizar + 1)
    }, 600000)
    return () => {
      clearInterval(interval)
    }
  }, [actuallizar])

  useEffect(() => {
    const responsable = responsables.find(
      (p) => p.estatusResponsable === 'OPERATIVO'
    )
    setResponsablecargando(responsable)
  }, [responsables])

  const findResponsable = (id) => {
    const responsable = responsables.find((p) => p.id === id)
    setEditResponsable(responsable)
  }

  const createResponsable = (responsable) => {
    responsableService.create(responsable, token).then((data) => {
      setResponsables([...responsables, data.saveResponsable])
      createBodegaResponsable(data.saveResponsable)
      setCreateBodegaResponsable1(data.saveResponsable)
    })
  }
  const createBodegaResponsable = (saveResponsable) => {
    const cargaBodega = {
      responsableID: saveResponsable.id,
      nombreBodega: '',
      estatusCargaBodega: 'CARGANDO',
      toneladasCargadasBodega: 0,
      toneladasCapacidadBodega: 0
    }

    // let i = 1

    // do {
    //   cargaBodega.nombreBodega = `BODEGA ${i}`
    //   cargaBodegaService.create(cargaBodega, token).then(i++)
    // } while (i <= saveResponsable.cantidadBodegas)
    for (let i = 1; i <= saveResponsable.cantidadBodegas; i++) {
      cargaBodega.nombreBodega = `BODEGA ${i}`
    }
    // responsableService.create(CargaBodega, token).then((data) => {
    //   setResponsables([...responsables, data.saveResponsable])
    //   console.log('responsable creado', data.saveResponsable)
    // })
  }

  const updateResponsable = (responsable) => {
    responsableService
      .update(responsable, token)
      .then((data) =>
        setResponsables(
          responsables.map((p) =>
            p.id === responsable.id ? data.updateResponsable : p
          )
        )
      )
    setEditResponsable(null)
  }
  const deleteResponsable = (id) => {
    responsableService
      .delete(id, token)
      .then(() => setResponsables(responsables.filter((p) => p.id !== id)))
  }

  return (
    <ResponsableContext.Provider
      value={{
        findResponsable,
        createResponsable,
        updateResponsable,
        deleteResponsable,
        editResponsable,
        responsablecargando,
        responsables,
        loading,
        createBodegaResponsable1
      }}
    >
      {props.children}
    </ResponsableContext.Provider>
  )
}

export default ResponsableContextProvider
