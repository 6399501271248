/* eslint-disable react/prop-types */

import moment from 'moment'

const PresupuestoAbonoList = ({ abonoPresupuestosFilter }) => (
  <div>
    {abonoPresupuestosFilter.map((p) => (
      <div key={p.id}>
        <span>
          {p.totalAbonoPresupuesto.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          })}
        </span>
        -<span> {p.descripcionAbonoPresupuesto}</span>-
        <span>
          {moment(p.fechaEfectivaAbonoPresupuesto).format('DD/MM/YYYY')}
        </span>
      </div>
    ))}
  </div>
)

export default PresupuestoAbonoList
