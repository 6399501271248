/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { ProyectoContext } from '../contexts/ProyectoContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'

import { addLocale } from 'primereact/api'

import moment from 'moment'
import { classNames } from 'primereact/utils'
import { InputTextarea } from 'primereact/inputtextarea'
import { ProveedorContext } from '../contexts/ProveedorContext'
import { ResponsableContext } from '../contexts/ResponsableContext'

const ActivoForm = (props) => {
  const initialSubProyectoForm = {
    id: null,
    nombreSubProyecto: '',
    descripcionSubProyecto: '',
    totalSubProyecto: 0,
    avanceSubProyecto: 0,
    fechaInicioSubProyecto: '',
    fechaFinalSubProyecto: '',
    estatusSubProyecto: '',
    proyectoId: null,
    proveedorId: null,
    creadoSubProyecto: moment(),
    modificadoSubProyecto: moment()
  }

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    today: 'Hoy',
    clear: 'Limpiar'
  })
  const toast = useRef(null)

  const { createSubProyecto, editSubProyecto, updateSubProyecto } =
    useContext(SubProyectoContext)
  const { proyectos } = useContext(ProyectoContext)
  const { proveedors } = useContext(ProveedorContext)
  const { responsables } = useContext(ResponsableContext)

  const { isVisible, setIsVisible } = props
  const [selectedSubProyecto, setSelectedSubProyecto] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [selectedProveedor, setSelectedProveedor] = useState(null)
  const [selectedResponsable, setSelectedResponsable] = useState(null)
  const [selectedProyecto, setSelectedProyecto] = useState(null)

  const [SubProyectoData, setSubProyectoData] = useState(initialSubProyectoForm)
  const estadoSubProyecto = [
    { estatusSubProyecto: 'OPERATIVO' },
    { estatusSubProyecto: 'ANULADO' },
    { estatusSubProyecto: 'CULMINADO' }
  ]
  const onEstatusSubProyecto = (e) => {
    setSelectedSubProyecto(e.value)
    updateField(e.value.estatusSubProyecto, 'estatusSubProyecto')
  }
  const onProveedor = (e) => {
    if (e.value) {
      setSelectedProveedor(e.value)
      updateField(e.value.id, 'proveedorId')
    } else {
      setSelectedProveedor(null)
    }
  }
  const onResponsable = (e) => {
    if (e.value) {
      setSelectedResponsable(e.value)
      updateField(e.value.id, 'responsableId')
    } else {
      setSelectedResponsable(null)
    }
  }

  const onProyecto = (e) => {
    e.value
      ? (setSelectedProyecto(e.value), updateField(e.value.id, 'proyectoId'))
      : (setSelectedProyecto(null), updateField(null, 'proyectoId'))
  }
  useEffect(() => {
    if (editSubProyecto) {
      setSubProyectoData({
        ...editSubProyecto,
        proyectoId: editSubProyecto.proyectoId?.id,
        proveedorId: editSubProyecto.proveedorId?.id,
        responsableId: editSubProyecto.responsableId?.id
      })

      setSelectedSubProyecto({
        estatusSubProyecto: editSubProyecto.estatusSubProyecto
      })
      const proveedorSelecEdit =
        editSubProyecto.proveedorId &&
        proveedors.find((p) => p.id === editSubProyecto.proveedorId.id)
      setSelectedProveedor(proveedorSelecEdit)
      const responsableSelecEdit =
        editSubProyecto.responsableId &&
        responsables.find((p) => p.id === editSubProyecto.responsableId.id)
      setSelectedResponsable(responsableSelecEdit)

      const proyectoSelecEdit =
        editSubProyecto.proyectoId &&
        proyectos.find((p) => p.id === editSubProyecto.proyectoId.id)

      setSelectedProyecto(proyectoSelecEdit)
    }
  }, [editSubProyecto])

  const updateField = (data, field) => {
    setSubProyectoData({
      ...SubProyectoData,
      [field]: data
    })
  }

  const saveSubProyecto = () => {
    setSubmitted(true)
    if (
      SubProyectoData.proyectoId.trim() &&
      SubProyectoData.nombreSubProyecto.trim() &&
      SubProyectoData.estatusSubProyecto.trim()
    ) {
      if (!editSubProyecto) {
        createSubProyecto(SubProyectoData)
      } else {
        updateSubProyecto({
          ...SubProyectoData,
          modificadoSubProyecto: moment()
        })
      }
      clearSelected()
    }
  }

  const dialogFooter = (
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => clearSelected()}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveSubProyecto} />
    </div>
  )

  const clearSelected = () => {
    setIsVisible(false)
    setSubProyectoData(initialSubProyectoForm)
    setSelectedSubProyecto(null)
    setSelectedProyecto(null)
    setSubmitted(false)
  }

  // const selectedestatusSubProyectoTemplate = (option, props) => {
  //   if (option) {
  //     return (
  //       <div className="country-item country-item-value">
  //         <div>{option.estatusSubProyecto}</div>
  //       </div>
  //     )
  //   }

  //   return <span>{props.placeholder}</span>
  // }

  // const estatusSubProyectoOptionTemplate = (option) => {
  //   return (
  //     <div className="country-item">
  //       <div>{option.estatusSubProyecto}</div>
  //     </div>
  //   )
  // }

  return (
    <div className="dialog-demo">
      <Toast ref={toast} />
      <Dialog
        visible={isVisible}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '30vw' }}
        header="Detalles de la SubProyecto"
        footer={dialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="p-grid p-fluid">
          <br />
          <div className="field  mb-4">
            <span className="p-float-label">
              <Dropdown
                inputId="dropdown"
                value={selectedProyecto}
                options={proyectos}
                onChange={onProyecto}
                optionLabel="nombreProyecto"
                // placeholder="Seleccione Proyecto*"
                // valueTemplate={selectedconceptoIngresoGastoTemplate}
                // itemTemplate={conceptoIngresoGastoOptionTemplate}
                showClear
                filter
                filterBy="nombrePresupuesto"
                className={classNames({
                  'p-invalid': submitted && !SubProyectoData.proyectoId
                })}
              />
              {submitted && !SubProyectoData.proyectoId && (
                <small className="p-invalid">Proyecto es requerido.</small>
              )}
              <label htmlFor="dropdown">Seleccione Proyecto*</label>
            </span>
          </div>
          <div className="p-float-label">
            <InputText
              value={SubProyectoData.nombreSubProyecto}
              onChange={(e) => updateField(e.target.value, 'nombreSubProyecto')}
              className={classNames({
                'p-invalid': submitted && !SubProyectoData.nombreSubProyecto
              })}
            />
            {submitted && !SubProyectoData.nombreSubProyecto && (
              <small className="p-invalid">Nombre es requerido.</small>
            )}
            <label>Nombre del SubProyecto:*</label>
          </div>
          <br />
          <div className="p-float-label">
            <InputTextarea
              id="description"
              value={SubProyectoData.descripcionSubProyecto}
              onChange={(e) =>
                updateField(e.target.value, 'descripcionSubProyecto')
              }
              rows={3}
              cols={20}
            />

            <label>Descripcion:</label>
          </div>
          <br />
          <div className="field col-12 md:col-6  ">
            <span className="p-float-label">
              <Dropdown
                inputId="dropdown"
                value={selectedProveedor}
                options={proveedors}
                onChange={onProveedor}
                optionLabel="nombreProveedor"
                showClear
                filter
                filterBy="nombreProveedor"
                className={classNames({
                  'p-invalid': submitted && !selectedProveedor
                })}
              />
              {submitted && !selectedProveedor && (
                <small className="p-invalid">Proveedor es requerido.</small>
              )}
              <label htmlFor="dropdown">Seleccione Proveedor*</label>
            </span>
          </div>
          <div className="field col-12 md:col-6  ">
            <span className="p-float-label">
              <Dropdown
                inputId="dropdown"
                value={selectedResponsable}
                options={responsables}
                onChange={onResponsable}
                optionLabel="nombreResponsable"
                showClear
                filter
                filterBy="nombreResponsable"
                className={classNames({
                  'p-invalid': submitted && !selectedResponsable
                })}
              />
              {submitted && !selectedProveedor && (
                <small className="p-invalid">Proveedor es requerido.</small>
              )}
              <label htmlFor="dropdown">Seleccione Proveedor*</label>
            </span>
          </div>
          <div className="field col-12 md:col-6 p-0">
            <span className="p-float-label">
              <Dropdown
                inputId="dropdown"
                value={selectedSubProyecto}
                options={estadoSubProyecto}
                onChange={onEstatusSubProyecto}
                optionLabel="estatusSubProyecto"
                // placeholder="Seleccione Estado*"
                // valueTemplate={selectedestatusSubProyectoTemplate}
                // itemTemplate={estatusSubProyectoOptionTemplate}
                className={classNames({
                  'p-invalid': submitted && !SubProyectoData.estatusSubProyecto
                })}
              />
              {submitted && !SubProyectoData.estatusSubProyecto && (
                <small className="p-invalid">Estatus es requerido.</small>
              )}
              <label htmlFor="dropdown">Seleccione Estado*</label>
            </span>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ActivoForm
