/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { ComentarioSubProyectoContext } from '../contexts/ComentarioSubProyectoContext'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import { addLocale } from 'primereact/api'

import moment from 'moment'
import { ProyectoContext } from '../contexts/ProyectoContext'
import { InputTextarea } from 'primereact/inputtextarea'
import { classNames } from 'primereact/utils'

const ActivoForm = (props) => {
  const initialComentarioSubProyectoForm = {
    id: null,
    nombreComentarioSubProyecto: '',
    descripcionComentarioSubProyecto: '',
    estatusComentarioSubProyecto: '',
    subProyectoId: null,
    // imagenUrlComentario: null,
    creadoComentarioSubProyecto: moment(),
    modificadoComentarioSubProyecto: moment()
  }

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  const {
    createComentarioSubProyecto,
    editComentarioSubProyecto,
    updateComentarioSubProyecto
  } = useContext(ComentarioSubProyectoContext)
  const { subProyectos } = useContext(SubProyectoContext)
  const { proyectos } = useContext(ProyectoContext)

  const { isVisible, setIsVisible } = props
  const [selectedComentarioSubProyecto, setSelectedComentarioSubProyecto] =
    useState(null)
  const [selectedProyecto, setSelectedProyecto] = useState(null)
  const [subProyecto, setSubProyecto] = useState(subProyectos)
  const [ComentarioSubProyectoData, setComentarioSubProyectoData] = useState(
    initialComentarioSubProyectoForm
  )
  const [selectedImages, setSelectedImages] = useState([])
  const [previewImages, setPreviewImages] = useState([])
  const [selectedSubProyecto, setSelectedSubProyecto] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const estadoComentarioSubProyecto = [
    { estatusComentarioSubProyecto: 'OPERATIVO' },
    { estatusComentarioSubProyecto: 'ANULADO' },
    { estatusComentarioSubProyecto: 'CULMINADO' }
  ]
  const onProyecto = (e) => {
    if (e.value) {
      const subProyectoFilter = subProyectos.filter(
        (p) => p.proyectoId?.id === e.value.id
      )
      setSelectedProyecto(e.value)
      setSubProyecto(subProyectoFilter)
    } else {
      setSelectedProyecto(null)
      setSubProyecto(null)
      setSelectedSubProyecto(null)
    }
  }
  const onEstatusComentarioSubProyecto = (e) => {
    setSelectedComentarioSubProyecto(e.value)
    updateField(
      e.value.estatusComentarioSubProyecto,
      'estatusComentarioSubProyecto'
    )
  }

  const toast = useRef(null)
  const onSubProyecto = (e) => {
    e.value
      ? (setSelectedSubProyecto(e.value),
        updateField(e.value.id, 'subProyectoId'))
      : (setSelectedSubProyecto(null), updateField(null, 'subProyectoId'))
  }
  useEffect(() => {
    if (editComentarioSubProyecto) {
      setComentarioSubProyectoData(editComentarioSubProyecto)
      setComentarioSubProyectoData({
        ...editComentarioSubProyecto,
        subProyectoId: editComentarioSubProyecto.subProyectoId?.id
      })
      setSelectedComentarioSubProyecto({
        estatusComentarioSubProyecto:
          editComentarioSubProyecto.estatusComentarioSubProyecto
      })
      const subProyectoSelecEdit =
        editComentarioSubProyecto.subProyectoId &&
        subProyectos.find(
          (p) => p.id === editComentarioSubProyecto.subProyectoId.id
        )

      setSelectedSubProyecto(subProyectoSelecEdit)
      const proyectoSelecEdit =
        subProyectoSelecEdit.proyectoId &&
        proyectos.find((p) => p.id === subProyectoSelecEdit.proyectoId.id)

      setSelectedProyecto(proyectoSelecEdit)
    }
  }, [editComentarioSubProyecto])

  const updateField = (data, field) => {
    setComentarioSubProyectoData({
      ...ComentarioSubProyectoData,
      [field]: data
    })
  }

  const saveComentarioSubProyecto = () => {
    setSubmitted(true)
    if (
      ComentarioSubProyectoData.estatusComentarioSubProyecto.trim() &&
      ComentarioSubProyectoData.nombreComentarioSubProyecto.trim() &&
      ComentarioSubProyectoData.subProyectoId.trim()
    ) {
      if (!editComentarioSubProyecto) {
        createComentarioSubProyecto({
          ...ComentarioSubProyectoData,
          selectedImages
        })
      } else {
        updateComentarioSubProyecto({
          ...ComentarioSubProyectoData,
          imagenUrlComentario: selectedImages,
          modificadoComentarioSubProyecto: moment(),
          selectedImages
        })
      }
      clearSelected()
    }
  }

  const dialogFooter = (
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => clearSelected()}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        onClick={saveComentarioSubProyecto}
      />
    </div>
  )

  const clearSelected = () => {
    setIsVisible(false)
    setComentarioSubProyectoData(initialComentarioSubProyectoForm)
    setSelectedComentarioSubProyecto('')
    setSelectedProyecto(null)
    setSubmitted(false)
    setSelectedSubProyecto(null)
  }
  const handleImageChange = (event) => {
    const files = event.target.files
    const previewImages = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    )
    setSelectedImages([...files])
    setPreviewImages([...previewImages])
  }
  return (
    <div className="dialog-demo">
      <Toast ref={toast} />
      <Dialog
        visible={isVisible}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '70vw' }}
        header="Detalles del ComentarioSubProyecto"
        footer={dialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="p-grid p-fluid">
          <div className="formgrid grid ">
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label">
                <Dropdown
                  inputId="dropdown"
                  value={selectedProyecto}
                  options={proyectos}
                  onChange={onProyecto}
                  optionLabel="nombreProyecto"
                  showClear
                  filter
                  filterBy="nombreComentarioSubProyecto"
                  className={classNames({
                    'p-invalid': submitted && !selectedProyecto
                  })}
                />
                {submitted && !selectedProyecto && (
                  <small className="p-invalid">Proyecto es requerido.</small>
                )}
                <label htmlFor="dropdown">Seleccione Proyecto*</label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3 ">
              <span className="p-float-label ">
                <Dropdown
                  value={selectedSubProyecto}
                  options={subProyecto}
                  onChange={onSubProyecto}
                  optionLabel="nombreSubProyecto"
                  showClear
                  filter
                  filterBy="nombreComentarioSubProyecto"
                  disabled={!selectedProyecto}
                  className={classNames({
                    'p-invalid':
                      submitted && !ComentarioSubProyectoData.subProyectoId
                  })}
                />
                {submitted && !ComentarioSubProyectoData.subProyectoId && (
                  <small className="p-invalid">
                    Sub Proyecto es requerido.
                  </small>
                )}
                <label htmlFor="dropdown">Seleccione Sub Proyecto*</label>
              </span>
            </div>
            <div className="p-float-label col-12  mt-2">
              <InputText
                value={ComentarioSubProyectoData.nombreComentarioSubProyecto}
                onChange={(e) =>
                  updateField(e.target.value, 'nombreComentarioSubProyecto')
                }
                className={classNames({
                  'p-invalid':
                    submitted &&
                    !ComentarioSubProyectoData.nombreComentarioSubProyecto
                })}
              />
              {submitted &&
                !ComentarioSubProyectoData.nombreComentarioSubProyecto && (
                  <small className="p-invalid">Nombre es requerido.</small>
                )}
              <label>Nombre del ComentarioSubProyecto:</label>
            </div>
            <div className="p-float-label col-12  mt-4">
              <InputTextarea
                id="description"
                value={
                  ComentarioSubProyectoData.descripcionComentarioSubProyecto
                }
                onChange={(e) =>
                  updateField(
                    e.target.value,
                    'descripcionComentarioSubProyecto'
                  )
                }
                rows={3}
                cols={20}
              />

              <label>Descripcion:</label>
            </div>
            <div className="field col-12  mt-4 mb-4">
              <label>Subir imágenes:</label>
              <input type="file" multiple onChange={handleImageChange} />
              <h2>Previsualización de imágenes:</h2>
              <div className="image-preview">
                {previewImages.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Previsualización ${index}`}
                    style={{ width: '200px', height: 'auto' }}
                  />
                ))}
              </div>
            </div>

            <div className="field col-12 md:col-6 mt-3">
              <span className="p-float-label">
                <Dropdown
                  value={selectedComentarioSubProyecto}
                  options={estadoComentarioSubProyecto}
                  onChange={onEstatusComentarioSubProyecto}
                  optionLabel="estatusComentarioSubProyecto"
                  className={classNames({
                    'p-invalid':
                      submitted &&
                      !ComentarioSubProyectoData.estatusComentarioSubProyecto
                  })}
                />
                {submitted &&
                  !ComentarioSubProyectoData.estatusComentarioSubProyecto && (
                    <small className="p-invalid">Estatus es requerido.</small>
                  )}
                <label htmlFor="dropdown">Seleccione Estado*</label>
              </span>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ActivoForm
