/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'
import { SolicitudFondoService } from '../services/SolicitudFondoService'
import AuthUse from '../../../auth/AuthUse'
// import { CargaBodegaService } from '../services/CargaBodegaService'
export const SolicitudFondoContext = createContext()

const SolicitudFondoContextProvider = (props) => {
  const solicitudFondoService = new SolicitudFondoService()
  // const cargaBodegaService = new CargaBodegaService()

  const [solicitudFondos, setSolicitudFondos] = useState([])
  const [createBodegaSolicitudFondo1, setCreateBodegaSolicitudFondo1] =
    useState([])
  const [solicitudFondocargando, setSolicitudFondocargando] = useState(null)
  const [editSolicitudFondo, setEditSolicitudFondo] = useState(null)
  const [loading, setLoading] = useState(true)
  const [actuallizar, setActuallizar] = useState(null)

  const auth = AuthUse()
  const token = auth.user.token

  useEffect(() => {
    solicitudFondoService.readAll(token).then((data) => {
      setSolicitudFondos(data)
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      solicitudFondoService.readAll(token).then((data) => {
        setSolicitudFondos(data)
        setLoading(false)
      })
      setActuallizar(actuallizar + 1)
    }, 600000)
    return () => {
      clearInterval(interval)
    }
  }, [actuallizar])

  useEffect(() => {
    const solicitudFondo = solicitudFondos.find(
      (p) => p.estatusSolicitudFondo === 'OPERATIVO'
    )
    setSolicitudFondocargando(solicitudFondo)
  }, [solicitudFondos])

  const findSolicitudFondo = (id) => {
    const solicitudFondo = solicitudFondos.find((p) => p.id === id)
    setEditSolicitudFondo(solicitudFondo)
  }

  const createSolicitudFondo = (solicitudFondo) => {
    solicitudFondoService.create(solicitudFondo, token).then((data) => {
      setSolicitudFondos([...solicitudFondos, data.saveSolicitudFondo])
      createBodegaSolicitudFondo(data.saveSolicitudFondo)
      setCreateBodegaSolicitudFondo1(data.saveSolicitudFondo)
    })
  }
  const createBodegaSolicitudFondo = (saveSolicitudFondo) => {
    const cargaBodega = {
      solicitudFondoID: saveSolicitudFondo.id,
      nombreBodega: '',
      estatusCargaBodega: 'CARGANDO',
      toneladasCargadasBodega: 0,
      toneladasCapacidadBodega: 0
    }

    // let i = 1

    // do {
    //   cargaBodega.nombreBodega = `BODEGA ${i}`
    //   cargaBodegaService.create(cargaBodega, token).then(i++)
    // } while (i <= saveSolicitudFondo.cantidadBodegas)
    for (let i = 1; i <= saveSolicitudFondo.cantidadBodegas; i++) {
      cargaBodega.nombreBodega = `BODEGA ${i}`
    }
    // solicitudFondoService.create(CargaBodega, token).then((data) => {
    //   setSolicitudFondos([...solicitudFondos, data.saveSolicitudFondo])
    //   console.log('solicitudFondo creado', data.saveSolicitudFondo)
    // })
  }

  const updateSolicitudFondo = (solicitudFondo) => {
    solicitudFondoService
      .update(solicitudFondo, token)
      .then((data) =>
        setSolicitudFondos(
          solicitudFondos.map((p) =>
            p.id === solicitudFondo.id ? data.updateSolicitudFondo : p
          )
        )
      )
    setEditSolicitudFondo(null)
  }
  const deleteSolicitudFondo = (id) => {
    solicitudFondoService
      .delete(id, token)
      .then(() =>
        setSolicitudFondos(solicitudFondos.filter((p) => p.id !== id))
      )
  }

  return (
    <SolicitudFondoContext.Provider
      value={{
        findSolicitudFondo,
        createSolicitudFondo,
        updateSolicitudFondo,
        deleteSolicitudFondo,
        editSolicitudFondo,
        solicitudFondocargando,
        solicitudFondos,
        loading,
        createBodegaSolicitudFondo1
      }}
    >
      {props.children}
    </SolicitudFondoContext.Provider>
  )
}

export default SolicitudFondoContextProvider
