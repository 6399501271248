import SolicitudFondoList from '../components/SolicitudFondoList'
import SolicitudFondoContextProvider from '../contexts/SolicitudFondoContext'

export const SolicitudFondoPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <h5>SolicitudFondo CRUD</h5>
          <SolicitudFondoContextProvider>
            <SolicitudFondoList />
          </SolicitudFondoContextProvider>
        </div>
      </div>
    </div>
  )
}
