/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
import { useContext, useEffect, useState } from 'react'
import { IngresoGastoContext } from '../contexts/IngresoGastoContext'
import moment from 'moment'

import IngresoIngresoEstadisticaCardRender from './IngresoIngresoEstadisticaCardRender'

const IngresoIngresoEstadisticaCard = ({ anoVisual }) => {
  const { ingresoGastos } = useContext(IngresoGastoContext)

  const [data, setdata] = useState()

  useEffect(() => {
    buquesToneladasDias()
    setdata(auxOtro2)
  }, [ingresoGastos, anoVisual])

  // cabecera de la tabla
  const mesesDelAno = [
    `${anoVisual}-01-20`,
    `${anoVisual}-02-20`,
    `${anoVisual}-03-20`,
    `${anoVisual}-04-20`,
    `${anoVisual}-05-20`,
    `${anoVisual}-06-20`,
    `${anoVisual}-07-20`,
    `${anoVisual}-08-20`,
    `${anoVisual}-09-20`,
    `${anoVisual}-10-20`,
    `${anoVisual}-11-20`,
    `${anoVisual}-12-20`
  ]
  const mesesDelAnoNombre = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
  ]
  let auxOtro2 = []
  let diasTotales = []

  const buquesToneladasDias = () => {
    mesesDelAno.forEach((dataset, i) => {
      let ingresoGastoMes = ingresoGastos.filter(
        (p) =>
          p.estatusIngresoGasto === 'PROCESADO' &&
          moment(dataset).isSame(p.fechaIngresoGasto, 'month')
      )
      let ingresoCarameloMes = ingresoGastoMes.filter(
        (p) =>
          p.centroDeCostoAuxId !== null &&
          p.centroDeCostoAuxId.id === '63cdca2c1a5853c01ad9d0d8'
      )
      let ingresoCiudadCarameloMes = ingresoGastoMes.filter(
        (p) =>
          p.centroDeCostoAuxId !== null &&
          p.centroDeCostoAuxId.id === '63cdca4f1a5853c01ad9d0de'
      )
      let ingresoTrinidadMes = ingresoGastoMes.filter(
        (p) =>
          p.centroDeCostoAuxId !== null &&
          p.centroDeCostoAuxId.id === '63cdca611a5853c01ad9d0e1'
      )

      const totalEgreso = ingresoGastoMes
        .map((ingreso) => ingreso.ingresoIngresoGasto)
        .reduce((a, b) => a + b, 0)

      const totalingresoGastoMaroil = ingresoCarameloMes
        .map((ingreso) => ingreso.ingresoIngresoGasto)
        .reduce((a, b) => a + b, 0)

      const totalingresoGastoSanFelix = ingresoCiudadCarameloMes
        .map((ingreso) => ingreso.ingresoIngresoGasto)
        .reduce((a, b) => a + b, 0)

      const totalingresoGastoCedeno = ingresoTrinidadMes
        .map((ingreso) => ingreso.ingresoIngresoGasto)
        .reduce((a, b) => a + b, 0)
      let mes = mesesDelAnoNombre[i]

      // auxOtro2.push({ [mes]: totalEgreso })
      auxOtro2.push({
        mesNombre: mes,
        totalIngresoMes: totalEgreso,
        totalIngresoMaroil: totalingresoGastoMaroil,
        totalIngresoSanFelix: totalingresoGastoSanFelix,
        totalIngresoCedeno: totalingresoGastoCedeno
      })
      diasTotales = diasTotales.concat(totalEgreso)
    })
  }
  return (
    <div className="grid  animate__animated animate__backInDown animate__slower">
      {ingresoGastos.length !== 0 &&
        data &&
        data.map((data) => (
          <IngresoIngresoEstadisticaCardRender data={data} key={data.id} />
        ))}
    </div>
  )
}

export default IngresoIngresoEstadisticaCard
