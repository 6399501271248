/* eslint-disable no-unused-vars */
import React, { useContext, useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { AvancePresupuestoContext } from '../contexts/AvancePresupuestoContext'
import moment from 'moment'

import AvancePresupuestoForm from './AvancePresupuestoForm'
import { PresupuestoContext } from '../contexts/PresupuestoContext'
import { Dropdown } from 'primereact/dropdown'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { ProyectoContext } from '../contexts/ProyectoContext'
import { log } from 'three/examples/jsm/nodes/Nodes'

const AvancePresupuestoList = () => {
  const {
    avancePresupuestos,
    findAvancePresupuesto,
    deleteAvancePresupuesto,
    loading
  } = useContext(AvancePresupuestoContext)
  const { proyectos } = useContext(ProyectoContext)
  const { subProyectos } = useContext(SubProyectoContext)
  const { presupuestos } = useContext(PresupuestoContext)
  const [presupuesto, setPresupuesto] = useState(presupuestos)
  const [subProyecto, setSubProyecto] = useState(subProyectos)
  const [avancePresupuesto, setAvancePresupuesto] = useState(avancePresupuestos)
  const [avancePresupuestoDelete, setAvancePresupuestoDelete] = useState(null)

  const [deleteBarcoDialog, setDeleteBarcoDialog] = useState(false)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [selectedPresupuesto, setSelectedPresupuesto] = useState(null)
  const [loadingProyecto, setoLadingProyecto] = useState(true)
  const [selectedSubProyecto, setSelectedSubProyecto] = useState(null)
  const [selectedProyecto, setSelectedProyecto] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState(null)
  const dt = useRef(null)
  const toast = useRef(null)
  const saveBarco = (id) => {
    findAvancePresupuesto(id)
    setIsVisible(true)
  }
  useEffect(() => {
    if (selectedPresupuesto) {
      const avancePresupuestosFilter = avancePresupuestos.filter(
        (p) => p.presupuestoId?.id === selectedPresupuesto.id
      )
      setAvancePresupuesto(avancePresupuestosFilter)
      setoLadingProyecto(false)
    }
    // setAvancePresupuesto(avancePresupuestos)
  }, [avancePresupuestos])
  const onProyecto = (e) => {
    if (e.value) {
      const subProyectoFilter = subProyectos.filter(
        (p) => p.proyectoId?.id === e.value.id
      )
      setSelectedProyecto(e.value)
      setSubProyecto(subProyectoFilter)
    } else {
      setSelectedProyecto(null)
    }
  }
  const onSubProyecto = (e) => {
    if (e.value) {
      const presupuestosFilter = presupuestos.filter(
        (p) => p.subProyectoId?.id === e.value.id
      )
      setSelectedSubProyecto(e.value)
      setPresupuesto(presupuestosFilter)
    } else {
      setSelectedSubProyecto(null)
      setoLadingProyecto(true)
      setPresupuesto(subProyectos)
    }
  }

  const onPresupuesto = (e) => {
    if (e.value) {
      const avancePresupuestosFilter = avancePresupuestos.filter(
        (p) => p.presupuestoId?.id === e.value.id
      )
      setSelectedPresupuesto(e.value)
      setAvancePresupuesto(avancePresupuestosFilter)
      setoLadingProyecto(false)
    } else {
      setSelectedPresupuesto(null)
      setoLadingProyecto(true)
      setAvancePresupuesto(avancePresupuestos)
    }
  }
  // cabecera de la tabla
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Dropdown
          value={selectedProyecto}
          options={proyectos}
          onChange={onProyecto}
          optionLabel="nombreProyecto"
          placeholder="Seleccione Proyecto"
          // valueTemplate={selectedconceptoIngresoGastoTemplate}
          // itemTemplate={conceptoIngresoGastoOptionTemplate}
          showClear
          filter
          filterBy="nombrePresupuesto"
        />
        <Dropdown
          value={selectedSubProyecto}
          options={subProyecto}
          onChange={onSubProyecto}
          optionLabel="nombreSubProyecto"
          placeholder="Seleccione Concepto"
          // valueTemplate={selectedconceptoIngresoGastoTemplate}
          // itemTemplate={conceptoIngresoGastoOptionTemplate}
          showClear
          filter
          filterBy="nombrePresupuesto"
          disabled={!selectedProyecto}
        />
        <Dropdown
          value={selectedPresupuesto}
          options={presupuesto}
          onChange={onPresupuesto}
          optionLabel="nombrePresupuesto"
          placeholder="Seleccione Concepto"
          // valueTemplate={selectedconceptoIngresoGastoTemplate}
          // itemTemplate={conceptoIngresoGastoOptionTemplate}
          showClear
          filter
          filterBy="nombrePresupuesto"
          disabled={!selectedSubProyecto}
        />
        <div className="my-2 ">
          <Button
            label="Nuevo"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={() => setIsVisible(true)}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
          />
        </div>
      </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    )
  }
  const exportCSV = () => {
    dt.current.exportCSV()
  }
  const fechaAtracoTemplate = (rowData) => {
    const validarFecha = moment(rowData.fechaAtraco).isValid()
    if (!validarFecha) return
    const fecha = moment(rowData.fechaAtraco)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechaInicioCargaTemplate = (rowData) => {
    const validarFecha = moment(rowData.fechaInicioCarga).isValid()
    if (!validarFecha) return
    const fecha = moment(rowData.fechaInicioCarga)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechaFinalCargaTemplate = (rowData) => {
    const validarFecha = moment(rowData.fechaFinalCarga).isValid()
    if (!validarFecha) return
    const fecha = moment(rowData.fechaFinalCarga)
    return fecha.format('dddDD/MM/YY HH:mm')
  }

  const fechacreadoAvancePresupuesto = (rowData) => {
    const fecha = moment(rowData.creadoAvancePresupuesto)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechamodificadoAvancePresupuesto = (rowData) => {
    const fecha = moment(rowData.modificadoAvancePresupuesto)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechaEfectivaAvancePresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaEfectivaAvancePresupuesto)
    return fecha.format('dddDD/MM/YY')
  }
  const fechaFinalAvancePresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaFinalAvancePresupuesto)
    return fecha.format('dddDD/MM/YY')
  }
  const eliminarBarco = () => {
    deleteAvancePresupuesto(avancePresupuestoDelete.id)
    setDeleteBarcoDialog(false)
    toast.current.show({
      severity: 'error',
      summary: 'Eliminar',
      detail: 'Data Presupuesto Eliminado',
      life: 3000
    })
  }

  const deleteBarcoDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteBarcoDialog(false)}
      />
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => eliminarBarco()}
      />
    </>
  )

  const confirmDeleteBarco = (barcos) => {
    setAvancePresupuestoDelete(barcos)
    setDeleteBarcoDialog(true)
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2 mb-2"
          onClick={() => saveBarco(rowData.id)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded  p-button-danger"
          onClick={() => confirmDeleteBarco(rowData)}
        />
      </div>
    )
  }
  const diasTotalesCarga = (rowData) => {
    function secondsToString(diff) {
      const numdays = Math.floor(diff / 86400)
      const numhours = Math.floor((diff % 86400) / 3600)
      const numminutes = Math.floor(((diff % 86400) % 3600) / 60)
      // const numseconds = ((diff % 86400) % 3600) % 60

      return (
        numdays + ' dias ' + numhours + ' horas ' + numminutes + ' minutos '
      )
    }
    const fecha1 = moment(rowData.fechaInicioCarga)
    const fecha2 = moment(
      rowData.fechaFinalCarga ? rowData.fechaFinalCarga : moment()
    ) //
    const diff = fecha2.diff(fecha1, 'seconds') // Diff in days

    return <div className="actions">{secondsToString(diff)}</div>
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">AvancePresupuesto</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  )
  const clearSelected = () => {
    setDeleteBarcoDialog(false)
  }
  return (
    <>
      <Toast ref={toast} />
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <DataTable
        ref={dt}
        value={avancePresupuesto}
        onSelectionChange={(e) => setSelectedProducts(e.value_id)}
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Muestra {first} a {last} de {totalRecords} Barcos"
        globalFilter={globalFilter}
        emptyMessage="No hay datos."
        header={header}
        sortField="barcoCreado"
        sortOrder={-1}
        loading={loadingProyecto}
        responsiveLayout="scroll"
        breakpoint="960px"
      >
        <Column body={actionBodyTemplate}></Column>
        {/* <Column
          field="presupuestoId.nombrePresupuesto"
          header="presupuestoId.nombrePresupuesto"
        /> */}

        <Column field="totalAvancePresupuesto" header="Total Avance" />
        <Column field="totalAvanceRealPresupuesto" header="Total Avance Real" />
        <Column field="descripcionAvancePresupuesto" header="Descripción" />
        <Column
          field="fechaEfectivaAvancePresupuesto"
          header="Fecha Efectiva"
          body={fechaEfectivaAvancePresupuesto}
          dataType="date"
        />
        <Column field="estatusAvancePresupuesto" header="Estatus" />
        <Column
          field="creadoAvancePresupuesto"
          body={fechacreadoAvancePresupuesto}
          header="Creado"
          dataType="date"
        />
        <Column
          field="modificadoAvancePresupuesto"
          body={fechamodificadoAvancePresupuesto}
          header="Modificado"
          dataType="date"
        />
      </DataTable>

      <AvancePresupuestoForm
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      <Dialog
        visible={deleteBarcoDialog}
        style={{ width: '450px' }}
        header="Confirm"
        modal
        footer={deleteBarcoDialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {avancePresupuestoDelete && (
            <span>
              Esta seguro que quiere eliminar la Data Presupuesto{' '}
              <b>{avancePresupuestoDelete.nombreAvancePresupuesto}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default AvancePresupuestoList
