export const HomeAIT = () => {
  return (
    <div className="layout-main-container">
      <div className="layout-main" style={{ height: '50vh' }}>
        <div className="container d-flex justify-content-center align-items-center h-100">
          <div className="grid col-8 "></div>
          <h1>HomeAIT</h1>
        </div>
      </div>
    </div>
  )
}
