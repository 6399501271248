import React, { useContext, useState, useRef, useEffect } from 'react'

import { DataView } from 'primereact/dataview'
import { Toast } from 'primereact/toast'
import { ComentarioSubProyectoContext } from '../contexts/ComentarioSubProyectoContext'
import { Rating } from 'primereact/rating'
import { Tag } from 'primereact/tag'
import moment from 'moment'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Galleria } from 'primereact/galleria'

const HomeComentarioSubProyectoList = () => {
  const { comentarioSubProyectos } = useContext(ComentarioSubProyectoContext)
  const { subProyectos } = useContext(SubProyectoContext)

  const commonIds = subProyectos.filter((obj1) =>
    comentarioSubProyectos.some((obj2) => obj2.subProyectoId.id === obj1.id)
  )
  const [comentarioSubProyecto, setComentarioSubProyecto] = useState(
    comentarioSubProyectos
  )
  const [cometarioSearchDialog, setComentarioSearchDialog] = useState(false)
  const [searchComentario, setSearchComentario] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)
  useState(null)

  const toast = useRef(null)
  const galleria = useRef(null)
  useEffect(() => {
    // const comentarioSubProyectosFilter = comentarioSubProyectos.filter(
    //   (p) => p.subProyectoId?.id ===
    // )
    const comentarioSubProyectosFilter = comentarioSubProyectos.sort(
      (o1, o2) => {
        if (o1.updatedAt > o2.updatedAt) {
          return -1
        } else if (o1.updatedAt < o2.updatedAt) {
          return 1
        } else {
          return 0
        }
      }
    )

    setComentarioSubProyecto(comentarioSubProyectosFilter)
  }, [comentarioSubProyectos])

  // cabecera de la tabla
  const clearSelected = () => {
    setComentarioSearchDialog(false)
  }
  const handleSearchComentario = (comentario) => {
    setSearchComentario(comentario)

    setComentarioSearchDialog(true)
  }
  const itemTemplate = (commentario) => {
    const numAleatorio = Math.floor(Math.random() * 5)
    return (
      <div className="col-12">
        <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
          <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <div className="flex flex-column align-items-center sm:align-items-start gap-3">
              <div className="text-2xl font-bold text-900">
                {commentario.nombreComentarioSubProyecto}
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-raised p-button-text p-button-success ms-3 mb-1"
                  onClick={() => handleSearchComentario(commentario)}
                  tooltip="Mostrar Detalle"
                  tooltipOptions={{ position: 'top' }}
                />
              </div>
              <Rating value={numAleatorio} readOnly cancel={false}></Rating>
              <div className="flex align-items-center gap-3">
                <span className="flex align-items-center gap-2">
                  <i className="pi pi-comments"></i>
                  <span className="font-semibold">
                    {commentario.descripcionComentarioSubProyecto}
                  </span>
                </span>
              </div>
            </div>
            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
              <span className="">
                {commonIds && commonIds.map((p) => p.proyectoId.nombreProyecto)}
              </span>
              <span className=" font-semibold">
                <Tag
                  value={commentario.subProyectoId.nombreSubProyecto}
                  severity={getSeverity(commentario)}
                  style={{ fontSize: '12px' }}
                ></Tag>
              </span>
              <span className="flex align-items-center gap-2">
                <i className="pi pi-calendar"></i>
                <span className="font-semibold">
                  {moment(commentario.updatedAt).format('dddDD/MM/YY ')}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const getSeverity = () => {
    const numAleatorio = Math.floor(Math.random() * 4)

    switch (numAleatorio) {
      case 1:
        return 'success'

      case 2:
        return 'info'

      case 3:
        return 'warning'
      case 4:
        return 'danger'

      default:
        return null
    }
  }
  // const responsiveOptions = [
  //   {
  //     breakpoint: '991px',
  //     numVisible: 4
  //   },
  //   {
  //     breakpoint: '767px',
  //     numVisible: 3
  //   },
  //   {
  //     breakpoint: '575px',
  //     numVisible: 1
  //   }
  // ]
  // const itemTemplate2 = (item) => {
  //   return <img src={item.url} alt={item.alt} style={{ width: '100%' }} />
  // }

  const itemTemplate2 = (item) => {
    return (
      <img
        src={item.url}
        alt={item.alt}
        style={{ width: '100%', display: 'block' }}
      />
    )
  }
  const setSearchDialogFooter = (
    <>
      <Button
        label="Salir"
        icon="pi pi-times"
        className="p-button-danger p-button-raised"
        onClick={() => setComentarioSearchDialog(false)}
      />
    </>
  )
  return (
    <>
      <Toast ref={toast} />
      <DataView
        value={comentarioSubProyecto}
        itemTemplate={itemTemplate}
        rows={4}
        paginator
      />
      <Dialog
        visible={cometarioSearchDialog}
        style={{ width: '75vw' }}
        header="Confirm"
        modal
        footer={setSearchDialogFooter}
        onHide={() => clearSelected()}
      >
        <></>
        {searchComentario && (
          <>
            <div className="flex align-items-center justify-content-center">
              <span className="block text-600 font-medium mb-1">
                <span className="block text-900 font-medium mb-1 text-center">
                  <span className=" font-semibold">
                    <Tag
                      value={searchComentario.subProyectoId.nombreSubProyecto}
                      severity={getSeverity(searchComentario)}
                      style={{ fontSize: '12px' }}
                    ></Tag>
                  </span>
                  <br />
                  <strong>
                    {searchComentario.nombreComentarioSubProyecto}
                  </strong>{' '}
                </span>
              </span>
            </div>
            <div className="flex align-items-center gap-3">
              <span className="flex align-items-center gap-2">
                <i className="pi pi-comments"></i>
                <span className="font-semibold">
                  {searchComentario.descripcionComentarioSubProyecto}
                </span>
              </span>
            </div>
            <div className="my-2">
              <span className="flex align-items-center gap-2">
                <i className="pi pi-calendar"></i>
                <span className="font-semibold">
                  {moment(searchComentario.updatedAt).format('dddDD/MM/YY ')}
                </span>
              </span>
            </div>
            <div className="flex align-items-center justify-content-center">
              <div className="card">
                <h4>Galería de imágenes:</h4>

                <div className="card flex align-items-center">
                  <Galleria
                    ref={galleria}
                    value={searchComentario.imagenUrlComentario}
                    numVisible={7}
                    style={{ maxWidth: '850px' }}
                    activeIndex={activeIndex}
                    onItemChange={(e) => setActiveIndex(e.index)}
                    circular
                    fullScreen
                    showItemNavigators
                    showThumbnails={false}
                    item={itemTemplate2}
                    // thumbnail={thumbnailTemplate}
                  />
                  <div className="grid" style={{ maxWidth: '1000px' }}>
                    {searchComentario.imagenUrlComentario &&
                      searchComentario.imagenUrlComentario.map(
                        (image, index) => {
                          const imgEl = (
                            <img
                              src={image.url}
                              alt={image.alt}
                              style={{
                                cursor: 'pointer',
                                width: '100px',
                                height: '100px'
                              }}
                              onClick={() => {
                                setActiveIndex(index)
                                galleria.current.show()
                              }}
                            />
                          )
                          return (
                            <div className="col-3" key={index}>
                              {imgEl}
                            </div>
                          )
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Dialog>
    </>
  )
}

export default HomeComentarioSubProyectoList
