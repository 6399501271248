import IngresoGastoEstadistica from './IngresoGastoEstadistica'
import IngresoIngresoEstadistica from './IngresoIngresoEstadistica'
import IngresoVsGastoEstadisticaGrafica from './IngresoVsGastoEstadisticaGrafica'
import { Accordion, AccordionTab } from 'primereact/accordion'
import IngresoVsGastoDetalleWR from './IngresoVsGastoDetalleWR'
// const HomeAdministracion = () => {
//   return <IngresoGastoEstadistica />
// }

// export default HomeAdministracion

const HomeAdministracion = () => {
  return (
    <div className=" ">
      <IngresoVsGastoDetalleWR />

      <Accordion activeIndex={0}>
        <AccordionTab header="Inversión">
          <IngresoIngresoEstadistica />
        </AccordionTab>
        <AccordionTab header="Gasto">
          <IngresoGastoEstadistica />
        </AccordionTab>
        <AccordionTab header="Inversión y Gasto">
          <IngresoVsGastoEstadisticaGrafica />
        </AccordionTab>
      </Accordion>
    </div>
  )
}

export default HomeAdministracion
