import ModeladoOperacionList from '../components/ModeladoOperacionList'

export const ModeladoOperacionPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <ModeladoOperacionList />
      </div>
    </div>
  )
}
