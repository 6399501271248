/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { PresupuestoContext } from '../contexts/PresupuestoContext'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import { addLocale } from 'primereact/api'

import moment from 'moment'
import { ProyectoContext } from '../contexts/ProyectoContext'
import { InputTextarea } from 'primereact/inputtextarea'
import { classNames } from 'primereact/utils'
// import { InputNumber } from 'primereact/inputnumber'
import { ProveedorContext } from '../contexts/ProveedorContext'
import { AbonoPresupuestoContext } from '../contexts/AbonoPresupuestoContext'
import { AvancePresupuestoContext } from '../contexts/AvancePresupuestoContext'
import PresupuestoAbonoList from './PresupuestoAbonoList'
import PresupuestoAvanceList from './PresupuestoAvanceList'
import AbonoPresupuestoForm from './AbonoPresupuestoForm'
import AvancePresupuestoForm from './AvancePresupuestoForm'
import { Calendar } from 'primereact/calendar'
import { InputNumber } from 'primereact/inputnumber'

const ActivoForm = (props) => {
  const initialPresupuestoForm = {
    id: null,
    nombrePresupuesto: '',
    descripcionPresupuesto: '',
    estatusPresupuesto: '',
    totalValuacionPresupuesto: 0,
    totalPlanPresupuesto: 0,
    totalRealPresupuesto: 0,
    totalPresupuesto: 0,
    avancePresupuesto: 0,
    subProyectoId: null,
    image: null,
    imageFinal: null,
    creadoPresupuesto: moment(),
    modificadoPresupuesto: moment(),
    proveedorId: null
  }

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  const {
    createPresupuesto,
    editPresupuesto,
    updatePresupuesto,
    setEditPresupuesto
  } = useContext(PresupuestoContext)
  const { subProyectos } = useContext(SubProyectoContext)
  const { proyectos } = useContext(ProyectoContext)
  const { proveedors } = useContext(ProveedorContext)
  const { abonoPresupuestos } = useContext(AbonoPresupuestoContext)
  const { avancePresupuestos } = useContext(AvancePresupuestoContext)

  const { isVisible, setIsVisible } = props
  const [selectedPresupuesto, setSelectedPresupuesto] = useState(null)
  const [selectedPrioridadPresupuesto, setSelectedPrioridadPresupuesto] =
    useState(null)
  const [selectedProyecto, setSelectedProyecto] = useState(null)
  const [selectedProveedor, setSelectedProveedor] = useState(null)
  const [subProyecto, setSubProyecto] = useState(subProyectos)
  const [PresupuestoData, setPresupuestoData] = useState(initialPresupuestoForm)
  const [selectedSubProyecto, setSelectedSubProyecto] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [abonoPresupuestosFilter, setAbonoPresupuestosFilter] = useState([])
  const [avancePresupuestosFilter, setAvancePresupuestosFilter] = useState([])
  const [abonoPresupuestosTotal, setAbonoPresupuestosTotal] = useState(0)
  const [avancePresupuestosTotal, setAvancePresupuestosTotal] = useState(0)
  const [realAvancePresupuestosTotal, setRealAvancePresupuestosTotal] =
    useState(0)
  const [isVisibleAbono, setIsVisibleAbono] = useState(false)
  const [isVisibleAvance, setIsVisibleAvance] = useState(false)
  const [dateInicioPlan, setDateInicioPlan] = useState(null)
  const [dateFinalPlan, setDateFinalPlan] = useState(null)
  const [dateInicioReal, setDateInicioReal] = useState(null)
  const [dateFinalReal, setDateFinalReal] = useState(null)
  const estadoPresupuesto = [
    { estatusPresupuesto: 'ELABORADO' },
    { estatusPresupuesto: 'ANULADO' }
  ]
  const prioridadPresupuesto = [
    { prioridadPresupuesto: 'ALTA' },
    { prioridadPresupuesto: 'MEDIA' },
    { prioridadPresupuesto: 'BAJA' }
  ]
  const onProyecto = (e) => {
    if (e.value) {
      const subProyectoFilter = subProyectos.filter(
        (p) => p.proyectoId?.id === e.value.id
      )
      setSelectedProyecto(e.value)

      if (subProyectoFilter.length > 0) {
        setSubProyecto(subProyectoFilter)
      } else {
        setSubProyecto(null)
      }
    } else {
      setSelectedProyecto(null)
      setSubProyecto(null)
      setSelectedSubProyecto(null)
    }
  }
  const onProveedor = (e) => {
    if (e.value) {
      setSelectedProveedor(e.value)
      updateField(e.value.id, 'proveedorId')
    } else {
      setSelectedProveedor(null)
    }
  }
  const onEstatusPresupuesto = (e) => {
    setSelectedPresupuesto(e.value)
    updateField(e.value.estatusPresupuesto, 'estatusPresupuesto')
  }
  const onPrioridadPresupuesto = (e) => {
    setSelectedPrioridadPresupuesto(e.value)
    updateField(e.value.prioridadPresupuesto, 'prioridadPresupuesto')
  }

  const toast = useRef(null)
  const onSubProyecto = (e) => {
    e.value
      ? (setSelectedSubProyecto(e.value),
        updateField(e.value.id, 'subProyectoId'))
      : (setSelectedSubProyecto(null), updateField(null, 'subProyectoId'))
  }
  useEffect(() => {
    if (editPresupuesto) {
      setPresupuestoData({
        ...editPresupuesto,
        subProyectoId: editPresupuesto.subProyectoId?.id,
        proveedorId: editPresupuesto.proveedorId?.id,
        image: null,
        imageFinal: null
      })
      setSelectedPresupuesto({
        estatusPresupuesto: editPresupuesto.estatusPresupuesto
      })
      setSelectedPrioridadPresupuesto({
        prioridadPresupuesto: editPresupuesto.prioridadPresupuesto
      })
      const subProyectoSelecEdit =
        editPresupuesto.subProyectoId &&
        subProyectos.find((p) => p.id === editPresupuesto.subProyectoId.id)
      const proveedorSelecEdit =
        editPresupuesto.proveedorId &&
        proveedors.find((p) => p.id === editPresupuesto.proveedorId.id)

      const proyectoSelecEdit =
        subProyectoSelecEdit.proyectoId &&
        proyectos.find((p) => p.id === subProyectoSelecEdit.proyectoId.id)

      setSelectedProyecto(proyectoSelecEdit)
      setSubProyecto(subProyectos)
      setSelectedSubProyecto(subProyectoSelecEdit)
      setSelectedProveedor(proveedorSelecEdit)
      setDateInicioPlan(
        editPresupuesto.fechaInicioPlanPresupuesto &&
          moment(editPresupuesto.fechaInicioPlanPresupuesto)._d
      )
      setDateFinalPlan(
        editPresupuesto.fechaFinalPlanPresupuesto &&
          moment(editPresupuesto.fechaFinalPlanPresupuesto)._d
      )
      setDateInicioReal(
        editPresupuesto.fechaInicioRealPresupuesto &&
          moment(editPresupuesto.fechaInicioRealPresupuesto)._d
      )
      setDateFinalReal(
        editPresupuesto.fechaFinalRealPresupuesto &&
          moment(editPresupuesto.fechaFinalRealPresupuesto)._d
      )
    }
  }, [editPresupuesto])

  useEffect(() => {
    if (editPresupuesto) {
      const abonoPresupuestosFilter = abonoPresupuestos.filter(
        (p) => p.presupuestoId.id === editPresupuesto.id
      )
      const avancePresupuestosFilter = avancePresupuestos.filter(
        (p) => p.presupuestoId.id === editPresupuesto.id
      )
      const abonoPresupuestosTotal = abonoPresupuestosFilter.reduce(
        (acc, p) => acc + p.totalAbonoPresupuesto,
        0
      )
      const avancePresupuestosTotal = avancePresupuestosFilter.reduce(
        (acc, p) => acc + p.totalAvancePresupuesto,
        0
      )

      const realAvancePresupuestosTotal = avancePresupuestosFilter.reduce(
        (acc, p) => acc + p.totalAvanceRealPresupuesto,
        0
      )
      setAbonoPresupuestosFilter(abonoPresupuestosFilter)
      setAvancePresupuestosFilter(avancePresupuestosFilter)
      setAbonoPresupuestosTotal(abonoPresupuestosTotal)
      setAvancePresupuestosTotal(avancePresupuestosTotal)
      setRealAvancePresupuestosTotal(realAvancePresupuestosTotal)
    }
  }, [editPresupuesto, avancePresupuestos, abonoPresupuestos])

  const updateField = (data, field) => {
    setPresupuestoData({
      ...PresupuestoData,
      [field]: data
    })
  }

  const savePresupuesto = () => {
    setSubmitted(true)
    if (
      PresupuestoData.estatusPresupuesto.trim() &&
      PresupuestoData.nombrePresupuesto.trim() &&
      PresupuestoData.subProyectoId.trim()
    ) {
      if (!editPresupuesto) {
        createPresupuesto(PresupuestoData)
      } else {
        if (
          PresupuestoData.image === null &&
          PresupuestoData.imageFinal === null
        ) {
          const { image, imageFinal, ...PresupuestoDataRest } = PresupuestoData
          updatePresupuesto({
            ...PresupuestoDataRest,
            PresupuestoModificado: moment()
          })
        } else if (PresupuestoData.image === null) {
          const { image, ...PresupuestoDataRest } = PresupuestoData
          updatePresupuesto({
            ...PresupuestoDataRest,
            PresupuestoModificado: moment()
          })
        } else if (PresupuestoData.imageFinal === null) {
          const { imageFinal, ...PresupuestoDataRest } = PresupuestoData
          updatePresupuesto({
            ...PresupuestoDataRest,
            PresupuestoModificado: moment()
          })
        } else {
          updatePresupuesto({
            ...PresupuestoData,
            PresupuestoModificado: moment()
          })
        }
      }
      clearSelected()
    }
  }
  const handleFormAbonoPresupuesto = () => {
    setIsVisibleAbono(true)
  }
  const handleFormAvancePresupuesto = () => {
    setIsVisibleAvance(true)
  }

  const dialogFooter = (
    <div className="d-flex flex-column sm:flex-row justify-content-between ">
      <div>
        {editPresupuesto && (
          <>
            <Button
              className="p-button-raised p-button-success p-button-text"
              label="Abono"
              icon="pi pi-plus"
              onClick={handleFormAbonoPresupuesto}
            />
            <Button
              className="p-button-raised p-button-success p-button-text"
              label="Avance"
              icon="pi pi-plus"
              onClick={handleFormAvancePresupuesto}
            />
          </>
        )}
      </div>
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button
          label="Cancelar"
          icon="pi pi-times"
          onClick={() => clearSelected()}
        />
        <Button label="Guardar" icon="pi pi-check" onClick={savePresupuesto} />
      </div>
    </div>
  )

  const clearSelected = () => {
    setIsVisible(false)
    setPresupuestoData(initialPresupuestoForm)
    setSelectedPresupuesto('')
    setSelectedProyecto(null)
    setSubmitted(false)
    setSelectedSubProyecto(null)
    setEditPresupuesto(null)
    setSelectedProveedor(null)
  }

  return (
    <div className="dialog-demo">
      <Toast ref={toast} />
      <Dialog
        visible={isVisible}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '50vw' }}
        header="Detalles del Presupuesto"
        footer={dialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="p-grid p-fluid">
          <div className="formgrid grid ">
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label">
                <Dropdown
                  inputId="dropdown"
                  value={selectedProyecto}
                  options={proyectos}
                  onChange={onProyecto}
                  optionLabel="nombreProyecto"
                  showClear
                  filter
                  filterBy="nombrePresupuesto"
                  className={classNames({
                    'p-invalid': submitted && !selectedProyecto
                  })}
                />
                {submitted && !selectedProyecto && (
                  <small className="p-invalid">Proyecto es requerido.</small>
                )}
                <label htmlFor="dropdown">Seleccione Proyecto*</label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3 ">
              <span className="p-float-label ">
                <Dropdown
                  value={selectedSubProyecto}
                  options={subProyecto}
                  onChange={onSubProyecto}
                  optionLabel="nombreSubProyecto"
                  showClear
                  filter
                  filterBy="nombreSubProyecto"
                  disabled={!selectedProyecto}
                  className={classNames({
                    'p-invalid': submitted && !PresupuestoData.subProyectoId
                  })}
                />
                {submitted && !PresupuestoData.subProyectoId && (
                  <small className="p-invalid">
                    Sub Proyecto es requerido.
                  </small>
                )}
                <label htmlFor="dropdown">Seleccione Sub Proyecto*</label>
              </span>
            </div>
            <div className="p-float-label col-12 md:col-6  mt-3">
              <InputText
                value={PresupuestoData.nombrePresupuesto}
                onChange={(e) =>
                  updateField(e.target.value, 'nombrePresupuesto')
                }
                className={classNames({
                  'p-invalid': submitted && !PresupuestoData.nombrePresupuesto
                })}
              />
              {submitted && !PresupuestoData.nombrePresupuesto && (
                <small className="p-invalid">Nombre es requerido.</small>
              )}
              <label>Nombre del Presupuesto:</label>
            </div>
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label">
                <Dropdown
                  inputId="dropdown"
                  value={selectedProveedor}
                  options={proveedors}
                  onChange={onProveedor}
                  optionLabel="nombreProveedor"
                  showClear
                  filter
                  filterBy="nombrePresupuesto"
                  className={classNames({
                    'p-invalid': submitted && !selectedProveedor
                  })}
                />
                {submitted && !selectedProveedor && (
                  <small className="p-invalid">Proveedor es requerido.</small>
                )}
                <label htmlFor="dropdown">Seleccione Proveedor*</label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label ">
                <Calendar
                  // className="p-datepicker-today"
                  id="time24"
                  value={dateInicioPlan !== null && dateInicioPlan}
                  onChange={(e) => {
                    setDateInicioPlan(e.value)
                    updateField(e.target.value, 'fechaInicioPlanPresupuesto')
                  }}
                  showTime
                  locale="es"
                  // hourFormat="12"
                  showButtonBar
                  className={classNames(
                    {
                      'p-invalid':
                        submitted && !PresupuestoData.fechaInicioPlanPresupuesto
                    },
                    'p-datepicker-today'
                  )}
                />{' '}
                {submitted && !PresupuestoData.fechaInicioPlanPresupuesto && (
                  <small className="p-invalid">Fecha es requerido.</small>
                )}
                <label>Fecha Inicio Plan </label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label ">
                <Calendar
                  // className="p-datepicker-today"
                  id="time24"
                  value={dateFinalPlan !== null && dateFinalPlan}
                  onChange={(e) => {
                    setDateFinalPlan(e.value)
                    updateField(e.target.value, 'fechaFinalPlanPresupuesto')
                  }}
                  showTime
                  locale="es"
                  // hourFormat="12"
                  showButtonBar
                  className={classNames(
                    {
                      'p-invalid':
                        submitted && !PresupuestoData.fechaFinalPlanPresupuesto
                    },
                    'p-datepicker-today'
                  )}
                />{' '}
                {submitted && !PresupuestoData.fechaFinalPlanPresupuesto && (
                  <small className="p-invalid">Fecha es requerido.</small>
                )}
                <label>Fecha Final Plan </label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label ">
                <Calendar
                  // className="p-datepicker-today"
                  id="time24"
                  value={dateInicioReal !== null && dateInicioReal}
                  onChange={(e) => {
                    setDateInicioReal(e.value)
                    updateField(e.target.value, 'fechaInicioRealPresupuesto')
                  }}
                  showTime
                  locale="es"
                  // hourFormat="12"
                  showButtonBar
                  className={classNames(
                    {
                      'p-invalid':
                        submitted && !PresupuestoData.fechaInicioRealPresupuesto
                    },
                    'p-datepicker-today'
                  )}
                />{' '}
                {submitted && !PresupuestoData.fechaInicioRealPresupuesto && (
                  <small className="p-invalid">Fecha es requerido.</small>
                )}
                <label>Fecha Inicio Real</label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label ">
                <Calendar
                  // className="p-datepicker-today"
                  id="time24"
                  value={dateFinalReal !== null && dateFinalReal}
                  onChange={(e) => {
                    setDateFinalReal(e.value)
                    updateField(e.target.value, 'fechaFinalRealPresupuesto')
                  }}
                  showTime
                  locale="es"
                  // hourFormat="12"
                  showButtonBar
                  className={classNames(
                    {
                      'p-invalid':
                        submitted && !PresupuestoData.fechaFinalRealPresupuesto
                    },
                    'p-datepicker-today'
                  )}
                />{' '}
                {submitted && !PresupuestoData.fechaFinalRealPresupuesto && (
                  <small className="p-invalid">Fecha es requerido.</small>
                )}
                <label>Fecha Final Real </label>
              </span>
            </div>

            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label ">
                <InputNumber
                  inputId="totalPlanPresupuesto"
                  value={PresupuestoData.totalPlanPresupuesto}
                  onValueChange={(e) =>
                    updateField(e.target.value, 'totalPlanPresupuesto')
                  }
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                />
                <label htmlFor="totalPlanPresupuesto">Total Plan</label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label ">
                <InputNumber
                  inputId="totalPlanPresupuesto"
                  value={PresupuestoData.totalRealPresupuesto}
                  onValueChange={(e) =>
                    updateField(e.target.value, 'totalRealPresupuesto')
                  }
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                />

                <label htmlFor="totalRealPresupuesto">Total Real</label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label ">
                <InputNumber
                  inputId="totalPresupuesto"
                  value={PresupuestoData.totalPresupuesto}
                  onValueChange={(e) =>
                    updateField(e.target.value, 'totalPresupuesto')
                  }
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                />
                <label htmlFor="totalPresupuesto">Total Automatico</label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label ">
                <InputNumber
                  inputId="avancePresupuesto"
                  value={PresupuestoData.avancePresupuesto}
                  onValueChange={(e) =>
                    updateField(e.target.value, 'avancePresupuesto')
                  }
                  suffix="%"
                />
                <label htmlFor="avancePresupuesto">Avance Automatico</label>
              </span>
            </div>

            <div className="p-float-label col-12  mt-4">
              <InputTextarea
                id="description"
                value={PresupuestoData.descripcionPresupuesto}
                onChange={(e) =>
                  updateField(e.target.value, 'descripcionPresupuesto')
                }
                rows={3}
                cols={20}
              />

              <label>Descripcion:</label>
            </div>
            <div className="card col-12">
              Imagen de Inicio
              <input
                type="file"
                onChange={(e) => updateField(e.target.files[0], 'image')}
              />
            </div>

            <div className="card col-12">
              Imagen de Avance
              <input
                type="file"
                onChange={(e) => updateField(e.target.files[0], 'imageFinal')}
              />
            </div>
            <div className="field col-12 md:col-6 mt-3">
              <span className="p-float-label">
                <Dropdown
                  value={selectedPrioridadPresupuesto}
                  options={prioridadPresupuesto}
                  onChange={onPrioridadPresupuesto}
                  optionLabel="prioridadPresupuesto"
                  className={classNames({
                    'p-invalid':
                      submitted && !PresupuestoData.prioridadPresupuesto
                  })}
                />
                {submitted && !PresupuestoData.prioridadPresupuesto && (
                  <small className="p-invalid">Prioridad es requerido.</small>
                )}
                <label htmlFor="dropdown">Seleccione Prioridad*</label>
              </span>
            </div>
            <div className="field col-12 md:col-6 mt-3">
              <span className="p-float-label">
                <Dropdown
                  value={selectedPresupuesto}
                  options={estadoPresupuesto}
                  onChange={onEstatusPresupuesto}
                  optionLabel="estatusPresupuesto"
                  className={classNames({
                    'p-invalid':
                      submitted && !PresupuestoData.estatusPresupuesto
                  })}
                />
                {submitted && !PresupuestoData.estatusPresupuesto && (
                  <small className="p-invalid">Estatus es requerido.</small>
                )}
                <label htmlFor="dropdown">Seleccione Estado*</label>
              </span>
            </div>

            {editPresupuesto && abonoPresupuestosFilter.length > 0 && (
              <div className="field col-6 mt-4">
                <span>
                  Abonos Total-{' '}
                  {abonoPresupuestosTotal.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  })}
                </span>
                <div style={{ height: '100px', overflow: 'auto' }}>
                  <PresupuestoAbonoList
                    abonoPresupuestosFilter={abonoPresupuestosFilter}
                  />
                </div>
              </div>
            )}
            {editPresupuesto && avancePresupuestosFilter.length > 0 && (
              <div className="field col-6 mt-4">
                <span>Avance Plan- {avancePresupuestosTotal}%</span>
                {'//'}
                <span>Avance Real- {realAvancePresupuestosTotal}%</span>
                {'//'}
                <span>
                  Desfase Total-{' '}
                  {avancePresupuestosTotal - realAvancePresupuestosTotal}%
                </span>
                {'//'}
                <div style={{ height: '100px', overflow: 'auto' }}>
                  <PresupuestoAvanceList
                    avancePresupuestosFilter={avancePresupuestosFilter}
                  />
                </div>
              </div>
            )}
            {/* <div className="field col-6 mt-4">
              <span className="p-float-label ">
                <InputNumber
                  inputId="totalValuacionPresupuesto"
                  value={PresupuestoData.totalValuacionPresupuesto}
                  onValueChange={(e) =>
                    updateField(e.target.value, 'totalValuacionPresupuesto')
                  }
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                />

                <label htmlFor="totalValuacionPresupuesto">
                  Valuacion Total
                </label>
              </span>
            </div> */}
          </div>
        </div>
      </Dialog>
      <AbonoPresupuestoForm
        isVisible={isVisibleAbono}
        setIsVisible={setIsVisibleAbono}
        editPresupuesto={editPresupuesto}
      />
      <AvancePresupuestoForm
        isVisible={isVisibleAvance}
        setIsVisible={setIsVisibleAvance}
        editPresupuesto={editPresupuesto}
      />
    </div>
  )
}

export default ActivoForm
