/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { useContext, useEffect, useState } from 'react'
import { PresupuestoContext } from '../contexts/PresupuestoContext'
import HomePresupuestoCardRender from './HomePresupuestoCardRender'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Chart } from 'primereact/chart'
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Area,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  Sector
} from 'recharts'

import { ProgressBar } from 'primereact/progressbar'
import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import rojo from '../assetsControl/rojo.svg'
import verde from '../assetsControl/verde.svg'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { ComentarioSubProyectoContext } from '../contexts/ComentarioSubProyectoContext'
import { AvancePresupuestoContext } from '../contexts/AvancePresupuestoContext'
import { AbonoPresupuestoContext } from '../contexts/AbonoPresupuestoContext'
import { Textfit } from 'react-textfit'
import HomePresupuestoCardRenderNew from './HomePresupuestoCardRenderNew'
const HomeSubProyectoCardRenderNew = ({ subProyectos }) => {
  const { presupuestos } = useContext(PresupuestoContext)
  const { comentarioSubProyectos } = useContext(ComentarioSubProyectoContext)
  const { avancePresupuestos } = useContext(AvancePresupuestoContext)
  const { abonoPresupuestos } = useContext(AbonoPresupuestoContext)

  const comentarioSubProyectosFilter = comentarioSubProyectos.filter(
    (p) => p.subProyectoId?.id === subProyectos.id
  )
  const [activeIndex, setActiveIndex] = useState(0)

  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  }

  const [displayDetalleCarga, setDisplayDetalleCarga] = useState(false)
  const [presupuesCantidadTotal, setPresupuestoCantidadTotal] = useState(0)
  const [presupuestoTotalPlan, setPresupuestoTotalPlan] = useState(0)
  const [presupuestoTotalReal, setPresupuestoTotalReal] = useState(0)
  const [dateMenorFecha, setDateMenorFecha] = useState()
  const [dateMenorFechaPlan, setDateMenorFechaPlan] = useState()
  const [dateMayorFechaPlan, setDateMayorFechaPlan] = useState()
  const [dateMenorFechaReal, setDateMenorFechaReal] = useState()
  const [dateMayorFechaReal, setDateMayorFechaReal] = useState()
  const [dateMayorFecha, setDateMayorFecha] = useState()
  const [dataPorcentajeAvance, setDataPorcentajeAvance] = useState()
  const [presupuestoPorcentajeAvanceReal, setPresupuestoPorcentajeAvanceReal] =
    useState()
  const [presupuestoPorcentajeAvancePlan, setPresupuestoPorcentajeAvancePlan] =
    useState()
  const [abonoPresupuesto, setAbonoPresupuesto] = useState()
  const [proveedorPresupuesto, setProveedorPresupuesto] = useState()
  const [dataGrafica, setDataGrafica] = useState([
    {
      name: 'Page A',
      totalAuto: presupuesCantidadTotal,
      totalPlan: presupuestoTotalPlan,
      totalReal: presupuestoTotalReal,
      totalabono: abonoPresupuesto
    }
  ])
  const fecha1 = moment(dateMenorFecha)
  const fecha2 = moment(dateMayorFecha || moment())
  const fechaToDay = moment()
  const fechaMenorPlan = moment(dateMenorFechaPlan)
  const fechaMayorPlan = moment(dateMayorFechaPlan)
  const fechaMenorReal = moment(dateMenorFechaReal)
  const fechaMayorReal = moment(dateMayorFechaReal)
  const diffPlanTotal = fechaMayorPlan.diff(fechaMenorPlan, 'days') // Diff in days
  const diffRealTotal = fechaMayorReal.diff(fechaMenorReal, 'days') // Diff in days
  const diffDesfaseInicio = fechaMenorReal.diff(fechaMenorPlan, 'days') // Diff in days
  const diffDesfaseFin = fechaMayorReal.diff(fechaMayorPlan, 'days') // Diff in days
  // const diffPlanToDay = fechaToDay.diff(fechaMenorPlan, 'seconds') // Diff in days
  // const porcentajeDiasPlan = (diffPlanToDay * 100) / diffPlanTotal
  // console.log('porcentaje de dias', porcentajeDiasPlan)

  // Diff in hours
  const diff = fecha2.diff(fecha1, 'seconds') // Diff in days
  const diff1 = fechaToDay.diff(fecha1, 'seconds')

  const porcentajeDias = (diff1 * 100) / diff
  useEffect(() => {
    sumaPresupuesto()
  }, [subProyectos, presupuestos])
  const dialogFuncMap = {
    displayDetalleCarga: setDisplayDetalleCarga
  }
  const onClick = (name) => {
    dialogFuncMap[`${name}`](true)
  }
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false)
  }

  const sumaPresupuesto = () => {
    // GATOS
    const presupuesto = presupuestos.filter(
      (p) =>
        p.subProyectoId?.id === subProyectos.id &&
        p.estatusPresupuesto === 'APROBADO'
    )
    setProveedorPresupuesto()
    let total = 0
    let totalPlan = 0
    let totalReal = 0
    const menorFecha = presupuesto.map((p) =>
      p.fechaInicioPresupuesto
        ? moment(p.fechaInicioPresupuesto).format('x')
        : moment().format('x')
    )
    setDateMenorFecha(Math.min(...menorFecha))

    const menorFechaPlan = presupuesto.map((p) =>
      p.fechaInicioPlanPresupuesto
        ? moment(p.fechaInicioPlanPresupuesto).format('x')
        : moment().format('x')
    )
    setDateMenorFechaPlan(Math.min(...menorFechaPlan))

    const mayorFechaPlan = presupuesto.map((p) =>
      p.fechaFinalPlanPresupuesto
        ? moment(p.fechaFinalPlanPresupuesto).format('x')
        : moment().format('x')
    )
    setDateMayorFechaPlan(Math.max(...mayorFechaPlan))

    const menorFechaReal = presupuesto.map((p) =>
      p.fechaInicioRealPresupuesto
        ? moment(p.fechaInicioRealPresupuesto).format('x')
        : moment().format('x')
    )
    setDateMenorFechaReal(Math.min(...menorFechaReal))

    const mayorFechaReal = presupuesto.map((p) =>
      p.fechaFinalRealPresupuesto
        ? moment(p.fechaFinalRealPresupuesto).format('x')
        : moment().format('x')
    )
    setDateMayorFechaReal(Math.max(...mayorFechaReal))

    const mayorFecha = presupuesto.map((p) =>
      p.fechaFinalPresupuesto
        ? moment(p.fechaFinalPresupuesto).format('x')
        : moment().format('x')
    )
    setDateMayorFecha(Math.max(...mayorFecha))
    for (const unitario of presupuesto) {
      // precioUnitario += unitario.precioUnitarioDataPresupuesto
      // cantidad += unitario.cantidadDataPresupuesto
      if (unitario.estatusPresupuesto === 'APROBADO') {
        total += unitario.totalPresupuesto
        totalPlan += unitario.totalPlanPresupuesto
        totalReal += unitario.totalRealPresupuesto
      }
    }
    setPresupuestoCantidadTotal(total)
    setPresupuestoTotalPlan(totalPlan)
    setPresupuestoTotalReal(totalReal)
    let auxAvancePorcentaje = []
    presupuesto.forEach((dataset, i) => {
      auxAvancePorcentaje = auxAvancePorcentaje.concat(
        dataset.avancePresupuesto
      )
    })
    setDataPorcentajeAvance(
      (
        auxAvancePorcentaje.reduce((a, b) => a + b, 0) / presupuesto.length
      ).toFixed(2)
    )
    let avancePresupuestoPlan = []
    presupuesto.forEach((dataset, i) => {
      avancePresupuestos.forEach((avance) => {
        if (
          avance.presupuestoId.id === dataset.id &&
          dataset.estatusPresupuesto === 'APROBADO'
        ) {
          avancePresupuestoPlan = avancePresupuestoPlan.concat(
            avance.totalAvancePresupuesto
          )
        }
      })
    })
    setPresupuestoPorcentajeAvancePlan(
      avancePresupuestoPlan.reduce((a, b) => a + b, 0) / presupuesto.length
    )
    let abonoPresupuesto = []
    presupuesto.forEach((dataset, i) => {
      abonoPresupuestos.forEach((abono) => {
        if (
          abono.presupuestoId.id === dataset.id &&
          dataset.estatusPresupuesto === 'APROBADO'
        ) {
          abonoPresupuesto = abonoPresupuesto.concat(
            abono.totalAbonoPresupuesto
          )
        }
      })
    })
    setAbonoPresupuesto(abonoPresupuesto.reduce((a, b) => a + b, 0))
    let avancePresupuestosReal = []
    presupuesto.forEach((dataset, i) => {
      avancePresupuestos.forEach((avance) => {
        if (avance.presupuestoId.id === dataset.id) {
          avancePresupuestosReal = avancePresupuestosReal.concat(
            avance.totalAvanceRealPresupuesto
          )
        }
      })
    })
    setPresupuestoPorcentajeAvanceReal(
      avancePresupuestosReal.reduce((a, b) => a + b, 0) / presupuesto.length
    )

    //   .map((p) => p.cantidadDataPresupuesto)
    //   .reduce((a, b) => a + b, 0)

    // const totalPrecioUnitario = dataPresupuesto
    //   .map((p) => p.precioUnitarioDataPresupuesto)
    //   .reduce((a, b) => a + b, 0)

    // setDataPrecioUnitarioTotal(totalPrecioUnitario)

    // const totalValuaciones = dataPresupuesto
    //   .map((p) => p.valuacionCantidadDataPresupuesto)
    //   .reduce((a, b) => a + b, 0)
  }
  // const formatCurrency = (value) => {
  //   return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  // }
  // const hijoAPadre = (datoshijo) => {
  //   setDatoshijo(datoshijo)
  // }
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Salir"
          icon="pi pi-check"
          onClick={() => onHide(name)}
          autoFocus
        />
      </div>
    )
  }
  const headerTemplate = (dato) => {
    return (
      <>
        <div className="my-2">
          {dato.nombrePresupuesto.toUpperCase()} {'('}Inicio
          {moment(dato.fechaInicioPresupuesto).format('DD/MM/YY')} Fin
          {moment(dato.fechaFinalPresupuesto).format('DD/MM/YY')}
          {')'}
          <ProgressBar value={dato.avancePresupuesto}></ProgressBar>
        </div>
      </>
    )
  }
  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }
  const pieData = {
    labels: ['Porcentaje Días', 'Porcentaje Avance'],
    datasets: [
      {
        data: [porcentajeDias, dataPorcentajeAvance],
        backgroundColor: ['#42A5F5', '#66BB6A']
      }
    ]
  }
  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // responsive: true,
    plugins: {
      legend: {
        display: false, // Ocultar las etiquetas
        position: 'bottom',
        labels: {
          color: '#151515',
          position: 'bottom'
        }
      },
      tooltip: {
        // mode: 'dataset',
        // intersect: true,
        // caretPadding: 5
        // enabled: false
      }
    },
    layout: {
      padding: 0
    },
    title: {
      display: false // Ocultar el título
    },
    scales: {
      x: {
        display: false // Ocultar el eje x
      },
      y: {
        display: false // Ocultar el eje y
      }
    }
    // Aquí puedes añadir más opciones si lo necesitas
  }
  const customStyles = {
    path: {
      stroke: 'rgba(255, 0, 0, 1)' // Color de la barra de progreso
    },
    trail: {
      stroke: '#d6d6d6' // Color de la línea de fondo
    },
    text: {
      fill: '#f88', // Color del texto
      fontSize: '12px' // Tamaño del texto
    }
  }
  const customStyles1 = {
    path: {
      stroke: 'rgba(7, 61, 139, 1)' // Color de la barra de progreso
    },
    trail: {
      stroke: '#d6d6d6' // Color de la línea de fondo
    },
    text: {
      fill: '#002458', // Color del texto
      fontSize: '12px' // Tamaño del texto
    }
  }
  const styleObject = {
    // marginBottom: '3px',
    // borderWidth: '2px',
    // borderRadius: '10px',
    // border: '2px solid ',
    // color: 'black',
    // padding: '5px',
    // marginRight: '5px',
    whiteSpace: 'nowrap'
  }
  useEffect(() => {
    setDataGrafica([
      {
        name: 'Page A',
        totalAuto: presupuesCantidadTotal,
        totalPlan: presupuestoTotalPlan,
        totalReal: presupuestoTotalReal,
        totalabono: abonoPresupuesto,
        totalAbonoPendiente: presupuestoTotalReal - abonoPresupuesto
      }
    ])
  }, [
    presupuesCantidadTotal,
    presupuestoTotalPlan,
    presupuestoTotalReal,
    abonoPresupuesto
  ])

  return (
    <div
      className=" col-12 lg:col-12 xl:col-12 "
      // onClick={onAppsControlClick}
    >
      <div
        className={'cardAPPS card mb-0 '}
        style={{ width: '100%', padding: '2px' }}
        // onClick={() => onClick('displayDetalleCarga')}
      >
        <div className="justify-center mb-1">
          <div style={{ width: '100%', color: 'black' }}>
            <span className="block text-center font-bold underline mb-1">
              {subProyectos.nombreSubProyecto.toUpperCase()}
              <span className="ml-2">
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-raised p-button-text p-button-success mb-1"
                  onClick={() => onClick('displayDetalleCarga')}
                  tooltip="Mostrar Detalle"
                  tooltipOptions={{ position: 'top' }}
                />
              </span>
            </span>
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="formgrid grid ">
            <div
              className="col-12 lg:col-12 xl:col-5"
              style={{ position: 'relative' }}
            >
              <div className=" w-100">
                <ResponsiveContainer width="100%" height={200}>
                  <ComposedChart
                    layout="vertical"
                    width={500}
                    height={400}
                    data={dataGrafica}
                    margin={{
                      left: -50
                    }}
                    barGap={10}
                  >
                    {/* <CartesianGrid stroke="#f5f5f5" /> */}
                    <XAxis
                      type="number"
                      tickFormatter={(value) => formatCurrency(value)}
                    />
                    <YAxis type="category" scale="band" />
                    <Tooltip />

                    {/* <Legend /> */}
                    {/* <Bar
                      name="totalAuto"
                      dataKey="totalAuto"
                      barSize={30}
                      fill="#ffc107d1"
                      label={({ x, y, width, height, value }) => (
                        <text
                          x={Math.max(150, x + Math.min(width, 200))}
                          y={y}
                          // fill="red"
                          textAnchor="middle"
                          dy={20}
                        >
                          Total Automatico: {formatCurrency(value)}
                        </text>
                      )}
                    /> */}
                    <Bar
                      name="totalPlan"
                      dataKey="totalPlan"
                      barSize={30}
                      fill="#0c3d8bc9"
                      label={({ x, y, width, height, value }) => (
                        <text
                          x={Math.max(150, x + Math.min(width, 200))}
                          y={y}
                          // fill="red"
                          textAnchor="middle"
                          dy={20}
                        >
                          Total Planificado: {formatCurrency(value)}
                        </text>
                      )}
                    />
                    <Bar
                      name="totalReal"
                      dataKey="totalReal"
                      barSize={30}
                      fill="#f50303d1"
                      label={({ x, y, width, height, value }) => (
                        <text
                          x={Math.max(150, x + Math.min(width, 200))}
                          y={y}
                          // fill="red"
                          textAnchor="middle"
                          dy={20}
                        >
                          Total Real: {formatCurrency(value)}
                        </text>
                      )}
                    />
                    <Bar
                      name="totalabono"
                      dataKey="totalabono"
                      barSize={30}
                      fill="#0dcaf0c2"
                      label={({ x, y, width, height, value }) => (
                        <text
                          x={Math.max(150, x + Math.min(width, 200))}
                          y={y}
                          // fill="red"
                          textAnchor="middle"
                          dy={20}
                        >
                          Total Abonos: {formatCurrency(value)}
                        </text>
                      )}
                    />
                    <Bar
                      name="totalAbonoPendiente"
                      dataKey="totalAbonoPendiente"
                      barSize={30}
                      fill="#ffc107b8"
                      label={({ x, y, width, height, value }) => (
                        <text
                          x={Math.max(150, x + Math.min(width, 200))}
                          y={y}
                          // fill="red"
                          textAnchor="middle"
                          dy={20}
                        >
                          Pendiente por Pagar: {formatCurrency(value)}
                        </text>
                      )}
                    />
                  </ComposedChart>
                  {(
                    ((presupuestoTotalReal - presupuestoTotalPlan) /
                      presupuestoTotalReal) *
                    100
                  ).toFixed(2) > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 0,
                        // bottom: 110,
                        top: 0,
                        width: '70px',
                        height: '70px'
                      }}
                    >
                      <Textfit mode="multi" max={9}>
                        Desviación presupuestaria
                      </Textfit>
                      <CircularProgressbar
                        value={(
                          ((presupuestoTotalReal - presupuestoTotalPlan) /
                            presupuestoTotalReal) *
                          100
                        ).toFixed(2)}
                        text={`${(
                          ((presupuestoTotalReal - presupuestoTotalPlan) /
                            presupuestoTotalReal) *
                          100
                        ).toFixed(2)}%`}
                        styles={customStyles}
                        strokeWidth={20}
                      />
                    </div>
                  )}
                </ResponsiveContainer>

                {/* <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    Total Automatico:
                    <hr style={{ margin: 0 }} />
                    <div>{formatCurrency(presupuesCantidadTotal)}</div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    Total Planificado:
                    <hr style={{ margin: 0 }} />
                    <div>{formatCurrency(presupuestoTotalPlan)}</div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    Total Real:
                    <hr style={{ margin: 0 }} />
                    <div>{formatCurrency(presupuestoTotalReal)}</div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    <Textfit mode="single" max={14}>
                      Desviación presupuestaria:
                    </Textfit>
                    <hr style={{ margin: 0 }} />
                    <div>
                      {(
                        ((presupuestoTotalReal - presupuestoTotalPlan) /
                          presupuestoTotalReal) *
                        100
                      ).toFixed(2)}
                      %
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    Total Abonos:
                    <hr style={{ margin: 0 }} />
                    <div>
                      {abonoPresupuesto && formatCurrency(abonoPresupuesto)}
                    </div>
                  </div>
                </div> */}
              </div>

              <div>
                {/* <ProgressBar
                value={dataPorcentajeAvance}
                color="#0dcaf0"
              ></ProgressBar> */}
              </div>
            </div>
            <div className="col-12 lg:col-12 xl:col-5 " style={{}}>
              <div className="flex ">
                <div className="  w-100">
                  <div style={{ textAlign: 'center' }}>
                    <div className="card p-0" style={styleObject}>
                      {'Dias Plan = '}
                      {diffPlanTotal}
                      <hr style={{ margin: 0 }} />
                      <div className="font text-muted small">
                        {moment(dateMenorFechaPlan).format('DD/MM')}-
                        {moment(dateMayorFechaPlan).format('DD/MM/YY')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="  w-100">
                  <div style={{ textAlign: 'center' }}>
                    <div className="card p-0" style={styleObject}>
                      {'Dias Real = '}
                      {diffRealTotal}

                      <hr style={{ margin: 0 }} />
                      <div className="font text-muted small">
                        {moment(dateMenorFechaReal).format('DD/MM')}-
                        {moment(dateMayorFechaReal).format('DD/MM/YY')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" w-100">
                  <div style={{ textAlign: 'center' }}>
                    <div
                      className={
                        'cardAPPS card  p-0 mb-0 border-bottom-0 border-start-0 border-4 m-1'
                      }
                      style={{
                        borderColor:
                          diffDesfaseInicio - diffDesfaseFin > 0
                            ? '#5ecca3'
                            : '#dc3545'
                      }}
                    >
                      Desfase
                      <hr style={{ margin: 0 }} />
                      <div className="small">
                        {diffDesfaseInicio - diffDesfaseFin * -1}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className=" w-100">
                  <div style={{ textAlign: 'center' }}>
                    <div className="card p-0" style={styleObject}>
                      Proveedor
                      <hr style={{ margin: 0 }} />
                      <div>
                        <Textfit mode="single" max={14}>
                          {subProyectos.proveedorId?.nombreProveedor}
                        </Textfit>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" w-100">
                  <div style={{ textAlign: 'center' }}>
                    <div className="card p-0" style={styleObject}>
                      Responsable
                      <hr style={{ margin: 0 }} />
                      <div>
                        <Textfit mode="single" max={14}>
                          {subProyectos.responsableId?.nombreResponsable}
                        </Textfit>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={styleObject} className="w-100 p-2 card ">
                <span
                  className="pi pi-exclamation-circle pi-spin"
                  style={{
                    position: 'absolute',
                    color: '#cdcd00',
                    fontSize: '2.5rem',
                    top: '0',
                    right: '0'
                  }}
                ></span>
                {comentarioSubProyectosFilter.slice(-3).map((p) => (
                  <div key={p.id}>
                    <spam>{p.descripcionComentarioSubProyecto}</spam>
                    {' - '}
                    <spam>{moment(p.createdAt).format('DD/MM/YY')}</spam>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="col-12 lg:col-2 xl:col-2"
              style={
                {
                  // display: 'flex'
                  // justifyContent: 'space-between'
                  // width: '180px' // Aumenta el ancho para dar espacio a los títulos
                  // height: '75px'
                  // backgroundColor: 'green'
                }
              }
            >
              <div
                style={{
                  // width: '80px',
                  textAlign: 'center',
                  display: 'flex'
                }}
              >
                <div
                  style={{
                    // width: '80px',
                    textAlign: 'center'
                  }}
                >
                  <div
                    className="card p-0"
                    // style={{
                    //   marginBottom: '10px',
                    //   borderWidth: '2px',
                    //   borderRadius: '10px',
                    //   border: '2px solid ',
                    //   // color: 'black',
                    //   padding: '5px'
                    // }}
                  >
                    Planificado
                  </div>
                  <div style={{}}>
                    <CircularProgressbar
                      value={presupuestoPorcentajeAvancePlan}
                      text={`${(
                        presupuestoPorcentajeAvancePlan || 0
                      ).toFixed()}%`}
                      strokeWidth={20}
                    />
                  </div>
                </div>
                <div
                  style={{
                    // width: '80px',
                    textAlign: 'center'
                    // marginLeft: '10px'
                  }}
                >
                  {/* Envuelve el CircularProgressbar y el título en un div */}
                  <div
                    className="card p-0"
                    // style={{
                    //   marginBottom: '10px',
                    //   borderWidth: '2px',
                    //   borderRadius: '10px',
                    //   border: '2px solid ',
                    //   // color: 'black',
                    //   padding: '5px'
                    // }}
                  >
                    Ejecutado
                  </div>
                  {/* Título del CircularProgressbar */}
                  <div>
                    <CircularProgressbar
                      value={presupuestoPorcentajeAvanceReal}
                      text={`${(
                        presupuestoPorcentajeAvanceReal || 0
                      ).toFixed()}%`}
                      styles={customStyles}
                      strokeWidth={20}
                    />
                  </div>
                </div>
              </div>
              <div className=" w-100 mt-1">
                <div style={{ textAlign: 'center' }}>
                  <div
                    className={
                      ' card mb-0 p-0 border-bottom-0 border-start-0 border-4 m-1'
                    }
                    style={{
                      borderColor:
                        presupuestoPorcentajeAvancePlan -
                          presupuestoPorcentajeAvanceReal >
                        0
                          ? '#dc3545'
                          : '#5ecca3'
                    }}
                  >
                    Variación del avance
                    <hr style={{ margin: 0 }} />
                    <div>
                      {presupuestoPorcentajeAvancePlan -
                        presupuestoPorcentajeAvanceReal}
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className="col-12 lg:col-12 xl:col-12"
              style={{
                display: 'flex'
                // justifyContent: 'space-between'
                // width: '180px' // Aumenta el ancho para dar espacio a los títulos
                // height: '75px'
                // backgroundColor: 'green'
              }}
            >
              <div style={styleObject} className="w-100 p-2">
                {comentarioSubProyectosFilter.slice(-3).map((p) => (
                  <div key={p.id}>
                    <spam>{p.descripcionComentarioSubProyecto}</spam>
                    {' - '}
                    <spam>{moment(p.createdAt).format('DD/MM/YY')}</spam>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Dialog
        header={'Detalles'}
        visible={displayDetalleCarga}
        onHide={() => onHide('displayDetalleCarga')}
        breakpoints={{ '960px': '85vw' }}
        style={{ width: '85vw' }}
        footer={renderFooter('displayDetalleCarga')}
      >
        <div className="flex  mb-1">
          {presupuestos.map(
            (presupuesto) =>
              presupuesto.estatusPresupuesto === 'APROBADO' &&
              presupuesto.subProyectoId?.id === subProyectos.id && (
                <HomePresupuestoCardRenderNew
                  key={presupuesto.id}
                  presupuestos={presupuesto}
                />
              )
          )}
        </div>
      </Dialog>
    </div>
  )
}

export default HomeSubProyectoCardRenderNew
