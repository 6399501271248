import DataPresupuestoList from '../components/DataPresupuestoList'
import DataPresupuestoContextProvider from '../contexts/DataPresupuestoContext'
import PresupuestoContextProvider from '../contexts/PresupuestoContext'
import ProyectoContextProvider from '../contexts/ProyectoContext'
import SubProyectoContextProvider from '../contexts/SubProyectoContext'

export const DataPresupuestoPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <h5>DataPresupuesto CRUD</h5>
          <ProyectoContextProvider>
            <SubProyectoContextProvider>
              <DataPresupuestoContextProvider>
                <PresupuestoContextProvider>
                  <DataPresupuestoList />
                </PresupuestoContextProvider>
              </DataPresupuestoContextProvider>
            </SubProyectoContextProvider>
          </ProyectoContextProvider>
        </div>
      </div>
    </div>
  )
}
