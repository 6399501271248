/* eslint-disable react/prop-types */
import React, { useContext, useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { ProgressBar } from 'primereact/progressbar'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { DataPresupuestoContext } from '../contexts/DataPresupuestoContext'
import moment from 'moment'

const HomeDataPresupuestoListRender = ({ dataFintrado }) => {
  const { loading } = useContext(DataPresupuestoContext)

  const [globalFilter, setGlobalFilter] = useState(null)

  const dt = useRef(null)
  const toast = useRef(null)

  const fechafechaInicioDataPresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaInicioDataPresupuesto)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechafechaFinalDataPresupuesto = (rowData) => {
    const fecha = moment(rowData.fechaFinalDataPresupuesto)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }
  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">DataPresupuesto</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  )

  const activityBodyTemplate = (rowData) => {
    const porcentajeAvance =
      (rowData.valuacionCantidadDataPresupuesto * 100) /
      rowData.cantidadDataPresupuesto
    return (
      <ProgressBar value={porcentajeAvance} showValue={false}></ProgressBar>
    )
  }
  const valuacionPrecioBodyTemplate = (rowData) => {
    // const porcentajeAvance =
    //   (rowData.valuacionPrecioUnitarioDataPresupuesto * 100) /
    //   rowData.precioUnitarioDataPresupuesto
    return <>{rowData.valuacionPrecioUnitarioDataPresupuesto}</>
  }
  const precioUnitarioBodyTemplate = (rowData) => {
    return formatCurrency(rowData.precioUnitarioDataPresupuesto)
  }
  const totalBodyTemplate = (rowData) => {
    return formatCurrency(
      rowData.cantidadDataPresupuesto * rowData.precioUnitarioDataPresupuesto
    )
  }

  const totalDataPresupuestoSuma = () => {
    // let cantidad = 0
    // let precioUnitario = 0
    let total = 0
    for (const unitario of dataFintrado) {
      // precioUnitario += unitario.precioUnitarioDataPresupuesto
      // cantidad += unitario.cantidadDataPresupuesto
      if (unitario.estatusDataPresupuesto !== 'ANULADO') {
        total +=
          unitario.cantidadDataPresupuesto *
          unitario.precioUnitarioDataPresupuesto
      }
    }

    return formatCurrency(total)
  }

  const valuacionTotalDataPresupuestoSuma = () => {
    // let cantidad = 0
    // let precioUnitario = 0
    let total = 0
    for (const unitario of dataFintrado) {
      // precioUnitario += unitario.precioUnitarioDataPresupuesto
      // cantidad += unitario.cantidadDataPresupuesto
      total +=
        unitario.cantidadDataPresupuesto *
        unitario.valuacionPrecioUnitarioDataPresupuesto
    }

    return formatCurrency(total)
  }
  const pendienteTotalDataPresupuestoSuma = () => {
    // let cantidad = 0
    // let precioUnitario = 0
    let total = 0
    let total1 = 0
    for (const unitario of dataFintrado) {
      // precioUnitario += unitario.precioUnitarioDataPresupuesto
      // cantidad += unitario.cantidadDataPresupuesto
      total +=
        unitario.cantidadDataPresupuesto *
        unitario.valuacionPrecioUnitarioDataPresupuesto
      total1 +=
        unitario.cantidadDataPresupuesto *
        unitario.precioUnitarioDataPresupuesto
    }

    return formatCurrency(total1 - total)
  }
  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Abonado:" footerStyle={{ textAlign: 'right' }} />
        <Column footer={valuacionTotalDataPresupuestoSuma} />
        <Column footer="Pendiente:" footerStyle={{ textAlign: 'right' }} />
        <Column footer={pendienteTotalDataPresupuestoSuma} />
        <Column
          footer="Total:"
          colSpan={3}
          footerStyle={{ textAlign: 'right' }}
        />
        <Column footer={totalDataPresupuestoSuma} />
      </Row>
    </ColumnGroup>
  )
  return (
    <>
      <Toast ref={toast} />

      <DataTable
        ref={dt}
        value={dataFintrado}
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Muestra {first} a {last} de {totalRecords} Data Presupuesto"
        globalFilter={globalFilter}
        emptyMessage="No hay datos."
        header={header}
        sortField="barcoCreado"
        sortOrder={-1}
        loading={loading}
        responsiveLayout="scroll"
        breakpoint="960px"
        footerColumnGroup={footerGroup}
      >
        <Column field="nombreDataPresupuesto" header="Nombre" />
        <Column field="descripcionDataPresupuesto" header="Descripcion" />
        <Column field="cantidadDataPresupuesto" header="Cantidad" />
        <Column
          field="valuacionCantidadDataPresupuesto"
          header="Valuacion Cantidad"
        />
        <Column
          field="activity"
          header="Valuacion Cantidad"
          showFilterMatchModes={false}
          style={{ minWidth: '12rem' }}
          body={activityBodyTemplate}
        />
        <Column
          field="precioUnitarioDataPresupuesto"
          header="Precio Unitario"
          body={precioUnitarioBodyTemplate}
        />
        <Column
          field="activity"
          header="Valuacion Precio"
          showFilterMatchModes={false}
          style={{ minWidth: '12rem' }}
          body={valuacionPrecioBodyTemplate}
        />
        <Column
          field="totalDataPresupuesto"
          header="Total"
          body={totalBodyTemplate}
        />
        {/* <Column field="avanceDataPresupuesto" header="avanceDataPresupuesto" /> */}
        <Column
          field="fechaInicioDataPresupuesto"
          header="Fecha Inicio"
          dataType="date"
          body={fechafechaInicioDataPresupuesto}
        />
        <Column
          field="fechaFinalDataPresupuesto"
          header="Fecha Final"
          dataType="date"
          body={fechafechaFinalDataPresupuesto}
        />
        <Column field="estatusDataPresupuesto" header="Estatus" />
      </DataTable>
    </>
  )
}

export default HomeDataPresupuestoListRender
