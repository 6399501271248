import IngresoGastoContextProvider from '../../administracion/contexts/IngresoGastoContext'
import VolumetriaContextProvider from '../../Control/contexts/VolumetriaContext'
import HomeInfo from '../components/HomeInfo'

export const ReporteGerenciaHomePages = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <VolumetriaContextProvider>
          <IngresoGastoContextProvider>
            <HomeInfo />
          </IngresoGastoContextProvider>
        </VolumetriaContextProvider>{' '}
      </div>
    </div>
  )
}
