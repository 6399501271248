/* eslint-disable indent */
import React, { useContext, useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { PresupuestoContext } from '../contexts/PresupuestoContext'
import { WhatsappContext } from '../contexts/WhatsappContext'
import moment from 'moment'
import emailjs from '@emailjs/browser'
import PresupuestoForm from './PresupuestoForm'
import AuthUse from '../../../auth/AuthUse'
import { Tag } from 'primereact/tag'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import HomeDataPresupuestoListRender from './HomeDataPresupuestoListRender'
import { DataPresupuestoContext } from '../contexts/DataPresupuestoContext'

const HomePresupuestoList = () => {
  const auth = AuthUse()
  // const token = auth.user.token
  const { presupuestos, updatePresupuesto, loading } =
    useContext(PresupuestoContext)
  const { subProyectos } = useContext(SubProyectoContext)
  const { dataPresupuestos } = useContext(DataPresupuestoContext)

  const { createWhatsapp } = useContext(WhatsappContext)
  const [presupuesto, setPresupuesto] = useState(presupuestos)
  const [aprobadoPresupuesto, setAprobadoPresupuesto] = useState(null)
  const [searchPresupuesto, setSearchPresupuesto] = useState(null)
  const [rechazarPresupuesto, setRechazarPresupuesto] = useState(null)
  const [someterAprobadoPresupuesto, setSometerAprobadoPresupuesto] =
    useState(null)
  const [deleteBarcoDialog, setDeleteBarcoDialog] = useState(false)
  const [presupuestoSearchDialog, setPresupuestoSearchDialog] = useState(false)
  const [rechazarPresupuestoDialog, setRechazarPresupuestoDialog] =
    useState(false)
  const [
    somerteAprobadoPresupuestoDialog,
    setSomerteAprobadoPresupuestoDialog
  ] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [displayDetalleCarga, setDisplayDetalleCarga] = useState(false)
  const [dataFintrado, setDataFitrado] = useState()
  const dt = useRef(null)
  const toast = useRef(null)

  useEffect(
    () => {
      const rol = auth.user.faidUser.roles[0]
      let presupuestosFilter = ''
      if (rol === 'SUPERADMIN') {
        presupuestosFilter = presupuestos.filter(
          (p) =>
            p.estatusPresupuesto === 'POR APROBAR' ||
            p.estatusPresupuesto === 'ELABORADO' ||
            p.estatusPresupuesto === 'RECHAZADO'
        )
      } else if (rol === 'ADMIN') {
        presupuestosFilter = presupuestos.filter(
          (p) => p.estatusPresupuesto === 'POR APROBAR'
        )
      } else {
        presupuestosFilter = presupuestos.filter(
          (p) =>
            p.estatusPresupuesto === 'ELABORADO' ||
            p.estatusPresupuesto === 'RECHAZADO'
        )
      }

      setPresupuesto(presupuestosFilter)
    },
    // setDataPresupuesto(dataPresupuestos)
    [presupuestos]
  )
  const enviarCorreoSometerAprobacion = () => {
    const templateParams = {
      name: 'James',
      notes: 'Check this out!',
      from_name: 'App Saltarrana',
      to_name: 'Alejandro Perez',
      message: 'aqui va el cuerpo del correo a ver q tal se ve',

      fechaInicioPresupuesto: moment(
        someterAprobadoPresupuesto.fechaInicioPresupuesto
      ).format('DD/MM/YY'),
      fechaFinalPresupuesto: moment(
        someterAprobadoPresupuesto.fechaFinalPresupuesto
      ).format('DD/MM/YY'),
      nombrePresupuesto: someterAprobadoPresupuesto.nombrePresupuesto,
      totalPresupuesto: formatCurrency(
        someterAprobadoPresupuesto.totalPresupuesto
      ),
      subProyectoId: someterAprobadoPresupuesto.subProyectoId.nombreSubProyecto
    }

    emailjs
      .send(
        'service_gznadtw',
        'template_3zz703p',
        templateParams,
        '0sIlMgGjb4iAHCyGd'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text)
        },
        (err) => {
          console.log('FAILED...', err)
        }
      )
  }
  const enviarCorreoAprobacion = () => {
    const templateParams = {
      name: 'James',
      notes: 'Check this out!',
      from_name: 'App Saltarrana',
      to_name: 'Alejandro Perez',
      message: 'aqui va el cuerpo del correo a ver q tal se ve',

      fechaAprobacionPresupuesto: moment().format('DD/MM/YY'),
      fechaFinalPresupuesto: moment(
        aprobadoPresupuesto.fechaFinalPresupuesto
      ).format('DD/MM/YY'),
      nombrePresupuesto: aprobadoPresupuesto.nombrePresupuesto,
      totalPresupuesto: formatCurrency(aprobadoPresupuesto.totalPresupuesto),
      subProyectoId: aprobadoPresupuesto.subProyectoId.nombreSubProyecto,
      nombreAprobo: auth.user.faidUser.nombre
    }

    emailjs
      .send(
        'service_gznadtw',
        'template_od1hwnr',
        templateParams,
        '0sIlMgGjb4iAHCyGd'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text)
        },
        (err) => {
          console.log('FAILED...', err)
        }
      )
  }
  const enviarCorreoRechazado = () => {
    const templateParams = {
      name: 'James',
      notes: 'Check this out!',
      from_name: 'App Saltarrana',
      to_name: 'Alejandro Perez',
      message: 'aqui va el cuerpo del correo a ver q tal se ve',

      fechaRechazadoPresupuesto: moment().format('DD/MM/YY'),
      fechaFinalPresupuesto: moment(
        rechazarPresupuesto.fechaFinalPresupuesto
      ).format('DD/MM/YY'),
      nombrePresupuesto: rechazarPresupuesto.nombrePresupuesto,
      totalPresupuesto: formatCurrency(rechazarPresupuesto.totalPresupuesto),
      subProyectoId: rechazarPresupuesto.subProyectoId.nombreSubProyecto,
      nombreRechazado: auth.user.faidUser.nombre
    }

    emailjs
      .send(
        'service_gznadtw',
        'template_qlt5g5n',
        templateParams,
        '0sIlMgGjb4iAHCyGd'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text)
        },
        (err) => {
          console.log('FAILED...', err)
        }
      )
  }
  const somerteAprobarPresupuesto = () => {
    enviarCorreoSometerAprobacion()
    updatePresupuesto({
      id: someterAprobadoPresupuesto.id,
      estatusPresupuesto: 'POR APROBAR'
    })
    setSomerteAprobadoPresupuestoDialog(false)
    toast.current.show({
      severity: 'warn',
      summary: 'Por Aprobar',
      detail: 'Presupuesto por Aprobar',
      life: 3000
    })

    enviarWhatssap2()
  }
  const rechazarPresupuestoEstatus = () => {
    // enviarCorreoSometerAprobacion()
    updatePresupuesto({
      id: rechazarPresupuesto.id,
      estatusPresupuesto: 'RECHAZADO'
    })
    setRechazarPresupuestoDialog(false)
    toast.current.show({
      severity: 'error',
      summary: 'Rechazado',
      detail: 'Presupuesto Rechazado',
      life: 3000
    })
    enviarCorreoRechazado()
  }
  const enviarWhatssap2 = async () => {
    const toValor = '584242422547'
    const nameValor = 'aprobar'
    const textValor = 'Alejandro Perez'
    createWhatsapp(toValor, nameValor, textValor)
    // const bearerToken =
    //   'EAAKE4c9SP9EBAG2xa2FnNpyxHH0qodAsMCXI32jLfZA2mN0R23I8fIz95QbeBZByVBLMJbiTtd5BM1ZBIXwd5TCrP9BXKJqiEMlkRHrmvZAB4vid6SHLyrabKLiGEJQw0Eph9HCuWrlHyzootMh5rvr7m12P7wpOotOFaSl57yGJZCcVZAYvyCwXzxCaHJ73fBOfRsfmpLkAZDZD'
    // const url = 'https://graph.facebook.com/v15.0/106517722367572/messages'
    // const data = {
    //   messaging_product: 'whatsapp',
    //   to: '584125633028',
    //   type: 'template',
    //   template: {
    //     name: 'aprobar',
    //     language: {
    //       code: 'es'
    //     },
    //     components: [
    //       {
    //         type: 'header',
    //         parameters: [
    //           {
    //             type: 'text',
    //             text: 'Alfredo Castillo'
    //           }
    //         ]
    //       }
    //     ]
    //   }
    // }
    // // const data = {
    // //   messaging_product: 'whatsapp',
    // //   recipient_type: 'individual',
    // //   to: '584248286102',
    // //   type: 'text',
    // //   text: {
    // //     // the text object
    // //     preview_url: false,
    // //     body: 'MESSAGE_CONTENT'
    // //   }
    // // }
    // const config = {
    //   headers: {
    //     authorization: 'Bearer ' + bearerToken,
    //     'Content-Type': 'application/json'
    //   }
    // }

    // return axios
    //   .post(url, data, config)
    //   .then((data) => {
    //     return data.json()
    //   })
    //   .then((res) => {
    //     console.log(res)
    //   })
    //   .catch((error) => console.log(error))
  }

  const aprobarPresupuesto = () => {
    updatePresupuesto({
      id: aprobadoPresupuesto.id,
      estatusPresupuesto: 'APROBADO'
    })
    setDeleteBarcoDialog(false)
    toast.current.show({
      severity: 'success',
      summary: 'Aprobado',
      detail: 'Presupuesto Aprobado',
      life: 3000
    })
    enviarCorreoAprobacion()
    // enviarWhatssap()
  }

  const setSomerteAprobadoPresupuestoDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-danger p-button-raised"
        onClick={() => setSomerteAprobadoPresupuestoDialog(false)}
      />

      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-success p-button-raised"
        onClick={() => somerteAprobarPresupuesto()}
      />
    </>
  )
  const setSearchDialogFooter = (
    <>
      <Button
        label="Salir"
        icon="pi pi-times"
        className="p-button-danger p-button-raised"
        onClick={() => setPresupuestoSearchDialog(false)}
      />
    </>
  )
  const rechazarPresupuestoDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-danger p-button-raised"
        onClick={() => setRechazarPresupuestoDialog(false)}
      />

      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-success p-button-raised"
        onClick={() => rechazarPresupuestoEstatus()}
      />
    </>
  )
  const deleteBarcoDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-danger p-button-raised"
        onClick={() => setDeleteBarcoDialog(false)}
      />

      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-success p-button-raised"
        onClick={() => aprobarPresupuesto()}
      />
    </>
  )
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Salir"
          icon="pi pi-check"
          onClick={() => setDisplayDetalleCarga(false)}
          autoFocus
        />
      </div>
    )
  }
  const confirmAprobadoPresupueso = (barcos) => {
    setAprobadoPresupuesto(barcos)
    setDeleteBarcoDialog(true)
  }
  const searchPresupueso = (barcos) => {
    setSearchPresupuesto(barcos)
    const dataPresupuesto = dataPresupuestos.filter(
      (p) => p.presupuestoId?.id === barcos.id
    )
    setDataFitrado(dataPresupuesto)
    setPresupuestoSearchDialog(true)
  }
  const reachazarPresupuesto = (datoFila) => {
    setRechazarPresupuesto(datoFila)
    setRechazarPresupuestoDialog(true)
  }
  const someterAprobadoPresupueso = (barcos) => {
    setSometerAprobadoPresupuesto(barcos)
    setSomerteAprobadoPresupuestoDialog(true)
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-search"
          className="p-button-rounded p-button-raised p-button-text p-button-success mb-1"
          onClick={() => searchPresupueso(rowData)}
          tooltip="Mostrar Detalle"
          tooltipOptions={{ position: 'top' }}
        />

        {rowData.estatusPresupuesto === 'ELABORADO' && (
          <Button
            icon="pi pi-cloud-upload"
            className="p-button-rounded  p-button-warning p-button-text p-button-raised"
            onClick={() => someterAprobadoPresupueso(rowData)}
            tooltip="Someter para aprobacion"
            tooltipOptions={{ position: 'top' }}
          />
        )}
        {rowData.estatusPresupuesto === 'POR APROBAR' && (
          <>
            <Button
              icon="pi pi-check-circle"
              className="p-button-rounded  p-button-success"
              onClick={() => confirmAprobadoPresupueso(rowData)}
              tooltip="Aprobar Presupuesto"
              tooltipOptions={{ position: 'top' }}
            />
            <Button
              icon="pi pi-check-circle"
              className="p-button-rounded  p-button-danger"
              onClick={() => reachazarPresupuesto(rowData)}
              tooltip="Rechazar Presupuesto"
              tooltipOptions={{ position: 'top' }}
            />
          </>
        )}
      </div>
    )
  }

  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }

  const clearSelected = () => {
    setDeleteBarcoDialog(false)
    setSomerteAprobadoPresupuestoDialog(false)
    setRechazarPresupuestoDialog(false)
    setPresupuestoSearchDialog(false)
    setRechazarPresupuestoDialog(false)
  }
  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.estatusPresupuesto}
        severity={getSeverity(rowData.estatusPresupuesto)}
      />
    )
  }
  const prioridadBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.prioridadPresupuesto}
        severity={getSeverity(rowData.prioridadPresupuesto)}
      />
    )
  }
  const proyectoBodyTemplate = (rowData) => {
    const nombreProyrecto = subProyectos.find(
      (p) => p.id === rowData.subProyectoId.id
    )
    return (
      <Tag
        value={nombreProyrecto?.proyectoId.nombreProyecto}
        // severity={getSeverity(rowData.estatusPresupuesto)}
      />
    )
  }
  const getSeverity = (estatusPresupuesto) => {
    switch (estatusPresupuesto) {
      case 'RECHAZADO':
        return 'danger'
      case 'ALTA':
        return 'danger'
      case 'MEDIA':
        return 'warning'
      case 'BAJA':
        return 'success'

      case 'POR APROBAR':
        return 'success'

      case 'new':
        return 'info'

      case 'ELABORADO':
        return 'warning'

      case 'renewal':
        return null
    }
  }
  return (
    <>
      <Toast ref={toast} />

      <DataTable
        ref={dt}
        value={presupuesto}
        dataKey="id"
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Muestra {first} a {last} de {totalRecords} Presupuesto"
        emptyMessage="No hay datos."
        sortField="updatedAt"
        sortOrder={-1}
        loading={loading}
        responsiveLayout="scroll"
        breakpoint="960px"
      >
        <Column body={actionBodyTemplate}></Column>

        <Column
          //  field="estatusPresupuesto"
          header="Proyecto"
          // sortable
          body={proyectoBodyTemplate}
        />
        <Column
          field="totalPresupuesto"
          header="Total"
          body={(data) => formatCurrency(data.totalPresupuesto)}
          sortable
        />
        <Column
          field="subProyectoId.nombreSubProyecto"
          header="Sub Proyecto"
          sortable
        />
        <Column field="nombrePresupuesto" header="Nombre" sortable />

        <Column
          field="prioridadPresupuesto"
          header="Prioridad"
          sortable
          body={prioridadBodyTemplate}
        />
        <Column
          field="estatusPresupuesto"
          header="Estatus"
          sortable
          body={statusBodyTemplate}
        />
      </DataTable>

      <PresupuestoForm isVisible={isVisible} setIsVisible={setIsVisible} />

      <Dialog
        visible={deleteBarcoDialog}
        style={{ width: '450px' }}
        header="Confirm"
        modal
        footer={deleteBarcoDialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-check-circle mr-3" style={{ fontSize: '2rem' }} />
          {aprobadoPresupuesto && (
            <span>
              Esta seguro que quiere aprobar el presupuesto{' '}
              <b>{aprobadoPresupuesto.nombrePresupuesto}</b> por{' '}
              <b>{formatCurrency(aprobadoPresupuesto.totalPresupuesto)}</b>?
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={rechazarPresupuestoDialog}
        style={{ width: '450px' }}
        header="Confirm"
        modal
        footer={rechazarPresupuestoDialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-check-circle mr-3" style={{ fontSize: '2rem' }} />
          {rechazarPresupuesto && (
            <span>
              Esta seguro que quiere rechazar el presupuesto{' '}
              <b>{rechazarPresupuesto.nombrePresupuesto}</b> por{' '}
              <b>{formatCurrency(rechazarPresupuesto.totalPresupuesto)}</b>?
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={somerteAprobadoPresupuestoDialog}
        style={{ width: '450px' }}
        header="Confirm"
        modal
        footer={setSomerteAprobadoPresupuestoDialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-check-circle mr-3" style={{ fontSize: '2rem' }} />
          {someterAprobadoPresupuesto && (
            <span>
              Esta seguro que quiere someter por aprobacion el presupuesto{' '}
              <b>{someterAprobadoPresupuesto.nombrePresupuesto}</b> por{' '}
              <b>
                {formatCurrency(someterAprobadoPresupuesto.totalPresupuesto)}
              </b>
              ?
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={presupuestoSearchDialog}
        style={{ width: '450px' }}
        header="Presupuesto"
        modal
        footer={setSearchDialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="flex align-items-center justify-content-center">
          {searchPresupuesto && (
            <span className="block text-600 font-medium mb-1">
              <span className="block text-900 font-medium mb-1 text-center">
                <strong>
                  {searchPresupuesto.nombrePresupuesto.toUpperCase()}
                </strong>
                <Button
                  icon="pi pi-search"
                  rounded
                  severity="success"
                  aria-label="Search"
                  onClick={() => setDisplayDetalleCarga(true)}
                  tooltip="Ver Detalles"
                  tooltipOptions={{ position: 'top' }}
                />
              </span>

              <div className="my-2">
                <h6 className="card-text mt-0 mb-2">
                  Fecha Inicio:
                  <span className="text-green-500 font-medium">
                    {' '}
                    {moment(
                      searchPresupuesto.fechaInicioPresupuesto
                    ).isValid() &&
                      moment(searchPresupuesto.fechaInicioPresupuesto).format(
                        'dddDD/MM/YY HH:mm'
                      )}
                  </span>
                </h6>
                <h6 className="card-text mt-0 mb-2">
                  Fecha Fin:
                  <span className="text-orange-500 font-medium">
                    {' '}
                    {moment(
                      searchPresupuesto.fechaFinalPresupuesto
                    ).isValid() &&
                      moment(searchPresupuesto.fechaFinalPresupuesto).format(
                        'dddDD/MM/YY HH:mm'
                      )}
                  </span>
                </h6>
              </div>
              <div className="card">
                <img
                  src={
                    searchPresupuesto.image
                      ? searchPresupuesto.image.url
                      : 'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png'
                  }
                  onError={(e) =>
                    (e.target.src =
                      'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png')
                  }
                  alt={searchPresupuesto.image}
                  className="product-image"
                  height="400px"
                  width={'400px'}
                />
              </div>
            </span>
          )}
        </div>
      </Dialog>
      <Dialog
        // header={`Detalles de ${presupuestos.nombrePresupuesto.toUpperCase()}`}
        visible={displayDetalleCarga}
        onHide={() => setDisplayDetalleCarga(false)}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '75vw' }}
        footer={renderFooter('displayDetalleCarga')}
      >
        {dataFintrado && (
          <HomeDataPresupuestoListRender dataFintrado={dataFintrado} />
        )}
      </Dialog>
    </>
  )
}

export default HomePresupuestoList
