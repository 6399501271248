import HomeList from '../components/HomeList'
import AbonoPresupuestoContextProvider from '../contexts/AbonoPresupuestoContext'
import AvancePresupuestoContextProvider from '../contexts/AvancePresupuestoContext'
import ComentarioSubProyectoContextProvider from '../contexts/ComentarioSubProyectoContext'
import DataPresupuestoContextProvider from '../contexts/DataPresupuestoContext'
import PresupuestoContextProvider from '../contexts/PresupuestoContext'
import ProveedorContextProvider from '../contexts/ProveedorContext'
import ProyectoContextProvider from '../contexts/ProyectoContext'
import SolicitudFondoContextProvider from '../contexts/SolicitudFondoContext'
import SubProyectoContextProvider from '../contexts/SubProyectoContext'
import WhatsappContextProvider from '../contexts/WhatsappContext'

export const HomePage = () => {
  return (
    <div className="grid">
      <SolicitudFondoContextProvider>
        <ProyectoContextProvider>
          <SubProyectoContextProvider>
            <ComentarioSubProyectoContextProvider>
              <PresupuestoContextProvider>
                <DataPresupuestoContextProvider>
                  <WhatsappContextProvider>
                    <ProveedorContextProvider>
                      <AbonoPresupuestoContextProvider>
                        <AvancePresupuestoContextProvider>
                          <HomeList />
                        </AvancePresupuestoContextProvider>
                      </AbonoPresupuestoContextProvider>
                    </ProveedorContextProvider>
                  </WhatsappContextProvider>
                </DataPresupuestoContextProvider>
              </PresupuestoContextProvider>
            </ComentarioSubProyectoContextProvider>
          </SubProyectoContextProvider>
        </ProyectoContextProvider>{' '}
      </SolicitudFondoContextProvider>
    </div>
  )
}
