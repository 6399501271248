/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'
import { SubProyectoService } from '../services/SubProyectoService'
import AuthUse from '../../../auth/AuthUse'
// import { CargaBodegaService } from '../services/CargaBodegaService'
export const SubProyectoContext = createContext()

const SubProyectoContextProvider = (props) => {
  const subProyectoService = new SubProyectoService()
  // const cargaBodegaService = new CargaBodegaService()

  const [subProyectos, setSubProyectos] = useState([])
  const [createBodegaSubProyecto1, setCreateBodegaSubProyecto1] = useState([])
  const [subProyectocargando, setSubProyectocargando] = useState(null)
  const [editSubProyecto, setEditSubProyecto] = useState(null)
  const [loading, setLoading] = useState(true)
  const [actuallizar, setActuallizar] = useState(null)

  const auth = AuthUse()
  const token = auth.user.token

  useEffect(() => {
    subProyectoService.readAll(token).then((data) => {
      setSubProyectos(data)
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      subProyectoService.readAll(token).then((data) => {
        setSubProyectos(data)
        setLoading(false)
      })
      setActuallizar(actuallizar + 1)
    }, 600000)
    return () => {
      clearInterval(interval)
    }
  }, [actuallizar])

  useEffect(() => {
    const subProyecto = subProyectos.find(
      (p) => p.estatusSubProyecto === 'OPERATIVO'
    )
    setSubProyectocargando(subProyecto)
  }, [subProyectos])

  const findSubProyecto = (id) => {
    const subProyecto = subProyectos.find((p) => p.id === id)
    setEditSubProyecto(subProyecto)
  }

  const createSubProyecto = (subProyecto) => {
    subProyectoService.create(subProyecto, token).then((data) => {
      setSubProyectos([...subProyectos, data.saveSubProyecto])
      createBodegaSubProyecto(data.saveSubProyecto)
      setCreateBodegaSubProyecto1(data.saveSubProyecto)
    })
  }
  const createBodegaSubProyecto = (saveSubProyecto) => {
    const cargaBodega = {
      subProyectoID: saveSubProyecto.id,
      nombreBodega: '',
      estatusCargaBodega: 'CARGANDO',
      toneladasCargadasBodega: 0,
      toneladasCapacidadBodega: 0
    }

    // let i = 1

    // do {
    //   cargaBodega.nombreBodega = `BODEGA ${i}`
    //   cargaBodegaService.create(cargaBodega, token).then(i++)
    // } while (i <= saveSubProyecto.cantidadBodegas)
    for (let i = 1; i <= saveSubProyecto.cantidadBodegas; i++) {
      cargaBodega.nombreBodega = `BODEGA ${i}`
    }
    // subProyectoService.create(CargaBodega, token).then((data) => {
    //   setSubProyectos([...subProyectos, data.saveSubProyecto])
    //   console.log('subProyecto creado', data.saveSubProyecto)
    // })
  }

  const updateSubProyecto = (subProyecto) => {
    subProyectoService
      .update(subProyecto, token)
      .then((data) =>
        setSubProyectos(
          subProyectos.map((p) =>
            p.id === subProyecto.id ? data.updateSubProyecto : p
          )
        )
      )
    setEditSubProyecto(null)
  }
  const deleteSubProyecto = (id) => {
    subProyectoService
      .delete(id, token)
      .then(() => setSubProyectos(subProyectos.filter((p) => p.id !== id)))
  }

  return (
    <SubProyectoContext.Provider
      value={{
        findSubProyecto,
        createSubProyecto,
        updateSubProyecto,
        deleteSubProyecto,
        editSubProyecto,
        subProyectocargando,
        subProyectos,
        loading,
        createBodegaSubProyecto1
      }}
    >
      {props.children}
    </SubProyectoContext.Provider>
  )
}

export default SubProyectoContextProvider
