import axios from 'axios'

export class ComentarioSubProyectoService {
  // baseUrl = "http://localhost:8080/api/comentarioSubProyectos/";
  // baseUrl = '  https://apimaroil.herokuapp.com/api/'
  // baseUrl = "http://10.20.40.159:4000/api/";
  // baseUrl = "https://hg-rest-api.herokuapp.com/api/comentarioSubProyectos/";
  baseUrl = 'https://apisaltarrana.herokuapp.com/api/'

  // baseUrl = 'https://apimaroil.herokuapp.com/api/'
  readAll(token) {
    const config = {
      headers: {
        authorization: token
      }
    }
    return axios
      .get(this.baseUrl + 'comentarioSubProyecto/', config)
      .then((res) => res.data)
  }

  create(comentarioSubProyecto, token) {
    const config = {
      headers: {
        authorization: token,
        'Content-Type': 'multipart/form-data'
      }
    }
    const form = new FormData()
    const { selectedImages } = comentarioSubProyecto
    for (const key in comentarioSubProyecto) {
      form.append(key, comentarioSubProyecto[key])
    }
    for (const image of selectedImages) {
      form.append('imagenUrlComentario', image)
    }
    return axios
      .post(this.baseUrl + 'comentarioSubProyecto/', form, config)
      .then((res) => res.data)
  }

  update(comentarioSubProyecto, token) {
    const config = {
      headers: {
        authorization: token,
        'Content-Type': 'multipart/form-data'
      }
    }
    const form = new FormData()
    const { selectedImages } = comentarioSubProyecto
    for (const key in comentarioSubProyecto) {
      form.append(key, comentarioSubProyecto[key])
    }
    for (const image of selectedImages) {
      form.append('imagenUrlComentario', image)
    }
    return axios
      .put(
        this.baseUrl + 'comentarioSubProyecto/' + comentarioSubProyecto.id,
        form,
        config
      )
      .then((res) => res.data)
  }

  delete(id, token) {
    const config = {
      headers: {
        authorization: token
      }
    }
    return axios
      .delete(this.baseUrl + 'comentarioSubProyecto/' + id, config)
      .then((res) => res.data)
  }
}
