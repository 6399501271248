/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'
import { AbonoPresupuestoService } from '../services/AbonoPresupuestoService'
import AuthUse from '../../../auth/AuthUse'
// import { CargaBodegaService } from '../services/CargaBodegaService'
export const AbonoPresupuestoContext = createContext()

const AbonoPresupuestoContextProvider = (props) => {
  const abonoPresupuestoService = new AbonoPresupuestoService()
  // const cargaBodegaService = new CargaBodegaService()

  const [abonoPresupuestos, setAbonoPresupuestos] = useState([])
  const [createBodegaAbonoPresupuesto1, setCreateBodegaAbonoPresupuesto1] =
    useState([])
  const [abonoPresupuestocargando, setAbonoPresupuestocargando] = useState(null)
  const [editAbonoPresupuesto, setEditAbonoPresupuesto] = useState(null)
  const [loading, setLoading] = useState(true)
  const [actuallizar, setActuallizar] = useState(null)

  const auth = AuthUse()
  const token = auth.user.token

  useEffect(() => {
    abonoPresupuestoService.readAll(token).then((data) => {
      setAbonoPresupuestos(data)
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      abonoPresupuestoService.readAll(token).then((data) => {
        setAbonoPresupuestos(data)
        setLoading(false)
      })
      setActuallizar(actuallizar + 1)
    }, 600000)
    return () => {
      clearInterval(interval)
    }
  }, [actuallizar])

  useEffect(() => {
    const abonoPresupuesto = abonoPresupuestos.find(
      (p) => p.estatusAbonoPresupuesto === 'OPERATIVO'
    )
    setAbonoPresupuestocargando(abonoPresupuesto)
  }, [abonoPresupuestos])

  const findAbonoPresupuesto = (id) => {
    const abonoPresupuesto = abonoPresupuestos.find((p) => p.id === id)
    setEditAbonoPresupuesto(abonoPresupuesto)
  }

  const createAbonoPresupuesto = (abonoPresupuesto) => {
    abonoPresupuestoService.create(abonoPresupuesto, token).then((data) => {
      setAbonoPresupuestos([...abonoPresupuestos, data.saveAbonoPresupuesto])
      createBodegaAbonoPresupuesto(data.saveAbonoPresupuesto)
      setCreateBodegaAbonoPresupuesto1(data.saveAbonoPresupuesto)
    })
  }
  const createBodegaAbonoPresupuesto = (saveAbonoPresupuesto) => {
    const cargaBodega = {
      abonoPresupuestoID: saveAbonoPresupuesto.id,
      nombreBodega: '',
      estatusCargaBodega: 'CARGANDO',
      toneladasCargadasBodega: 0,
      toneladasCapacidadBodega: 0
    }

    // let i = 1

    // do {
    //   cargaBodega.nombreBodega = `BODEGA ${i}`
    //   cargaBodegaService.create(cargaBodega, token).then(i++)
    // } while (i <= saveAbonoPresupuesto.cantidadBodegas)
    for (let i = 1; i <= saveAbonoPresupuesto.cantidadBodegas; i++) {
      cargaBodega.nombreBodega = `BODEGA ${i}`
    }
    // abonoPresupuestoService.create(CargaBodega, token).then((data) => {
    //   setAbonoPresupuestos([...abonoPresupuestos, data.saveAbonoPresupuesto])
    //   console.log('abonoPresupuesto creado', data.saveAbonoPresupuesto)
    // })
  }

  const updateAbonoPresupuesto = (abonoPresupuesto) => {
    abonoPresupuestoService
      .update(abonoPresupuesto, token)
      .then((data) =>
        setAbonoPresupuestos(
          abonoPresupuestos.map((p) =>
            p.id === abonoPresupuesto.id ? data.updateAbonoPresupuesto : p
          )
        )
      )
    setEditAbonoPresupuesto(null)
  }
  const deleteAbonoPresupuesto = (id) => {
    abonoPresupuestoService
      .delete(id, token)
      .then(() =>
        setAbonoPresupuestos(abonoPresupuestos.filter((p) => p.id !== id))
      )
  }

  return (
    <AbonoPresupuestoContext.Provider
      value={{
        findAbonoPresupuesto,
        createAbonoPresupuesto,
        updateAbonoPresupuesto,
        deleteAbonoPresupuesto,
        editAbonoPresupuesto,
        abonoPresupuestocargando,
        abonoPresupuestos,
        loading,
        createBodegaAbonoPresupuesto1
      }}
    >
      {props.children}
    </AbonoPresupuestoContext.Provider>
  )
}

export default AbonoPresupuestoContextProvider
