/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { DataPresupuestoContext } from '../contexts/DataPresupuestoContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { addLocale } from 'primereact/api'
import { PresupuestoContext } from '../contexts/PresupuestoContext'

import moment from 'moment'
import { ProyectoContext } from '../contexts/ProyectoContext'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { classNames } from 'primereact/utils'
import { InputTextarea } from 'primereact/inputtextarea'

const ActivoForm = (props) => {
  const initialDataPresupuestoForm = {
    id: null,
    nombreDataPresupuesto: '',
    descripcionDataPresupuesto: '',
    totalDataPresupuesto: 0,
    cantidadDataPresupuesto: 0,
    unidadDataPresupuesto: '',
    valuacionCantidadDataPresupuesto: 0,
    precioUnitarioDataPresupuesto: 0,
    valuacionPrecioUnitarioDataPresupuesto: 0,
    avanceDataPresupuesto: 0,
    fechaInicioDataPresupuesto: '',
    fechaFinalDataPresupuesto: '',
    estatusDataPresupuesto: '',
    creadoDataPresupuesto: moment(),
    modificadoDataPresupuesto: moment()
  }

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  const { createDataPresupuesto, editDataPresupuesto, updateDataPresupuesto } =
    useContext(DataPresupuestoContext)
  const { presupuestos } = useContext(PresupuestoContext)
  const { subProyectos } = useContext(SubProyectoContext)
  const { proyectos } = useContext(ProyectoContext)
  const {
    isVisible,
    setIsVisible,
    selectedPresupuesto1,
    selectedSubProyecto1,
    selectedProyecto1
  } = props

  const [selectedDataPresupuesto, setSelectedDataPresupuesto] = useState(null)
  const [selectedUnidadDataPresupuesto, setSelectedUnidadDataPresupuesto] =
    useState(null)
  const [subProyecto, setSubProyecto] = useState(subProyectos)
  const [presupuesto, setPresupuesto] = useState(presupuestos)
  const [selectedPresupuesto, setSelectedPresupuesto] =
    useState(selectedPresupuesto1)
  const [submitted, setSubmitted] = useState(false)
  const [selectedProyecto, setSelectedProyecto] = useState(selectedProyecto1)
  const [selectedSubProyecto, setSelectedSubProyecto] =
    useState(selectedSubProyecto1)

  const [DataPresupuestoData, setDataPresupuestoData] = useState(
    initialDataPresupuestoForm
  )
  const estadoDataPresupuesto = [
    { estatusDataPresupuesto: 'OPERATIVO' },
    { estatusDataPresupuesto: 'ANULADO' },
    { estatusDataPresupuesto: 'CULMINADO' }
  ]
  const unidadDataPresupuesto = [
    { unidadDataPresupuesto: 'M' },
    { unidadDataPresupuesto: 'M2' },
    { unidadDataPresupuesto: 'M3' },
    { unidadDataPresupuesto: 'Kg' },
    { unidadDataPresupuesto: 'Sem' },
    { unidadDataPresupuesto: 'Viaje' },
    { unidadDataPresupuesto: 'sco' },
    { unidadDataPresupuesto: 'Kgf' },
    { unidadDataPresupuesto: 'Pza' },
    { unidadDataPresupuesto: 'Und' },
    { unidadDataPresupuesto: 'ML' },
    { unidadDataPresupuesto: 'Sg' }
  ]
  const onEstatusDataPresupuesto = (e) => {
    setSelectedDataPresupuesto(e.value)
    updateField(e.value.estatusDataPresupuesto, 'estatusDataPresupuesto')
  }
  const onUnidadDataPresupuesto = (e) => {
    setSelectedUnidadDataPresupuesto(e.value)
    updateField(e.value.unidadDataPresupuesto, 'unidadDataPresupuesto')
  }
  const onProyecto = (e) => {
    if (e.value) {
      const subProyectoFilter = subProyectos.filter(
        (p) => p.proyectoId?.id === e.value.id
      )
      setSelectedProyecto(e.value)
      setSubProyecto(subProyectoFilter)
    } else {
      setSelectedProyecto(null)
      setSubProyecto(null)
      setSelectedSubProyecto(null)
      setSelectedPresupuesto(null)
    }
  }
  const onSubProyecto = (e) => {
    if (e.value) {
      const presupuestoFilter = presupuestos.filter(
        (p) => p.subProyectoId?.id === e.value.id
      )
      setPresupuesto(presupuestoFilter)
      setSelectedSubProyecto(e.value)
      updateField(e.value.id, 'subProyectoId')
    } else {
      setSelectedSubProyecto(null)
      updateField(null, 'subProyectoId')
    }
  }

  const onPresupuesto = (e) => {
    e.value
      ? (setSelectedPresupuesto(e.value),
        updateField(e.value.id, 'presupuestoId'))
      : (setSelectedPresupuesto(null), updateField(null, 'presupuestoId'))
  }
  const [dateInicio, setDateInicio] = useState(null)
  const [dateFinal, setDateFinal] = useState(null)
  const toast = useRef(null)

  useEffect(() => {
    if (editDataPresupuesto) {
      setDataPresupuestoData({
        ...editDataPresupuesto,
        presupuestoId: editDataPresupuesto.presupuestoId?.id
      })
      setSelectedDataPresupuesto({
        estatusDataPresupuesto: editDataPresupuesto.estatusDataPresupuesto
      })
      const conceptoAuxSelecEdit =
        editDataPresupuesto.conceptoAuxId &&
        presupuestos.find((p) => p.id === editDataPresupuesto.conceptoAuxId.id)

      setSelectedPresupuesto(conceptoAuxSelecEdit)
      setDateInicio(
        editDataPresupuesto.fechaInicioDataPresupuesto &&
          moment(editDataPresupuesto.fechaInicioDataPresupuesto)._d
      )
      setDateFinal(
        editDataPresupuesto.fechaFinalDataPresupuesto &&
          moment(editDataPresupuesto.fechaFinalDataPresupuesto)._d
      )
    }
  }, [editDataPresupuesto])
  useEffect(() => {
    if (!editDataPresupuesto) {
      setDataPresupuestoData({
        ...initialDataPresupuestoForm,
        presupuestoId: selectedPresupuesto1?.id
      })
      setSubProyecto(subProyectos)
      setPresupuesto(presupuestos)
      setSelectedProyecto(selectedProyecto1)
      setSelectedSubProyecto(selectedSubProyecto1)
      setSelectedPresupuesto(selectedPresupuesto1)
    }
  }, [isVisible])
  useEffect(() => {
    setSelectedSubProyecto(selectedSubProyecto1)
  }, [selectedSubProyecto1])

  const updateField = (data, field) => {
    setDataPresupuestoData({
      ...DataPresupuestoData,
      [field]: data
    })
  }

  const saveDataPresupuesto = () => {
    setSubmitted(true)
    if (
      DataPresupuestoData.nombreDataPresupuesto.trim() &&
      DataPresupuestoData.cantidadDataPresupuesto !== null &&
      DataPresupuestoData.unidadDataPresupuesto.trim() &&
      DataPresupuestoData.precioUnitarioDataPresupuesto !== 0 &&
      DataPresupuestoData.estatusDataPresupuesto.trim() &&
      DataPresupuestoData.estatusDataPresupuesto.trim() &&
      DataPresupuestoData.fechaInicioDataPresupuesto !== null &&
      DataPresupuestoData.fechaFinalDataPresupuesto !== null
    ) {
      if (!editDataPresupuesto) {
        createDataPresupuesto(DataPresupuestoData)
      } else {
        updateDataPresupuesto({
          ...DataPresupuestoData,
          DataPresupuestoModificado: moment()
        })
      }
      clearSelected()
    }
  }

  const dialogFooter = (
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => clearSelected()}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        onClick={saveDataPresupuesto}
      />
    </div>
  )

  const clearSelected = () => {
    setIsVisible(false)
    setDataPresupuestoData(initialDataPresupuestoForm)
    setSelectedDataPresupuesto('')
    setSelectedUnidadDataPresupuesto(null)
    setSelectedPresupuesto(null)
    setSelectedProyecto(null)
    setSelectedSubProyecto(null)
    setDateInicio(null)
    setDateFinal(null)
    setSubmitted(false)
  }

  return (
    <div className="dialog-demo">
      <Toast ref={toast} />
      <Dialog
        visible={isVisible}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '50vw' }}
        header="Detalles de la DataPresupuesto"
        footer={dialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="p-grid p-fluid">
          <div className="formgrid grid">
            <div className="field col-12 md:col-6  mt-3">
              <span className="p-float-label">
                <Dropdown
                  inputId="dropdown"
                  value={selectedProyecto}
                  options={proyectos}
                  onChange={onProyecto}
                  optionLabel="nombreProyecto"
                  showClear
                  filter
                  filterBy="nombrePresupuesto"
                  className={classNames({
                    'p-invalid': submitted && !selectedProyecto
                  })}
                />
                {submitted && !selectedProyecto && (
                  <small className="p-invalid">Proyecto es requerido.</small>
                )}
                <label htmlFor="dropdown">Seleccione Proyecto*</label>
              </span>
            </div>
            <div className="field col-12 md:col-6  mt-3 ">
              <span className="p-float-label ">
                <Dropdown
                  value={selectedSubProyecto}
                  options={subProyecto}
                  onChange={onSubProyecto}
                  optionLabel="nombreSubProyecto"
                  showClear
                  filter
                  filterBy="nombrePresupuesto"
                  disabled={!selectedProyecto}
                  className={classNames({
                    'p-invalid': submitted && !DataPresupuestoData.subProyectoId
                  })}
                />
                {submitted && !DataPresupuestoData.subProyectoId && (
                  <small className="p-invalid">
                    Sub Proyecto es requerido.
                  </small>
                )}
                <label htmlFor="dropdown">Seleccione Sub Proyecto*</label>
              </span>
            </div>
            <div className="field col-12 md:col-6 mt-3 ">
              <span className="p-float-label ">
                <Dropdown
                  value={selectedPresupuesto}
                  options={presupuesto}
                  onChange={onPresupuesto}
                  optionLabel="nombrePresupuesto"
                  showClear
                  filter
                  filterBy="nombrePresupuesto"
                  disabled={!selectedSubProyecto}
                  className={classNames({
                    'p-invalid': submitted && !DataPresupuestoData.subProyectoId
                  })}
                />
                <label htmlFor="dropdown">Seleccione Presupuesto*</label>
              </span>
            </div>

            <div className="p-float-label col-12 md:col-6 mt-3 ">
              <InputText
                value={DataPresupuestoData.nombreDataPresupuesto}
                onChange={(e) =>
                  updateField(e.target.value, 'nombreDataPresupuesto')
                }
                className={classNames({
                  'p-invalid':
                    submitted && !DataPresupuestoData.nombreDataPresupuesto
                })}
              />
              {submitted && !DataPresupuestoData.nombreDataPresupuesto && (
                <small className="p-invalid">Nombre es requerido.</small>
              )}
              <label>Nombre:*</label>
            </div>

            <div className="p-float-label col-12 md:col-12 mt-3 ">
              <InputTextarea
                id="description"
                value={DataPresupuestoData.descripcionDataPresupuesto}
                onChange={(e) =>
                  updateField(e.target.value, 'descripcionDataPresupuesto')
                }
                rows={3}
                cols={20}
              />

              <label>Descripción:</label>
            </div>
            <br />
            <div className="field col-6 p-col-2 mt-4">
              <span className="p-float-label ">
                <InputNumber
                  inputId="cantidadDataPresupuesto"
                  value={DataPresupuestoData.cantidadDataPresupuesto}
                  onValueChange={(e) =>
                    updateField(e.target.value, 'cantidadDataPresupuesto')
                  }
                  minFractionDigits={2}
                  maxFractionDigits={5}
                  className={classNames({
                    'p-invalid':
                      submitted && !DataPresupuestoData.cantidadDataPresupuesto
                  })}
                />

                {submitted && !DataPresupuestoData.cantidadDataPresupuesto && (
                  <small className="p-invalid">Cantidad es requerido.</small>
                )}
                <label htmlFor="cantidadDataPresupuesto">Cantidad</label>
              </span>
            </div>
            <div className="field col-12 md:col-6 mt-4">
              <span className="p-float-label ">
                <Dropdown
                  value={selectedUnidadDataPresupuesto}
                  options={unidadDataPresupuesto}
                  onChange={onUnidadDataPresupuesto}
                  optionLabel="unidadDataPresupuesto"
                  // placeholder="Seleccione unidad"
                  className={classNames({
                    'p-invalid':
                      submitted && !DataPresupuestoData.unidadDataPresupuesto
                  })}
                />
                {submitted && !DataPresupuestoData.unidadDataPresupuesto && (
                  <small className="p-invalid">Unidad es requerido.</small>
                )}
                <label>Unidad</label>{' '}
              </span>
            </div>
            <div className="field col-6 mt-4">
              <span className="p-float-label ">
                <InputNumber
                  inputId="valuacionCantidadDataPresupuesto"
                  value={DataPresupuestoData.valuacionCantidadDataPresupuesto}
                  onValueChange={(e) =>
                    updateField(
                      e.target.value,
                      'valuacionCantidadDataPresupuesto'
                    )
                  }
                  minFractionDigits={2}
                  maxFractionDigits={5}
                />

                <label htmlFor="valuacionCantidadDataPresupuesto">
                  Valuación Cantidad
                </label>
              </span>
            </div>
            <div className="field col-6 mt-4">
              <span className="p-float-label ">
                <InputNumber
                  inputId="precioUnitarioDataPresupuesto"
                  value={DataPresupuestoData.precioUnitarioDataPresupuesto}
                  onValueChange={(e) =>
                    updateField(e.target.value, 'precioUnitarioDataPresupuesto')
                  }
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  className={classNames({
                    'p-invalid':
                      submitted &&
                      !DataPresupuestoData.precioUnitarioDataPresupuesto
                  })}
                />
                {submitted &&
                  !DataPresupuestoData.precioUnitarioDataPresupuesto && (
                    <small className="p-invalid">Precio es requerido.</small>
                  )}
                <label htmlFor="precioUnitarioDataPresupuesto">
                  Precio Unitario
                </label>
              </span>
            </div>
            <div className="field col-6 mt-4">
              <span className="p-float-label ">
                <InputNumber
                  inputId="valuacionPrecioUnitarioDataPresupuesto"
                  value={
                    DataPresupuestoData.valuacionPrecioUnitarioDataPresupuesto
                  }
                  onValueChange={(e) =>
                    updateField(
                      e.target.value,
                      'valuacionPrecioUnitarioDataPresupuesto'
                    )
                  }
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                />

                <label htmlFor="valuacionPrecioUnitarioDataPresupuesto">
                  Valuacion Precio Unitario
                </label>
              </span>
            </div>

            <div className="field col-12 md:col-6 mt-3">
              <span className="p-float-label ">
                <Dropdown
                  value={selectedDataPresupuesto}
                  options={estadoDataPresupuesto}
                  onChange={onEstatusDataPresupuesto}
                  optionLabel="estatusDataPresupuesto"
                  className={classNames({
                    'p-invalid':
                      submitted && !DataPresupuestoData.estatusDataPresupuesto
                  })}
                />
                {submitted && !DataPresupuestoData.estatusDataPresupuesto && (
                  <small className="p-invalid">Unidad es requerido.</small>
                )}
                <label>Selecione Estado</label>
              </span>
            </div>

            <div className="field col-12 md:col-6 mt-3">
              <span className="p-float-label ">
                <Calendar
                  // className="p-datepicker-today"
                  id="time24"
                  value={dateInicio !== null && dateInicio}
                  onChange={(e) => {
                    setDateInicio(e.value)
                    updateField(e.target.value, 'fechaInicioDataPresupuesto')
                  }}
                  showTime
                  locale="es"
                  // hourFormat="12"
                  showButtonBar
                  className={classNames(
                    {
                      'p-invalid':
                        submitted &&
                        !DataPresupuestoData.fechaInicioDataPresupuesto
                    },
                    'p-datepicker-today'
                  )}
                />{' '}
                {submitted &&
                  !DataPresupuestoData.fechaInicioDataPresupuesto && (
                    <small className="p-invalid">Fecha es requerido.</small>
                  )}
                <label>Fecha Inicio </label>
              </span>
            </div>
            <div className="field col-12 md:col-6 mt-3">
              <span className="p-float-label ">
                <Calendar
                  // className="p-datepicker-today"
                  id="time24"
                  value={dateFinal !== null && dateFinal}
                  onChange={(e) => {
                    setDateFinal(e.value)
                    updateField(e.target.value, 'fechaFinalDataPresupuesto')
                  }}
                  showTime
                  locale="es"
                  // hourFormat="12"
                  showButtonBar
                  className={classNames(
                    {
                      'p-invalid':
                        submitted &&
                        !DataPresupuestoData.fechaFinalDataPresupuesto
                    },
                    'p-datepicker-today'
                  )}
                />{' '}
                {submitted &&
                  !DataPresupuestoData.fechaFinalDataPresupuesto && (
                    <small className="p-invalid">Fecha es requerido.</small>
                  )}
                <label>Fecha Final</label>
              </span>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ActivoForm
