/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'
import { DataPresupuestoService } from '../services/DataPresupuestoService'
import AuthUse from '../../../auth/AuthUse'
// import { CargaBodegaService } from '../services/CargaBodegaService'
export const DataPresupuestoContext = createContext()

const DataPresupuestoContextProvider = (props) => {
  const dataPresupuestoService = new DataPresupuestoService()
  // const cargaBodegaService = new CargaBodegaService()

  const [dataPresupuestos, setDataPresupuestos] = useState([])
  const [createBodegaDataPresupuesto1, setCreateBodegaDataPresupuesto1] =
    useState([])
  const [dataPresupuestocargando, setDataPresupuestocargando] = useState(null)
  const [editDataPresupuesto, setEditDataPresupuesto] = useState(null)
  const [loading, setLoading] = useState(true)
  const [actuallizar, setActuallizar] = useState(null)

  const auth = AuthUse()
  const token = auth.user.token

  useEffect(() => {
    dataPresupuestoService.readAll(token).then((data) => {
      setDataPresupuestos(data)
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      dataPresupuestoService.readAll(token).then((data) => {
        setDataPresupuestos(data)
        setLoading(false)
      })
      setActuallizar(actuallizar + 1)
    }, 600000)
    return () => {
      clearInterval(interval)
    }
  }, [actuallizar])

  useEffect(() => {
    const dataPresupuesto = dataPresupuestos.find(
      (p) => p.estatusDataPresupuesto === 'OPERATIVO'
    )
    setDataPresupuestocargando(dataPresupuesto)
  }, [dataPresupuestos])

  const findDataPresupuesto = (id) => {
    const dataPresupuesto = dataPresupuestos.find((p) => p.id === id)
    setEditDataPresupuesto(dataPresupuesto)
  }

  const createDataPresupuesto = (dataPresupuesto) => {
    dataPresupuestoService.create(dataPresupuesto, token).then((data) => {
      setDataPresupuestos([...dataPresupuestos, data.saveDataPresupuesto])
      createBodegaDataPresupuesto(data.saveDataPresupuesto)
      setCreateBodegaDataPresupuesto1(data.saveDataPresupuesto)
    })
  }
  const createBodegaDataPresupuesto = (saveDataPresupuesto) => {
    const cargaBodega = {
      dataPresupuestoID: saveDataPresupuesto.id,
      nombreBodega: '',
      estatusCargaBodega: 'CARGANDO',
      toneladasCargadasBodega: 0,
      toneladasCapacidadBodega: 0
    }

    // let i = 1

    // do {
    //   cargaBodega.nombreBodega = `BODEGA ${i}`
    //   cargaBodegaService.create(cargaBodega, token).then(i++)
    // } while (i <= saveDataPresupuesto.cantidadBodegas)
    for (let i = 1; i <= saveDataPresupuesto.cantidadBodegas; i++) {
      cargaBodega.nombreBodega = `BODEGA ${i}`
    }
    // dataPresupuestoService.create(CargaBodega, token).then((data) => {
    //   setDataPresupuestos([...dataPresupuestos, data.saveDataPresupuesto])
    //   console.log('dataPresupuesto creado', data.saveDataPresupuesto)
    // })
  }

  const updateDataPresupuesto = (dataPresupuesto) => {
    dataPresupuestoService
      .update(dataPresupuesto, token)
      .then((data) =>
        setDataPresupuestos(
          dataPresupuestos.map((p) =>
            p.id === dataPresupuesto.id ? data.updateDataPresupuesto : p
          )
        )
      )
    setEditDataPresupuesto(null)
  }
  const deleteDataPresupuesto = (id) => {
    dataPresupuestoService
      .delete(id, token)
      .then(() =>
        setDataPresupuestos(dataPresupuestos.filter((p) => p.id !== id))
      )
  }

  return (
    <DataPresupuestoContext.Provider
      value={{
        findDataPresupuesto,
        createDataPresupuesto,
        updateDataPresupuesto,
        deleteDataPresupuesto,
        editDataPresupuesto,
        dataPresupuestocargando,
        dataPresupuestos,
        loading,
        createBodegaDataPresupuesto1
      }}
    >
      {props.children}
    </DataPresupuestoContext.Provider>
  )
}

export default DataPresupuestoContextProvider
