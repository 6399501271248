/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'
import { AvancePresupuestoService } from '../services/AvancePresupuestoService'
import AuthUse from '../../../auth/AuthUse'
// import { CargaBodegaService } from '../services/CargaBodegaService'
export const AvancePresupuestoContext = createContext()

const AvancePresupuestoContextProvider = (props) => {
  const avancePresupuestoService = new AvancePresupuestoService()
  // const cargaBodegaService = new CargaBodegaService()

  const [avancePresupuestos, setAvancePresupuestos] = useState([])
  const [createBodegaAvancePresupuesto1, setCreateBodegaAvancePresupuesto1] =
    useState([])
  const [avancePresupuestocargando, setAvancePresupuestocargando] =
    useState(null)
  const [editAvancePresupuesto, setEditAvancePresupuesto] = useState(null)
  const [loading, setLoading] = useState(true)
  const [actuallizar, setActuallizar] = useState(null)

  const auth = AuthUse()
  const token = auth.user.token

  useEffect(() => {
    avancePresupuestoService.readAll(token).then((data) => {
      setAvancePresupuestos(data)
      setLoading(false)
    })
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      avancePresupuestoService.readAll(token).then((data) => {
        setAvancePresupuestos(data)
        setLoading(false)
      })
      setActuallizar(actuallizar + 1)
    }, 600000)
    return () => {
      clearInterval(interval)
    }
  }, [actuallizar])

  useEffect(() => {
    const avancePresupuesto = avancePresupuestos.find(
      (p) => p.estatusAvancePresupuesto === 'OPERATIVO'
    )
    setAvancePresupuestocargando(avancePresupuesto)
  }, [avancePresupuestos])

  const findAvancePresupuesto = (id) => {
    const avancePresupuesto = avancePresupuestos.find((p) => p.id === id)
    setEditAvancePresupuesto(avancePresupuesto)
  }

  const createAvancePresupuesto = (avancePresupuesto) => {
    avancePresupuestoService.create(avancePresupuesto, token).then((data) => {
      setAvancePresupuestos([...avancePresupuestos, data.saveAvancePresupuesto])
      createBodegaAvancePresupuesto(data.saveAvancePresupuesto)
      setCreateBodegaAvancePresupuesto1(data.saveAvancePresupuesto)
    })
  }
  const createBodegaAvancePresupuesto = (saveAvancePresupuesto) => {
    const cargaBodega = {
      avancePresupuestoID: saveAvancePresupuesto.id,
      nombreBodega: '',
      estatusCargaBodega: 'CARGANDO',
      toneladasCargadasBodega: 0,
      toneladasCapacidadBodega: 0
    }

    // let i = 1

    // do {
    //   cargaBodega.nombreBodega = `BODEGA ${i}`
    //   cargaBodegaService.create(cargaBodega, token).then(i++)
    // } while (i <= saveAvancePresupuesto.cantidadBodegas)
    for (let i = 1; i <= saveAvancePresupuesto.cantidadBodegas; i++) {
      cargaBodega.nombreBodega = `BODEGA ${i}`
    }
    // avancePresupuestoService.create(CargaBodega, token).then((data) => {
    //   setAvancePresupuestos([...avancePresupuestos, data.saveAvancePresupuesto])
    //   console.log('avancePresupuesto creado', data.saveAvancePresupuesto)
    // })
  }

  const updateAvancePresupuesto = (avancePresupuesto) => {
    avancePresupuestoService
      .update(avancePresupuesto, token)
      .then((data) =>
        setAvancePresupuestos(
          avancePresupuestos.map((p) =>
            p.id === avancePresupuesto.id ? data.updateAvancePresupuesto : p
          )
        )
      )
    setEditAvancePresupuesto(null)
  }
  const deleteAvancePresupuesto = (id) => {
    avancePresupuestoService
      .delete(id, token)
      .then(() =>
        setAvancePresupuestos(avancePresupuestos.filter((p) => p.id !== id))
      )
  }

  return (
    <AvancePresupuestoContext.Provider
      value={{
        findAvancePresupuesto,
        createAvancePresupuesto,
        updateAvancePresupuesto,
        deleteAvancePresupuesto,
        editAvancePresupuesto,
        avancePresupuestocargando,
        avancePresupuestos,
        loading,
        createBodegaAvancePresupuesto1
      }}
    >
      {props.children}
    </AvancePresupuestoContext.Provider>
  )
}

export default AvancePresupuestoContextProvider
