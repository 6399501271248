import ComentarioSubProyectoList from '../components/ComentarioSubProyectoList'
import ComentarioSubProyectoContextProvider from '../contexts/ComentarioSubProyectoContext'
import ProyectoContextProvider from '../contexts/ProyectoContext'
import SubProyectoContextProvider from '../contexts/SubProyectoContext'

export const ComentarioSubProyectoPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <h5>ComentarioSubProyecto CRUD</h5>
          <ProyectoContextProvider>
            <SubProyectoContextProvider>
              <ComentarioSubProyectoContextProvider>
                <ComentarioSubProyectoList />
              </ComentarioSubProyectoContextProvider>
            </SubProyectoContextProvider>
          </ProyectoContextProvider>
        </div>
      </div>
    </div>
  )
}
