/* eslint-disable react/prop-types */

import moment from 'moment'

export default function IngresoVsGastoDetalleCentroDeCosto({
  centroDeCostoAux,
  ingresoGastos,
  anoVisual,
  formatCurrency
}) {
  const ingresoGastosFiltrado = ingresoGastos.filter(
    (ingresoGasto) =>
      ingresoGasto.centroDeCostoAuxId?.nombreCentroDeCosto ===
        centroDeCostoAux?.nombreCentroDeCosto &&
      moment(anoVisual).isSame(ingresoGasto.fechaIngresoGasto, 'year')
  )
  const totalIngresos =
    ingresoGastosFiltrado.reduce(
      (acc, ingresoGasto) => acc + ingresoGasto.ingresoIngresoGasto,
      0
    ) | 0
  const totalEgresos =
    ingresoGastosFiltrado.reduce(
      (acc, ingresoGasto) => acc + ingresoGasto.egresoIngresoGasto,
      0
    ) | 0

  //   .filter((ingresoGasto) => ingresoGasto.tipo === 'Gasto')

  return (
    <div className="col-12 lg:col-4 xl:col-4 text-center">
      <div className=" mb-0">
        <div className="flex justify-content-between mb-3">
          <div className="card w-100">
            <h4 className="block text-900 font-medium mb-3">
              {centroDeCostoAux.nombreCentroDeCosto}
            </h4>
            <hr style={{ width: '100%', height: '5px' }} />
            <div className="text-900 font-medium text-xl mb-3">
              Inversión{' '}
              <span className="text-blue-400 font-medium">
                {formatCurrency(totalIngresos) || 0}
              </span>{' '}
            </div>
            <div className="text-900 font-medium text-xl mb-5">
              Gastos{' '}
              <span className="text-green-500 font-medium">
                {formatCurrency(totalEgresos)}
              </span>{' '}
            </div>

            <hr style={{ width: '100%', height: '5px' }} />

            <div className="text-900 font-medium text-xl mb-3">
              Inversión{' '}
              <span className="text-blue-400 font-medium">
                {(
                  (100 * totalIngresos) /
                  (totalIngresos + totalEgresos)
                ).toFixed(2)}
                %
              </span>
            </div>
            <div className="text-900 font-medium text-xl mb-5">
              Gastos{' '}
              <span className="text-green-500 font-medium">
                {(
                  (100 * totalEgresos) /
                  (totalEgresos + totalIngresos)
                ).toFixed(2)}
                %
              </span>
            </div>
            <div className="text-900 font-medium text-xl">
              Saldo{' '}
              <span className="text-pink-400 font-medium">
                {formatCurrency(totalIngresos - totalEgresos)}
              </span>{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
