import SubProyectoList from '../components/SubProyectoList'
import ProveedorContextProvider from '../contexts/ProveedorContext'
import ProyectoContextProvider from '../contexts/ProyectoContext'
import ResponsableContextProvider from '../contexts/ResponsableContext'
import SubProyectoContextProvider from '../contexts/SubProyectoContext'

export const SubProyectoPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <h5>SubProyecto CRUD</h5>
          <ProyectoContextProvider>
            <SubProyectoContextProvider>
              <ProveedorContextProvider>
                <ResponsableContextProvider>
                  <SubProyectoList />
                </ResponsableContextProvider>
              </ProveedorContextProvider>
            </SubProyectoContextProvider>
          </ProyectoContextProvider>
        </div>
      </div>
    </div>
  )
}
