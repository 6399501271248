import CargaInformacionPanel from '../components/CargaInformacionPanel'
import AbonoPresupuestoContextProvider from '../contexts/AbonoPresupuestoContext'
import AvancePresupuestoContextProvider from '../contexts/AvancePresupuestoContext'
import ComentarioSubProyectoContextProvider from '../contexts/ComentarioSubProyectoContext'
import DataPresupuestoContextProvider from '../contexts/DataPresupuestoContext'
import PresupuestoContextProvider from '../contexts/PresupuestoContext'
import ProveedorContextProvider from '../contexts/ProveedorContext'
import ProyectoContextProvider from '../contexts/ProyectoContext'
import ResponsableContextProvider from '../contexts/ResponsableContext'
import SolicitudFondoContextProvider from '../contexts/SolicitudFondoContext'
import SubProyectoContextProvider from '../contexts/SubProyectoContext'

export const CargaInformacionPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <ProyectoContextProvider>
            <SubProyectoContextProvider>
              <ComentarioSubProyectoContextProvider>
                <PresupuestoContextProvider>
                  <DataPresupuestoContextProvider>
                    <SolicitudFondoContextProvider>
                      <AvancePresupuestoContextProvider>
                        <ProveedorContextProvider>
                          <AbonoPresupuestoContextProvider>
                            <ResponsableContextProvider>
                              <CargaInformacionPanel />
                            </ResponsableContextProvider>
                          </AbonoPresupuestoContextProvider>
                        </ProveedorContextProvider>
                      </AvancePresupuestoContextProvider>
                    </SolicitudFondoContextProvider>
                  </DataPresupuestoContextProvider>
                </PresupuestoContextProvider>
              </ComentarioSubProyectoContextProvider>
            </SubProyectoContextProvider>
          </ProyectoContextProvider>
        </div>
      </div>
    </div>
  )
}
