import AbonoPresupuestoContextProvider from '../../Control/contexts/AbonoPresupuestoContext'
import PresupuestoContextProvider from '../../Control/contexts/PresupuestoContext'
import SubProyectoContextProvider from '../../Control/contexts/SubProyectoContext'
import CargaInformacionPanel from '../components/CargaInformacionPanel'
import CentroDeCostoAuxContextProvider from '../contexts/CentroDeCostoAuxContext'
import ConceptoAuxContextProvider from '../contexts/ConceptoAuxContext'

import IngresoGastoContextProvider from '../contexts/IngresoGastoContext'

export const CargaInformacionPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <ConceptoAuxContextProvider>
            <CentroDeCostoAuxContextProvider>
              <IngresoGastoContextProvider>
                <PresupuestoContextProvider>
                  <AbonoPresupuestoContextProvider>
                    <SubProyectoContextProvider>
                      <CargaInformacionPanel />
                    </SubProyectoContextProvider>
                  </AbonoPresupuestoContextProvider>
                </PresupuestoContextProvider>
              </IngresoGastoContextProvider>
            </CentroDeCostoAuxContextProvider>
          </ConceptoAuxContextProvider>
        </div>
      </div>
    </div>
  )
}
