/* eslint-disable no-unused-vars */
/* eslint-disable multiline-ternary */
/* eslint-disable indent */
/* eslint-disable react/prop-types */

// import HomeDataPresupuestoCardRender from './HomeDataPresupuestoCardRender'
import { DataPresupuestoContext } from '../contexts/DataPresupuestoContext'
import { useContext, useEffect, useState } from 'react'
import { ProgressBar } from 'primereact/progressbar'
import { PresupuestoContext } from '../contexts/PresupuestoContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import HomeDataPresupuestoListRender from './HomeDataPresupuestoListRender'
import moment from 'moment'
import { Galleria } from 'primereact/galleria'
import { Textfit } from 'react-textfit'
import { CircularProgressbar } from 'react-circular-progressbar'
import { AvancePresupuestoContext } from '../contexts/AvancePresupuestoContext'
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Area,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  Sector
} from 'recharts'
import { AbonoPresupuestoContext } from '../contexts/AbonoPresupuestoContext'
// avancePresupuesto
const HomePresupuestoCardRenderNew = ({ presupuestos }) => {
  const { dataPresupuestos } = useContext(DataPresupuestoContext)
  const { updatePresupuesto } = useContext(PresupuestoContext)
  const { avancePresupuestos } = useContext(AvancePresupuestoContext)
  const { abonoPresupuestos } = useContext(AbonoPresupuestoContext)

  const [dataCantidadTotal, setDataCantidadTotal] = useState(0)
  const [dataValuacionPrecio, setDataValuacionPrecio] = useState(0)
  const [dataFintrado, setDataFitrado] = useState()
  const [displayDetalleCarga, setDisplayDetalleCarga] = useState(false)
  // const [dataValuacionTotal, setDataValuacionTotal] = useState()
  // const [dataPrecioUnitarioTotal, setDataPrecioUnitarioTotal] = useState()
  const [dataPorcentajeAvance, setDataPorcentajeAvance] = useState()
  // eslint-disable-next-line no-unused-vars
  const [images, setImages] = useState([])
  const [presupuestoPorcentajeAvancePlan, setPresupuestoPorcentajeAvancePlan] =
    useState()
  const [presupuestoPorcentajeAvanceReal, setPresupuestoPorcentajeAvanceReal] =
    useState()
  const [presupuesCantidadTotal, setPresupuestoCantidadTotal] = useState(0)
  const [presupuestoTotalPlan, setPresupuestoTotalPlan] = useState(0)
  const [presupuestoTotalReal, setPresupuestoTotalReal] = useState(0)

  const [abonoPresupuesto, setAbonoPresupuesto] = useState()
  const [dataGrafica, setDataGrafica] = useState([
    {
      name: 'Page A',
      totalAuto: presupuesCantidadTotal,
      totalPlan: presupuestoTotalPlan,
      totalReal: presupuestoTotalReal,
      totalabono: abonoPresupuesto
    }
  ])
  useEffect(() => {
    //   image0: presupuestos.image,
    //   image1: presupuestos.imageFinal
    // }
    setImages([presupuestos.image, presupuestos.imageFinal])
    sumaPresupuesto()
  }, [dataPresupuestos])
  useEffect(() => {
    setDataGrafica([
      {
        name: 'Page A',
        totalAuto: presupuesCantidadTotal,
        totalPlan: presupuestoTotalPlan,
        totalReal: presupuestoTotalReal,
        totalabono: abonoPresupuesto,
        totalAbonoPendiente: presupuestoTotalReal - abonoPresupuesto
      }
    ])
  }, [
    presupuesCantidadTotal,
    presupuestoTotalPlan,
    presupuestoTotalReal,
    abonoPresupuesto
  ])
  const fecha1 = moment(presupuestos.fechaInicioPresupuesto)
  const fecha2 = moment(
    presupuestos.fechaFinalPresupuesto
      ? presupuestos.fechaFinalPresupuesto
      : moment()
  )
  const fecha3 = moment()
  const fechaMenorPlan = moment(presupuestos.fechaInicioPlanPresupuesto)
  const fechaMayorPlan = moment(presupuestos.fechaFinalPlanPresupuesto)
  const fechaMenorReal = moment(presupuestos.fechaInicioRealPresupuesto)
  const fechaMayorReal = moment(presupuestos.fechaFinalRealPresupuesto)
  const diffPlanTotal = fechaMayorPlan.diff(fechaMenorPlan, 'days') // Diff in days
  const diffRealTotal = fechaMayorReal.diff(fechaMenorReal, 'days') // Diff in days
  const diffDesfaseInicio = fechaMenorReal.diff(fechaMenorPlan, 'days') // Diff in days
  const diffDesfaseFin = fechaMayorReal.diff(fechaMayorPlan, 'days') // Diff in days
  // Diff in hours
  const diff = fecha2.diff(fecha1, 'seconds') // Diff in days
  const diff1 = fecha3.diff(fecha1, 'seconds')
  const porcentajeDias = (diff1 * 100) / diff

  function secondsToString(diff) {
    const numdays = Math.floor(diff / 86400)
    const numhours = Math.floor((diff % 86400) / 3600)
    const numminutes = Math.floor(((diff % 86400) % 3600) / 60)
    // const numseconds = ((diff % 86400) % 3600) % 60

    return numdays + 'D :' + numhours + 'H :' + numminutes + ' M'
  }

  const formatCurrency = (value) => {
    if (value === undefined) {
      return '0.00'
    }

    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
  }
  const sumaPresupuesto = () => {
    // GATOS
    const dataPresupuesto = dataPresupuestos.filter(
      (p) =>
        p.presupuestoId?.id === presupuestos.id &&
        p.estatusDataPresupuesto === 'APROBADO'
    )
    const menorFecha = dataPresupuesto.map((p) =>
      p.fechaInicioDataPresupuesto
        ? moment(p.fechaInicioDataPresupuesto).format('x')
        : moment().format('x')
    )
    const mayorFecha = dataPresupuesto.map((p) =>
      p.fechaFinalDataPresupuesto
        ? moment(p.fechaFinalDataPresupuesto).format('x')
        : moment().format('x')
    )

    let total = 0
    let totalValuacionPrecio = 0
    for (const unitario of dataPresupuesto) {
      // precioUnitario += unitario.precioUnitarioDataPresupuesto
      // cantidad += unitario.cantidadDataPresupuesto
      if (unitario.estatusDataPresupuesto !== 'ANULADO') {
        total +=
          unitario.cantidadDataPresupuesto *
          unitario.precioUnitarioDataPresupuesto
        totalValuacionPrecio +=
          unitario.cantidadDataPresupuesto *
          unitario.valuacionPrecioUnitarioDataPresupuesto
      }
    }
    setDataCantidadTotal(total)
    setDataValuacionPrecio(totalValuacionPrecio)

    setDataFitrado(dataPresupuesto)
    // const totalCantidad = dataPresupuesto
    //   .map((p) => p.cantidadDataPresupuesto)
    //   .reduce((a, b) => a + b, 0)

    // const totalPrecioUnitario = dataPresupuesto
    //   .map((p) => p.precioUnitarioDataPresupuesto)
    //   .reduce((a, b) => a + b, 0)

    // setDataPrecioUnitarioTotal(totalPrecioUnitario)

    // const totalValuaciones = dataPresupuesto
    //   .map((p) => p.valuacionCantidadDataPresupuesto)
    //   .reduce((a, b) => a + b, 0)
    let auxAvancePorcentaje = []
    dataPresupuesto.forEach((dataset, i) => {
      auxAvancePorcentaje = auxAvancePorcentaje.concat(
        dataset.cantidadDataPresupuesto !== 0 &&
          dataset.cantidadDataPresupuesto !== null &&
          (dataset.valuacionCantidadDataPresupuesto * 100) /
            dataset.cantidadDataPresupuesto
      )
    })
    if (total !== 0) {
      updatePresupuesto({
        id: presupuestos.id,
        avancePresupuesto: (
          auxAvancePorcentaje.reduce((a, b) => a + b, 0) /
          dataPresupuesto.length
        ).toFixed(2),
        fechaFinalPresupuesto: moment(Math.max(...mayorFecha)),
        fechaInicioPresupuesto: moment(Math.min(...menorFecha)),
        totalPresupuesto: total
      })
    }

    setDataPorcentajeAvance(
      (
        auxAvancePorcentaje.reduce((a, b) => a + b, 0) / dataPresupuesto.length
      ).toFixed(2)
    )
    let avancePresupuestoPlan = []
    avancePresupuestos.forEach((avance) => {
      if (avance.presupuestoId.id === presupuestos.id) {
        avancePresupuestoPlan = avancePresupuestoPlan.concat(
          avance.totalAvancePresupuesto
        )
      }
    })

    setPresupuestoPorcentajeAvancePlan(
      avancePresupuestoPlan.reduce((a, b) => a + b, 0)
    )

    let avancePresupuestosReal = []

    avancePresupuestos.forEach((avance) => {
      if (avance.presupuestoId.id === presupuestos.id) {
        avancePresupuestosReal = avancePresupuestosReal.concat(
          avance.totalAvanceRealPresupuesto
        )
      }
    })

    setPresupuestoPorcentajeAvanceReal(
      avancePresupuestosReal.reduce((a, b) => a + b, 0)
    )
    let abonoPresupuesto = []

    abonoPresupuestos.forEach((abono) => {
      if (abono.presupuestoId.id === presupuestos.id) {
        abonoPresupuesto = abonoPresupuesto.concat(abono.totalAbonoPresupuesto)
      }
    })

    setPresupuestoCantidadTotal(presupuestos.totalPresupuesto)
    setPresupuestoTotalPlan(presupuestos.totalPlanPresupuesto)
    setPresupuestoTotalReal(presupuestos.totalRealPresupuesto)
    setAbonoPresupuesto(abonoPresupuesto.reduce((a, b) => a + b, 0))
    // setDataValuacionTotal(totalValuaciones)

    // COSTOS TM MES

    // COSTO MENSUALIDAD OPERATIVA
  }
  // const porcentajeAvance = (
  //   (dataValuacionTotal * 100) /
  //   dataCantidadTotal
  // ).toFixed(2)
  const dialogFuncMap = {
    displayDetalleCarga: setDisplayDetalleCarga
  }
  const onClick = (name) => {
    dialogFuncMap[`${name}`](true)
  }

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false)
  }
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="Salir"
          icon="pi pi-check"
          onClick={() => onHide(name)}
          autoFocus
        />
      </div>
    )
  }
  const itemTemplate = (item) => {
    return (
      <img
        src={
          item
            ? item.url
            : 'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png'
        }
        // src={item.url}
        onError={(e) =>
          (e.target.src =
            'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png')
        }
        alt={
          item
            ? item.url
            : 'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png'
        }
        style={{ width: '300px', display: 'block', height: '250px' }}
      />
    )
  }

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={
          item
            ? item.url
            : 'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png'
        }
        alt={
          item
            ? item.url
            : 'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png'
        }
        onError={(e) =>
          (e.target.src =
            'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png')
        }
        style={{ display: 'block' }}
      />
    )
  }
  const styleObject = {
    // marginBottom: '3px',
    // borderWidth: '2px',
    // borderRadius: '10px',
    // border: '2px solid ',
    // color: 'black',
    // padding: '5px',
    // marginRight: '5px',
    whiteSpace: 'nowrap'
  }
  const customStyles = {
    path: {
      stroke: 'rgba(255, 0, 0, 1)' // Color de la barra de progreso
    },
    trail: {
      stroke: '#d6d6d6' // Color de la línea de fondo
    },
    text: {
      fill: '#f88', // Color del texto
      fontSize: '12px' // Tamaño del texto
    }
  }
  const customStyles1 = {
    path: {
      stroke: 'rgba(7, 61, 139, 1)' // Color de la barra de progreso
    },
    trail: {
      stroke: '#d6d6d6' // Color de la línea de fondo
    },
    text: {
      fill: '#002458', // Color del texto
      fontSize: '12px' // Tamaño del texto
    }
  }
  return (
    <div
    // className=" col-12 lg:col-4 xl:col-3 "
    // onClick={onAppsControlClick}
    >
      <div
        className={
          'cardAPPS card mb-0 border-bottom-0 border-start-0 border-4 m-1'
        }
        style={{
          borderColor:
            (porcentajeDias > 100 ? 99 : porcentajeDias) < dataPorcentajeAvance
              ? '#5ecca3'
              : '#dc3545'
        }}
      >
        <div className="flex justify-content-between mb-1">
          <div>
            <span className="block text-600 font-medium mb-1">
              <span className="block text-900 font-medium mb-1 text-center">
                <strong>{presupuestos.nombrePresupuesto.toUpperCase()}</strong>{' '}
                <Button
                  icon="pi pi-search"
                  rounded
                  severity="success"
                  aria-label="Search"
                  onClick={() => onClick('displayDetalleCarga')}
                  tooltip="Ver Detalles"
                  tooltipOptions={{ position: 'top' }}
                />
              </span>

              <div className="my-2">
                {/* {presupuestos.nombrePresupuesto.toUpperCase()} {'('}Inicio{' '}
              {moment(presupuestos.fechaInicioPresupuesto).format('DD/MM/YY')}{' '}
              Fin{' '}
              {moment(presupuestos.fechaFinalPresupuesto).format('DD/MM/YY')}
              {')'} */}
                {/* <ProgressBar value={presupuestos.avancePresupuesto}></ProgressBar> */}
                <div className="col-12 lg:col-12 xl:col-12" style={{}}>
                  <div className="flex ">
                    <div className="  w-100">
                      <div style={{ textAlign: 'center' }}>
                        <div className="card p-0" style={styleObject}>
                          {'Dias Plan = '}
                          {diffPlanTotal}
                          <hr style={{ margin: 0 }} />
                          <div className="font text-muted small">
                            {moment(
                              presupuestos.fechaInicioPlanPresupuesto
                            ).format('DD/MM')}
                            -
                            {moment(
                              presupuestos.fechaFinalPlanPresupuesto
                            ).format('DD/MM/YY')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="  w-100">
                      <div style={{ textAlign: 'center' }}>
                        <div className="card p-0" style={styleObject}>
                          {'Dias Real = '}
                          {diffRealTotal}

                          <hr style={{ margin: 0 }} />
                          <div className="font text-muted small">
                            {moment(
                              presupuestos.fechaInicioRealPresupuesto
                            ).format('DD/MM')}
                            -
                            {moment(
                              presupuestos.fechaFinalRealPresupuesto
                            ).format('DD/MM/YY')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" w-100">
                      <div style={{ textAlign: 'center' }}>
                        <div
                          className={
                            'cardAPPS card  p-0 mb-0 border-bottom-0 border-start-0 border-4 m-1'
                          }
                          style={{
                            borderColor:
                              diffDesfaseInicio - diffDesfaseFin > 0
                                ? '#5ecca3'
                                : '#dc3545'
                          }}
                        >
                          Desfase
                          <hr style={{ margin: 0 }} />
                          <div className="small">
                            {diffDesfaseInicio - diffDesfaseFin * -1}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className=" w-100">
                      <div style={{ textAlign: 'center' }}>
                        <div className="card p-0" style={styleObject}>
                          Proveedor
                          <hr style={{ margin: 0 }} />
                          <div>
                            <Textfit mode="single" max={14}>
                              {presupuestos.proveedorId?.nombreProveedor}
                            </Textfit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 lg:col-12 xl:col-12"
                  style={
                    {
                      // display: 'flex'
                      // justifyContent: 'space-between'
                      // width: '180px' // Aumenta el ancho para dar espacio a los títulos
                      // height: '75px'
                      // backgroundColor: 'green'
                    }
                  }
                >
                  <div
                    style={{
                      // width: '80px',
                      textAlign: 'center',
                      display: 'flex'
                    }}
                  >
                    <div
                      style={{
                        // width: '80px',
                        textAlign: 'center'
                      }}
                    >
                      <div
                        className="card p-0"
                        // style={{
                        //   marginBottom: '10px',
                        //   borderWidth: '2px',
                        //   borderRadius: '10px',
                        //   border: '2px solid ',
                        //   // color: 'black',
                        //   padding: '5px'
                        // }}
                      >
                        Planificado
                      </div>
                      <div style={{}}>
                        <CircularProgressbar
                          value={presupuestoPorcentajeAvancePlan}
                          text={`${presupuestoPorcentajeAvancePlan}%`}
                          styles={customStyles1}
                          strokeWidth={20}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        // width: '80px',
                        textAlign: 'center'
                        // marginLeft: '10px'
                      }}
                    >
                      {/* Envuelve el CircularProgressbar y el título en un div */}
                      <div
                        className="card p-0"
                        // style={{
                        //   marginBottom: '10px',
                        //   borderWidth: '2px',
                        //   borderRadius: '10px',
                        //   border: '2px solid ',
                        //   // color: 'black',
                        //   padding: '5px'
                        // }}
                      >
                        Ejecutado
                      </div>
                      {/* Título del CircularProgressbar */}
                      <div>
                        <CircularProgressbar
                          value={presupuestoPorcentajeAvanceReal}
                          text={`${presupuestoPorcentajeAvanceReal}%`}
                          styles={customStyles}
                          strokeWidth={20}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" w-100 mt-1">
                    <div style={{ textAlign: 'center' }}>
                      <div
                        className={
                          ' card mb-0 p-0 border-bottom-0 border-start-0 border-4 m-1'
                        }
                        style={{
                          borderColor:
                            presupuestoPorcentajeAvancePlan -
                              presupuestoPorcentajeAvanceReal >
                            0
                              ? '#dc3545'
                              : '#5ecca3'
                        }}
                      >
                        Variación del avance
                        <hr style={{ margin: 0 }} />
                        <div>
                          {presupuestoPorcentajeAvancePlan -
                            presupuestoPorcentajeAvanceReal}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 lg:col-12 xl:col-12"
                  style={{ position: 'relative' }}
                >
                  <div className=" w-100">
                    <ResponsiveContainer width="100%" height={200}>
                      <ComposedChart
                        layout="vertical"
                        width={500}
                        height={400}
                        data={dataGrafica}
                        margin={{
                          left: -50
                        }}
                        barGap={10}
                      >
                        {/* <CartesianGrid stroke="#f5f5f5" /> */}
                        <XAxis
                          type="number"
                          tickFormatter={(value) => formatCurrency(value)}
                        />
                        <YAxis type="category" scale="band" />
                        <Tooltip />

                        {/* <Legend /> */}
                        {/* <Bar
                      name="totalAuto"
                      dataKey="totalAuto"
                      barSize={30}
                      fill="#ffc107d1"
                      label={({ x, y, width, height, value }) => (
                        <text
                          x={Math.max(150, x + Math.min(width, 200))}
                          y={y}
                          // fill="red"
                          textAnchor="middle"
                          dy={20}
                        >
                          Total Automatico: {formatCurrency(value)}
                        </text>
                      )}
                    /> */}
                        <Bar
                          name="totalPlan"
                          dataKey="totalPlan"
                          barSize={30}
                          fill="#0c3d8bc9"
                          label={({ x, y, width, height, value }) => (
                            <text
                              x={Math.max(150, x + Math.min(width, 200))}
                              y={y}
                              // fill="red"
                              textAnchor="middle"
                              dy={20}
                            >
                              Total Planificado: {formatCurrency(value)}
                            </text>
                          )}
                        />
                        <Bar
                          name="totalReal"
                          dataKey="totalReal"
                          barSize={30}
                          fill="#f50303d1"
                          label={({ x, y, width, height, value }) => (
                            <text
                              x={Math.max(150, x + Math.min(width, 200))}
                              y={y}
                              // fill="red"
                              textAnchor="middle"
                              dy={20}
                            >
                              Total Real: {formatCurrency(value)}
                            </text>
                          )}
                        />
                        <Bar
                          name="totalabono"
                          dataKey="totalabono"
                          barSize={30}
                          fill="#0dcaf0c2"
                          label={({ x, y, width, height, value }) => (
                            <text
                              x={Math.max(150, x + Math.min(width, 200))}
                              y={y}
                              // fill="red"
                              textAnchor="middle"
                              dy={20}
                            >
                              Total Abonos: {formatCurrency(value)}
                            </text>
                          )}
                        />
                        <Bar
                          name="totalAbonoPendiente"
                          dataKey="totalAbonoPendiente"
                          barSize={30}
                          fill="#ffc107b8"
                          label={({ x, y, width, height, value }) => (
                            <text
                              x={Math.max(150, x + Math.min(width, 200))}
                              y={y}
                              // fill="red"
                              textAnchor="middle"
                              dy={20}
                            >
                              Pendiente por Pagar: {formatCurrency(value)}
                            </text>
                          )}
                        />
                      </ComposedChart>
                      {(
                        ((presupuestoTotalReal - presupuestoTotalPlan) /
                          presupuestoTotalReal) *
                        100
                      ).toFixed(2) > 0 && (
                        <div
                          style={{
                            position: 'absolute',
                            right: 0,
                            // bottom: 110,
                            top: 0,
                            width: '70px',
                            height: '70px'
                          }}
                        >
                          <Textfit mode="multi" max={9}>
                            Desviación presupuestaria
                          </Textfit>
                          <CircularProgressbar
                            value={(
                              ((presupuestoTotalReal - presupuestoTotalPlan) /
                                presupuestoTotalReal) *
                              100
                            ).toFixed(2)}
                            text={`${(
                              ((presupuestoTotalReal - presupuestoTotalPlan) /
                                presupuestoTotalReal) *
                              100
                            ).toFixed(2)}%`}
                            styles={customStyles}
                            strokeWidth={20}
                          />
                        </div>
                      )}
                    </ResponsiveContainer>

                    {/* <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    Total Automatico:
                    <hr style={{ margin: 0 }} />
                    <div>{formatCurrency(presupuesCantidadTotal)}</div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    Total Planificado:
                    <hr style={{ margin: 0 }} />
                    <div>{formatCurrency(presupuestoTotalPlan)}</div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    Total Real:
                    <hr style={{ margin: 0 }} />
                    <div>{formatCurrency(presupuestoTotalReal)}</div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    <Textfit mode="single" max={14}>
                      Desviación presupuestaria:
                    </Textfit>
                    <hr style={{ margin: 0 }} />
                    <div>
                      {(
                        ((presupuestoTotalReal - presupuestoTotalPlan) /
                          presupuestoTotalReal) *
                        100
                      ).toFixed(2)}
                      %
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={styleObject}>
                    Total Abonos:
                    <hr style={{ margin: 0 }} />
                    <div>
                      {abonoPresupuesto && formatCurrency(abonoPresupuesto)}
                    </div>
                  </div>
                </div> */}
                  </div>

                  <div>
                    {/* <ProgressBar
                value={dataPorcentajeAvance}
                color="#0dcaf0"
              ></ProgressBar> */}
                  </div>
                </div>
                {/* <h6 className="card-text mt-0 mb-2">
                  Fecha Inicio:
                  <span className="text-green-500 font-medium">
                    {' '}
                    {moment(presupuestos.fechaInicioPresupuesto).isValid() &&
                      moment(presupuestos.fechaInicioPresupuesto).format(
                        'dddDD/MM/YY HH:mm'
                      )}
                  </span>
                </h6>
                <h6 className="card-text mt-0 mb-2">
                  Fecha Fin:
                  <span className="text-orange-500 font-medium">
                    {' '}
                    {moment(presupuestos.fechaFinalPresupuesto).isValid() &&
                      moment(presupuestos.fechaFinalPresupuesto).format(
                        'dddDD/MM/YY HH:mm'
                      )}
                  </span>
                </h6>
                <h6 className="card-text mt-0 mb-2">
                  Tiempo de Ejecucion:
                  <span className="text-blue-500 font-medium">
                    {secondsToString(diff)}
                  </span>
                </h6> */}
              </div>
              <div className="card">
                <Galleria
                  value={images}
                  numVisible={5}
                  circular
                  style={{ with: '100%' }}
                  showThumbnails={false}
                  showItemNavigators
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                  autoPlay
                  transitionInterval={4000}
                />
              </div>
              {/* <div
                style={{
                  width: '300px',
                  height: '200px'
                }}
              >
                <img
                  src={
                    presupuestos.image
                      ? presupuestos.image.url
                      : 'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png'
                  }
                  alt={presupuestos.title}
                  style={{
                    'object-fit': 'cover',
                    width: '100%',
                    height: '100%'
                  }}
                  onClick={() => onClick('displayDetalleCarga')}
                  onError={(e) =>
                    (e.target.src =
                      'https://res.cloudinary.com/dlt3eax5v/image/upload/v1675834230/saltarra/saltarrana_on_buzawp.png')
                  }
                />
              </div> */}
            </span>
            {/* <span className="block text-500 font-medium mb-1">
              {dataCantidadTotal}
            </span>
            <span className="block text-500 font-medium mb-1">
              {dataValuacionTotal}
            </span> */}
            {/* {dataPorcentajeAvance && (
              <ProgressBar
                value={dataPorcentajeAvance}
                color="#0dcaf0"
              ></ProgressBar>
            )}

            {dataPorcentajeAvance >= 100 ? (
              <span className=" text-600 "> Inversión Total:</span>
            ) : (
              <span className=" text-600 "> Inversión Estimada:</span>
            )}
            <span className=" text-700 font-medium mb-1">
              {formatCurrency(dataCantidadTotal)}
            </span>
            <br />
            <span className=" text-600  "> Abonado Total:</span>
            <span className=" text-700 text-green-500 font-medium mb-1">
              {formatCurrency(presupuestos.totalValuacionPresupuesto)}
            </span>
            <br />
            <span className=" text-600 "> Pendiente Total:</span>
            <span className=" text-700 text-pink-600 font-medium mb-1">
              {formatCurrency(
                dataCantidadTotal - presupuestos.totalValuacionPresupuesto
              )}
            </span> */}
            {/* <span className="block text-500 font-medium mb-1">
              {dataPresupuestos.map(
                (dataPresupuesto) =>
                  dataPresupuesto.estatusDataPresupuesto === 'OPERATIVO' &&
                  dataPresupuesto.presupuestoId === presupuestos.id && (
                    <HomeDataPresupuestoCardRender
                      key={dataPresupuesto.id}
                      dataPresupuesto={dataPresupuesto}
                    />
                  )
              )}
              object-fit: cover;
  width:100%;
  height:100%;
            </span> */}
            <Dialog
              header={`Detalles de ${presupuestos.nombrePresupuesto.toUpperCase()}`}
              visible={displayDetalleCarga}
              onHide={() => onHide('displayDetalleCarga')}
              breakpoints={{ '960px': '75vw' }}
              style={{ width: '75vw' }}
              footer={renderFooter('displayDetalleCarga')}
            >
              {dataFintrado && (
                <HomeDataPresupuestoListRender dataFintrado={dataFintrado} />
              )}
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePresupuestoCardRenderNew
