import IngresoGastoContextProvider from '../contexts/IngresoGastoContext'
import HomeAdministracion from '../components/HomeAdministracion'
import CentroDeCostoAuxContextProvider from '../contexts/CentroDeCostoAuxContext'
import AbonoPresupuestoContextProvider from '../../Control/contexts/AbonoPresupuestoContext'
export const HomeAdministracionPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <IngresoGastoContextProvider>
          <CentroDeCostoAuxContextProvider>
            <AbonoPresupuestoContextProvider>
              <HomeAdministracion />
            </AbonoPresupuestoContextProvider>
          </CentroDeCostoAuxContextProvider>
        </IngresoGastoContextProvider>
      </div>
    </div>
  )
}
