import ProyectoList from '../components/ProyectoList'
import ProyectoContextProvider from '../contexts/ProyectoContext'

export const ProyectoPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <h5>Proyecto CRUD</h5>
          <ProyectoContextProvider>
            <ProyectoList />
          </ProyectoContextProvider>
        </div>
      </div>
    </div>
  )
}
