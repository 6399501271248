import React, { useContext, useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { ComentarioSubProyectoContext } from '../contexts/ComentarioSubProyectoContext'
import moment from 'moment'

import ComentarioSubProyectoForm from './ComentarioSubProyectoForm'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { ProyectoContext } from '../contexts/ProyectoContext'
import { Dropdown } from 'primereact/dropdown'

const ComentarioSubProyectoList = () => {
  const { proyectos } = useContext(ProyectoContext)
  const { subProyectos } = useContext(SubProyectoContext)
  const {
    comentarioSubProyectos,
    findComentarioSubProyecto,
    deleteComentarioSubProyecto
  } = useContext(ComentarioSubProyectoContext)
  const [selectedSubProyecto, setSelectedSubProyecto] = useState(null)
  const [loadingProyecto, setoLadingProyecto] = useState(true)
  const [selectedProyecto, setSelectedProyecto] = useState(null)
  const [comentarioSubProyecto, setComentarioSubProyecto] = useState(
    comentarioSubProyectos
  )
  const [comentarioSubProyectoDelete, setComentarioSubProyectoDelete] =
    useState(null)

  const [subProyecto, setSubProyecto] = useState(subProyectos)
  const [deleteBarcoDialog, setDeleteBarcoDialog] = useState(false)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [isVisible, setIsVisible] = useState(false)

  const dt = useRef(null)
  const toast = useRef(null)
  const saveBarco = (id) => {
    findComentarioSubProyecto(id)
    setIsVisible(true)
  }
  useEffect(() => {
    if (selectedSubProyecto) {
      const comentarioSubProyectosFilter = comentarioSubProyectos.filter(
        (p) => p.subProyectoId?.id === selectedSubProyecto.id
      )
      setComentarioSubProyecto(comentarioSubProyectosFilter)
      setoLadingProyecto(false)
    }
    // setDataComentarioSubProyecto(dataComentarioSubProyectos)
  }, [comentarioSubProyectos])
  const onSubProyecto = (e) => {
    if (e.value) {
      const comentarioSubProyectosFilter = comentarioSubProyectos.filter(
        (p) => p.subProyectoId?.id === e.value.id
      )
      setSelectedSubProyecto(e.value)
      setComentarioSubProyecto(comentarioSubProyectosFilter)
      setoLadingProyecto(false)
    } else {
      setSelectedSubProyecto(null)
      setoLadingProyecto(true)
      setComentarioSubProyecto(subProyectos)
    }
  }
  const onProyecto = (e) => {
    if (e.value) {
      const subProyectoFilter = subProyectos.filter(
        (p) => p.proyectoId?.id === e.value.id
      )
      setSelectedProyecto(e.value)
      setSubProyecto(subProyectoFilter)
    } else {
      setSelectedProyecto(null)
    }
  }
  // cabecera de la tabla
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Dropdown
          value={selectedProyecto}
          options={proyectos}
          onChange={onProyecto}
          optionLabel="nombreProyecto"
          placeholder="Seleccione Proyecto"
          // valueTemplate={selectedconceptoIngresoGastoTemplate}
          // itemTemplate={conceptoIngresoGastoOptionTemplate}
          showClear
          filter
          filterBy="nombreComentarioSubProyecto"
        />
        <Dropdown
          value={selectedSubProyecto}
          options={subProyecto}
          onChange={onSubProyecto}
          optionLabel="nombreSubProyecto"
          placeholder="Seleccione Concepto"
          // valueTemplate={selectedconceptoIngresoGastoTemplate}
          // itemTemplate={conceptoIngresoGastoOptionTemplate}
          showClear
          filter
          filterBy="nombreComentarioSubProyecto"
          disabled={!selectedProyecto}
        />
        <div className="my-2 ">
          <Button
            label="Nuevo"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={() => setIsVisible(true)}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
          />
        </div>
      </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    )
  }
  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const fechacreadoComentarioSubProyecto = (rowData) => {
    const fecha = moment(rowData.creadoComentarioSubProyecto)
    return fecha.format('dddDD/MM/YY HH:mm')
  }
  const fechamodificadoComentarioSubProyecto = (rowData) => {
    const fecha = moment(rowData.modificadoComentarioSubProyecto)
    return fecha.format('dddDD/MM/YY HH:mm')
  }

  const eliminarBarco = () => {
    deleteComentarioSubProyecto(comentarioSubProyectoDelete.id)
    setDeleteBarcoDialog(false)
    toast.current.show({
      severity: 'error',
      summary: 'Eliminar',
      detail: 'Barco Eliminado',
      life: 3000
    })
  }

  const deleteBarcoDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteBarcoDialog(false)}
      />
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => eliminarBarco()}
      />
    </>
  )

  const confirmDeleteBarco = (barcos) => {
    setComentarioSubProyectoDelete(barcos)
    setDeleteBarcoDialog(true)
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2 mb-2"
          onClick={() => saveBarco(rowData.id)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded  p-button-danger"
          onClick={() => confirmDeleteBarco(rowData)}
        />
      </div>
    )
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">ComentarioSubProyecto</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  )
  const clearSelected = () => {
    setDeleteBarcoDialog(false)
  }
  return (
    <>
      <Toast ref={toast} />
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <DataTable
        ref={dt}
        value={comentarioSubProyecto}
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        selectionMode="single"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Muestra {first} a {last} de {totalRecords} ComentarioSubProyecto"
        globalFilter={globalFilter}
        emptyMessage="No hay datos."
        header={header}
        sortField="barcoCreado"
        sortOrder={-1}
        loading={loadingProyecto}
        responsiveLayout="scroll"
        breakpoint="960px"
      >
        <Column body={actionBodyTemplate}></Column>
        {/* <Column
          field="subProyectoId.nombreSubProyecto"
          header="subProyectoId.nombreSubProyecto"
        /> */}
        <Column field="nombreComentarioSubProyecto" header="Nombre" />
        <Column field="descripcionComentarioSubProyecto" header="Descripción" />

        <Column field="estatusComentarioSubProyecto" header="Estatus" />
        <Column
          field="creadoComentarioSubProyecto"
          body={fechacreadoComentarioSubProyecto}
          dataType="date"
          header="Creado"
        />
        <Column
          field="modificadoComentarioSubProyecto"
          body={fechamodificadoComentarioSubProyecto}
          header="Modificado"
          dataType="date"
        />
      </DataTable>

      <ComentarioSubProyectoForm
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />

      <Dialog
        visible={deleteBarcoDialog}
        style={{ width: '450px' }}
        header="Confirm"
        modal
        footer={deleteBarcoDialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '2rem' }}
          />
          {comentarioSubProyectoDelete && (
            <span>
              Esta seguro que quiere eliminar la barco{' '}
              <b>{comentarioSubProyectoDelete.nombreComentarioSubProyecto}</b>?
            </span>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default ComentarioSubProyectoList
