/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { ProyectoContext } from '../contexts/ProyectoContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import { addLocale } from 'primereact/api'
import { InputTextarea } from 'primereact/inputtextarea'
import moment from 'moment'
import { classNames } from 'primereact/utils'

const ActivoForm = (props) => {
  const initialProyectoForm = {
    id: null,
    nombreProyecto: '',
    descripcionProyecto: '',
    totalProyecto: 0,
    avanceProyecto: 0,
    fechaInicioProyecto: '',
    fechaFinalProyecto: '',
    estatusProyecto: '',
    creadoProyecto: moment(),
    modificadoProyecto: moment()
  }

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  const { createProyecto, editProyecto, updateProyecto } =
    useContext(ProyectoContext)

  const { isVisible, setIsVisible } = props
  const [selectedProyecto, setSelectedProyecto] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [ProyectoData, setProyectoData] = useState(initialProyectoForm)
  const estadoProyecto = [
    { estatusProyecto: 'OPERATIVO' },
    { estatusProyecto: 'ANULADO' },

    { estatusProyecto: 'CULMINADO' }
  ]
  const onEstatusProyecto = (e) => {
    setSelectedProyecto(e.value)
    updateField(e.value.estatusProyecto, 'estatusProyecto')
  }

  const toast = useRef(null)

  useEffect(() => {
    if (editProyecto) {
      setProyectoData(editProyecto)
      setSelectedProyecto({
        estatusProyecto: editProyecto.estatusProyecto
      })
    }
  }, [editProyecto])

  const updateField = (data, field) => {
    setProyectoData({
      ...ProyectoData,
      [field]: data
    })
  }

  const saveProyecto = () => {
    setSubmitted(true)
    if (
      ProyectoData.nombreProyecto.trim() &&
      ProyectoData.estatusProyecto.trim()
    ) {
      if (!editProyecto) {
        createProyecto(ProyectoData)
      } else {
        updateProyecto({
          ...ProyectoData,
          ProyectoModificado: moment()
        })
      }
      clearSelected()
    }
  }

  const dialogFooter = (
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => clearSelected()}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveProyecto} />
    </div>
  )

  const clearSelected = () => {
    setIsVisible(false)
    setProyectoData(initialProyectoForm)
    setSelectedProyecto('')
    setSubmitted(false)
  }
  const selectedestatusProyectoTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.estatusProyecto}</div>
        </div>
      )
    }

    return <span>{props.placeholder}</span>
  }

  const estatusProyectoOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.estatusProyecto}</div>
      </div>
    )
  }

  return (
    <div className="dialog-demo">
      <Toast ref={toast} />
      <Dialog
        visible={isVisible}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '30vw' }}
        header="Detalles de la Proyecto"
        footer={dialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="p-grid p-fluid">
          <br />
          <div className="p-float-label">
            <InputText
              value={ProyectoData.nombreProyecto}
              onChange={(e) => updateField(e.target.value, 'nombreProyecto')}
              className={classNames({
                'p-invalid': submitted && !ProyectoData.nombreProyecto
              })}
            />
            {submitted && !ProyectoData.nombreProyecto && (
              <small className="p-invalid">Nombre es requerido.</small>
            )}
            <label>Nombre del Proyecto:*</label>
          </div>
          <br />
          <div className="p-float-label">
            <InputTextarea
              id="description"
              value={ProyectoData.descripcionProyecto}
              onChange={(e) =>
                updateField(e.target.value, 'descripcionProyecto')
              }
              rows={3}
              cols={20}
            />

            <label>Descripcion:</label>
          </div>
          <br />
          <div className="p-float-label ">
            <Dropdown
              value={selectedProyecto}
              options={estadoProyecto}
              onChange={onEstatusProyecto}
              optionLabel="estatusProyecto"
              placeholder="Seleccione Estado"
              valueTemplate={selectedestatusProyectoTemplate}
              itemTemplate={estatusProyectoOptionTemplate}
              className={classNames({
                'p-invalid': submitted && !ProyectoData.estatusProyecto
              })}
            />
            {submitted && !ProyectoData.estatusProyecto && (
              <small className="p-invalid">Estatus es requerido.</small>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ActivoForm
