import CronogramaList from '../components/CronogramaList'
import DataPresupuestoContextProvider from '../contexts/DataPresupuestoContext'
import PresupuestoContextProvider from '../contexts/PresupuestoContext'
export const CronogramaPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        {/* <div className="text-center " style={{ 'margin-top': '10%' }}> */}
        <PresupuestoContextProvider>
          <DataPresupuestoContextProvider>
            <CronogramaList />
          </DataPresupuestoContextProvider>
        </PresupuestoContextProvider>
      </div>
    </div>
    // </div>
  )
}
