import ResponsableList from '../components/ResponsableList'
import ResponsableContextProvider from '../contexts/ResponsableContext'

export const ResponsablePage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <h5>Responsable CRUD</h5>
          <ResponsableContextProvider>
            <ResponsableList />
          </ResponsableContextProvider>
        </div>
      </div>
    </div>
  )
}
