import IngresoGastoContextProvider from '../../administracion/contexts/IngresoGastoContext'
import VolumetriaContextProvider from '../../Control/contexts/VolumetriaContext'
import CostoPorTmReporteEstadistica from '../components/CostoPorTmReporteEstadistica'
export const CostoPorTmReportePage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <VolumetriaContextProvider>
          <IngresoGastoContextProvider>
            <CostoPorTmReporteEstadistica />
          </IngresoGastoContextProvider>
        </VolumetriaContextProvider>
      </div>
    </div>
  )
}
