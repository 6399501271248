/* eslint-disable multiline-ternary */
/* eslint-disable indent */
/* eslint-disable prefer-const */
import { useContext } from 'react'

import { Skeleton } from 'primereact/skeleton'

// import ProgramacionVentanaAngenaV2 from './ProgramacionVentanaAngenaV2'

import 'react-big-scheduler/lib/css/style.css'
import { DataPresupuestoContext } from '../contexts/DataPresupuestoContext'
import ProgramacionVentanaAngenaV2 from './ProgramacionVentanaAngenaV2'
import { PresupuestoContext } from '../contexts/PresupuestoContext'

const CronogramaList = () => {
  const { dataPresupuestos } = useContext(DataPresupuestoContext)
  const { presupuestos } = useContext(PresupuestoContext)
  dataPresupuestos.sort((o1, o2) => {
    if (o1.fechaInicioDataPresupuesto < o2.fechaInicioDataPresupuesto) {
      return -1
    } else if (o1.fechaInicioDataPresupuesto > o2.fechaInicioDataPresupuesto) {
      return 1
    } else {
      return 0
    }
  })

  //   const schedulerData = new SchedulerData(
  //     new moment().format(DATE_FORMAT),
  //     ViewTypes.Week
  //   )
  //   moment.locale('he-il')
  //   schedulerData.setLocaleMoment(moment)
  let auxCronograma = []
  const resources = []
  // eslint-disable-next-line no-unused-vars
  for (let prop1 in presupuestos) {
    resources.push({
      id: `r${prop1}`,
      name: presupuestos[prop1].nombrePresupuesto
    })
    for (let prop in dataPresupuestos) {
      if (
        dataPresupuestos[prop].presupuestoId?.id === presupuestos[prop1].id &&
        dataPresupuestos[prop].fechaInicioDataPresupuesto &&
        dataPresupuestos[prop].fechaFinalDataPresupuesto
      ) {
        auxCronograma.push({
          id: dataPresupuestos[prop].id,
          title: dataPresupuestos[prop].nombreDataPresupuesto,

          start: dataPresupuestos[prop].fechaInicioDataPresupuesto,
          end: dataPresupuestos[prop].fechaFinalDataPresupuesto,
          resourceId: `r${prop1}`,
          movable: false,
          bgColor:
            prop === '1'
              ? '#0d6efd'
              : prop === '2'
              ? '#08afff'
              : prop === '3'
              ? '#dc3545'
              : dataPresupuestos[prop].buqueCliente === 'PDVSA PRIORIDAD'
              ? '#f759ab'
              : '#797d82'
        })
      }
    }
  }

  return (
    <div>
      {auxCronograma.length === 0 ? (
        <div className="field col-12  pr-0">
          <div className=" custom-skeleton p-4">
            <div className="flex justify-content-between mt-3 mb-3">
              <div className="mr-2">
                <Skeleton
                  width="13rem"
                  height="3rem"
                  className="mb-2"
                ></Skeleton>
              </div>

              <Skeleton width="40rem" height="3rem"></Skeleton>
            </div>

            <Skeleton width="100%" height="18rem" className="mb-2"></Skeleton>
          </div>
        </div>
      ) : (
        <>
          <div className="card">
            <ProgramacionVentanaAngenaV2
              events={auxCronograma}
              resources={resources}
            />
            aqui
          </div>
        </>
      )}
    </div>
  )
}
export default CronogramaList
