import PresupuestoList from '../components/PresupuestoList'
import AbonoPresupuestoContextProvider from '../contexts/AbonoPresupuestoContext'
import AvancePresupuestoContextProvider from '../contexts/AvancePresupuestoContext'
import PresupuestoContextProvider from '../contexts/PresupuestoContext'
import ProveedorContextProvider from '../contexts/ProveedorContext'
import ProyectoContextProvider from '../contexts/ProyectoContext'
import SubProyectoContextProvider from '../contexts/SubProyectoContext'

export const PresupuestoPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <h5>Presupuesto CRUD</h5>
          <ProyectoContextProvider>
            <SubProyectoContextProvider>
              <PresupuestoContextProvider>
                <ProveedorContextProvider>
                  <AbonoPresupuestoContextProvider>
                    <AvancePresupuestoContextProvider>
                      <PresupuestoList />
                    </AvancePresupuestoContextProvider>
                  </AbonoPresupuestoContextProvider>
                </ProveedorContextProvider>
              </PresupuestoContextProvider>
            </SubProyectoContextProvider>
          </ProyectoContextProvider>
        </div>
      </div>
    </div>
  )
}
