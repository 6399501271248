import AbonoPresupuestoList from '../components/AbonoPresupuestoList'
import AbonoPresupuestoContextProvider from '../contexts/AbonoPresupuestoContext'
import PresupuestoContextProvider from '../contexts/PresupuestoContext'
import ProyectoContextProvider from '../contexts/ProyectoContext'
import SubProyectoContextProvider from '../contexts/SubProyectoContext'

export const AbonoPresupuestoPage = () => {
  return (
    <div className="grid crud-demo">
      <div className="col-12 ">
        <div className="card">
          <h5>AbonoPresupuesto CRUD</h5>
          <ProyectoContextProvider>
            <SubProyectoContextProvider>
              <AbonoPresupuestoContextProvider>
                <PresupuestoContextProvider>
                  <AbonoPresupuestoList />
                </PresupuestoContextProvider>
              </AbonoPresupuestoContextProvider>
            </SubProyectoContextProvider>
          </ProyectoContextProvider>
        </div>
      </div>
    </div>
  )
}
