/* eslint-disable react/prop-types */

import { useContext } from 'react'
import { SubProyectoContext } from '../contexts/SubProyectoContext'
import { ScrollPanel } from 'primereact/scrollpanel'
import HomeSubProyectoCardRenderNew from './HomeSubProyectoCardRenderNew'

const HomeProyectoCardRenderNew = ({ proyectos }) => {
  const { subProyectos } = useContext(SubProyectoContext)

  return (
    <div
      className=" col-12 lg:col-12 xl:col-12"
      // onClick={onAppsControlClick}
    >
      <div className={'  mb-0  mb-3'}>
        <div className="flex justify-content-between mb-1">
          <div style={{ width: '100%' }}>
            <ScrollPanel style={{ width: '100%', height: '400px' }}>
              <div className="text-900 font-medium  ">
                {subProyectos.map(
                  (subProyectos) =>
                    subProyectos.estatusSubProyecto === 'OPERATIVO' &&
                    subProyectos.proyectoId?.id === proyectos.id && (
                      <HomeSubProyectoCardRenderNew
                        key={subProyectos.id}
                        subProyectos={subProyectos}
                      />
                    )
                )}
              </div>
            </ScrollPanel>
          </div>

          {/* <div
            className="flex align-items-center justify-content-center bg-blue-100 border-round"
            style={{ width: '2.5rem', height: '2.5rem' }}
          >
            <i className="pi pi-chart-bar text-blue-500 text-xl" />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default HomeProyectoCardRenderNew
