/* eslint-disable react/prop-types */

import moment from 'moment'

const PresupuestoAvanceList = ({ avancePresupuestosFilter }) => (
  <div>
    {avancePresupuestosFilter.map((p) => (
      <div key={p.id}>
        <span>Plan-{p.totalAvancePresupuesto}%</span>-
        <span>Real-{p.totalAvanceRealPresupuesto}%</span>-
        <span> {p.descripcionAvancePresupuesto}</span>-
        <span>
          {moment(p.fechaEfectivaAvancePresupuesto).format('DD/MM/YYYY')}
        </span>
      </div>
    ))}
  </div>
)

export default PresupuestoAvanceList
