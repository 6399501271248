/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { ProveedorContext } from '../contexts/ProveedorContext'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import { addLocale } from 'primereact/api'
import { InputTextarea } from 'primereact/inputtextarea'
import moment from 'moment'
import { classNames } from 'primereact/utils'

const ActivoForm = (props) => {
  const initialProveedorForm = {
    id: null,
    nombreProveedor: '',
    direccionProveedor: '',
    totalProveedor: 0,
    avanceProveedor: 0,
    fechaInicioProveedor: '',
    fechaFinalProveedor: '',
    estatusProveedor: '',
    creadoProveedor: moment(),
    modificadoProveedor: moment()
  }

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    today: 'Hoy',
    clear: 'Limpiar'
  })

  const { createProveedor, editProveedor, updateProveedor } =
    useContext(ProveedorContext)

  const { isVisible, setIsVisible } = props
  const [selectedProveedor, setSelectedProveedor] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [ProveedorData, setProveedorData] = useState(initialProveedorForm)
  const estadoProveedor = [
    { estatusProveedor: 'OPERATIVO' },
    { estatusProveedor: 'ANULADO' },

    { estatusProveedor: 'CULMINADO' }
  ]
  const onEstatusProveedor = (e) => {
    setSelectedProveedor(e.value)
    updateField(e.value.estatusProveedor, 'estatusProveedor')
  }

  const toast = useRef(null)

  useEffect(() => {
    if (editProveedor) {
      setProveedorData(editProveedor)
      setSelectedProveedor({
        estatusProveedor: editProveedor.estatusProveedor
      })
    }
  }, [editProveedor])

  const updateField = (data, field) => {
    setProveedorData({
      ...ProveedorData,
      [field]: data
    })
  }

  const saveProveedor = () => {
    setSubmitted(true)
    if (
      ProveedorData.nombreProveedor.trim() &&
      ProveedorData.estatusProveedor.trim()
    ) {
      if (!editProveedor) {
        createProveedor(ProveedorData)
      } else {
        updateProveedor({
          ...ProveedorData,
          ProveedorModificado: moment()
        })
      }
      clearSelected()
    }
  }

  const dialogFooter = (
    <div className="ui-dialog-buttonpane p-clearfix">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => clearSelected()}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveProveedor} />
    </div>
  )

  const clearSelected = () => {
    setIsVisible(false)
    setProveedorData(initialProveedorForm)
    setSelectedProveedor('')
    setSubmitted(false)
  }
  const selectedestatusProveedorTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.estatusProveedor}</div>
        </div>
      )
    }

    return <span>{props.placeholder}</span>
  }

  const estatusProveedorOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.estatusProveedor}</div>
      </div>
    )
  }

  return (
    <div className="dialog-demo">
      <Toast ref={toast} />
      <Dialog
        visible={isVisible}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '30vw' }}
        header="Detalles de la Proveedor"
        footer={dialogFooter}
        onHide={() => clearSelected()}
      >
        <div className="p-grid p-fluid">
          <br />
          <div className="p-float-label">
            <InputText
              value={ProveedorData.nombreProveedor}
              onChange={(e) => updateField(e.target.value, 'nombreProveedor')}
              className={classNames({
                'p-invalid': submitted && !ProveedorData.nombreProveedor
              })}
            />
            {submitted && !ProveedorData.nombreProveedor && (
              <small className="p-invalid">Nombre es requerido.</small>
            )}
            <label>Nombre del Proveedor:*</label>
          </div>
          <br />
          <div className="p-float-label">
            <InputTextarea
              id="description"
              value={ProveedorData.direccionProveedor}
              onChange={(e) =>
                updateField(e.target.value, 'direccionProveedor')
              }
              rows={3}
              cols={20}
            />

            <label>Direccion:</label>
          </div>
          <br />
          <div className="p-float-label ">
            <Dropdown
              value={selectedProveedor}
              options={estadoProveedor}
              onChange={onEstatusProveedor}
              optionLabel="estatusProveedor"
              placeholder="Seleccione Estado"
              valueTemplate={selectedestatusProveedorTemplate}
              itemTemplate={estatusProveedorOptionTemplate}
              className={classNames({
                'p-invalid': submitted && !ProveedorData.estatusProveedor
              })}
            />
            {submitted && !ProveedorData.estatusProveedor && (
              <small className="p-invalid">Estatus es requerido.</small>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default ActivoForm
