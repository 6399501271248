/* eslint-disable prefer-const */

/* eslint-disable react/prop-types */

import React, { useRef } from 'react'
import { Toast } from 'primereact/toast'
import { TabView, TabPanel } from 'primereact/tabview'

// import AuthUse from '../../../auth/AuthUse'
import ProyectoList from './ProyectoList'
import SubProyectoList from './SubProyectoList'
import PresupuestoList from './PresupuestoList'
import DataPresupuestoList from './DataPresupuestoList'
import ComentarioSubProyectoList from './ComentarioSubProyectoList'
import SolicitudFondoList from './SolicitudFondoList'
import AbonoPresupuestoList from './AbonoPresupuestoList'
import AvancePresupuestoList from './AvancePresupuestoList'
import ResponsableList from './ResponsableList'
import ProveedorList from './ProveedorList'

const CargaInformacionPanel = () => {
  // const auth = AuthUse()
  // const token = auth.user.token

  const toast = useRef(null)

  return (
    <>
      <h5>Operaciones de Carga de Datos</h5>
      <TabView className="tabview-custom">
        <TabPanel header="Proyecto" leftIcon="pi pi-database">
          <ProyectoList />
        </TabPanel>

        <TabPanel header="Sub Proyecto" leftIcon="pi pi-database">
          <SubProyectoList />
        </TabPanel>

        <TabPanel header="Presupuesto" leftIcon="pi pi-database">
          <PresupuestoList />
        </TabPanel>

        <TabPanel header="Data Presupuesto" leftIcon="pi pi-database">
          <DataPresupuestoList />
        </TabPanel>
        <TabPanel header="Comentario Sub Proyecto" leftIcon="pi pi-database">
          <ComentarioSubProyectoList />
        </TabPanel>
        <TabPanel header="Solicitud de Fondo" leftIcon="pi pi-database">
          <SolicitudFondoList />
        </TabPanel>
        <TabPanel header="Abono Presupuesto" leftIcon="pi pi-database">
          <AbonoPresupuestoList />
        </TabPanel>
        <TabPanel header="Avance Presupuesto" leftIcon="pi pi-database">
          <AvancePresupuestoList />
        </TabPanel>
        <TabPanel header="Responsable" leftIcon="pi pi-database">
          <ResponsableList />
        </TabPanel>
        <TabPanel header="Proveedor" leftIcon="pi pi-database">
          <ProveedorList />
        </TabPanel>
        {/* {(auth.user.faidUser.roles[0] === 'ADMIN' ||
          auth.user.faidUser.roles[0] === 'SUPERADMIN') && (
          <TabPanel header="Carga de =>>>>" leftIcon="pi pi-user">
            <h1>listo3</h1>
          </TabPanel>
        )} */}
      </TabView>

      <Toast ref={toast} />
    </>
  )
}

export default CargaInformacionPanel
